import { Injectable } from '@angular/core';
import { MEDIA_ENGINE_CONFIGS } from '@profile/features/magic-bands/common/media-engine-configs';
import { MEDIA_ENGINE_CONSTANTS } from '@profile/shared/constants/app.constants';
import { MeConfig } from '@wdpr/angular-media-engine';
import { get, merge } from 'lodash-es';
import { ViewportDetection } from '../viewport-detection/viewport-detection.service';
import { MediaEngineConfig, MediaEngineResult } from './media-engine.interface';

@Injectable({
  providedIn: 'root',
})
export class MediaEngineService {
  constructor(
    private viewport: ViewportDetection,
    // private configService: ConfigService,
  ) { }

  initConfig(mediaEngine: any, title?: string): MediaEngineConfig {
    // const env = this.configService.getValue(CONFIG_CONSTANTS.envKey);
    const platform = this.viewport.isDesktop() ?
      MEDIA_ENGINE_CONSTANTS.desktop :
      MEDIA_ENGINE_CONSTANTS.mobileWeb;
    const mediaEngineConfig: MediaEngineConfig = {
      data: mediaEngine.data,
      version: mediaEngine.version,
      theme: mediaEngine.theme,
      // production: env === CONFIG_CONSTANTS.prodEnvValue,
      production: true,
      fullscreen: {
        label: mediaEngine.fsBtnLabel,
        alt: mediaEngine.fsBtnAlt,
      },
      config: mediaEngine.config,
      configOverrides: get(mediaEngine, [platform]),
      platform,
    };

    if (title) {
      mediaEngineConfig.title = title;
    }

    return mediaEngineConfig;
  }

  initMediaEngine(finderConfig: MediaEngineConfig): MediaEngineResult {
    // Must execute this helper _before_ we return values because the helper can modify the theme
    const config = this.setMediaEngine(finderConfig);

    return {
      version: finderConfig.version,
      theme: finderConfig.theme,
      production: finderConfig.production,
      config,
    };
  }

  /**
   * Set Media Engine config
   *
   * @return Config Object
   */
  private setMediaEngine(finderConfig: MediaEngineConfig): MeConfig {
    /* Note: this method relies on the Media Engine configs defined in the codebase,
     * organized by theme, stored in the MEDIA_ENGINE_CONFIGS value brought in from a file.
     * The vendomatic configuration (https://latest.vdm.wdpro.starwave.com/edit_config.php?id=76807)
     * only relies on 'mediaEngineConfig' entry to chose which theme is applied from the
     * MEDIA_ENGINE_CONFIGS file (as well as the version of ME being used).
     * All other values in the codebase settings that are overridden from vendo must come from the
     * 'mediaEngineConfigOverrides' entry. That entry, in turn, is organized by desktop, mobile
     * (i.e., non-desktop), and native app (for hybrid Nemo pages). So to add lazy-loading slides,
     * that change must be in the `mediaEngineConfigOverrides` entry for the specific target
     * (desktop/mobile/app), not in the generic `mediaEngineConfig` entry even if you want it for all.
     */

    const titleBarClass = MEDIA_ENGINE_CONSTANTS.selectors.titleBarClass;
    const titleClass = MEDIA_ENGINE_CONSTANTS.selectors.titleClass;
    const ctaBtnClass = MEDIA_ENGINE_CONSTANTS.selectors.ctaBtnClass;
    const fsBtnClass = MEDIA_ENGINE_CONSTANTS.selectors.fsBtnClass;
    const fsBtnLabel = finderConfig.fullscreen.label;
    const fsBtnAlt = finderConfig.fullscreen.alt;

    const title = finderConfig.title ?
      `<h1 class="${titleClass}">${finderConfig.title}</h1>` :
      '';

    // CTA is only for attractions
    /* istanbul ignore next */
    const cta = finderConfig.button ?
      `<a class="${ctaBtnClass}" href="${finderConfig.button.url}" target="_blank">${finderConfig.button.text}</a>` :
      '';

    // Default theme if undefined
    finderConfig.theme = finderConfig.theme || MEDIA_ENGINE_CONSTANTS.defaultTheme;

    // We grab the base config
    const config: MeConfig = MEDIA_ENGINE_CONFIGS[finderConfig.theme];

    // Check for an existing config and use that as default
    const existingConfig = finderConfig.config;
    if (existingConfig) {
      Object.assign(config, existingConfig);
    }

    // Setting some custom HTML for the slides title
    config.slides.customHTML = {
      before: `<div class="${titleBarClass}">${title}${cta}</div>`
    };

    // Set up the "go full screen" (FS) button
    config.thumbnails.customHTML = {
      append: `<a class="${fsBtnClass}" href="#" title="${fsBtnAlt}">${fsBtnLabel}</a>`
    };

    config.media = [];

    // if it's not desktop use a smaller image
    if (!this.viewport.isDesktop()) {
      config.media = this.getMobileImageSources(finderConfig.data);
    } else {
      config.media.push(...finderConfig.data);
    }

    config.video = merge(config.video, get(finderConfig, MEDIA_ENGINE_CONSTANTS.configOverrides.video, {}));
    config.slides = merge(config.slides, get(finderConfig, MEDIA_ENGINE_CONSTANTS.configOverrides.slides, {}));

    return config;
  }

  /**
   * change the source of all image media to use a mobile size source
   *
   * @param data the array of media.
   * @returns and array of media with mobile sources.
   */
  private getMobileImageSources(data) {
    return data.map(media => {
      media.source = media.type === 'image' ? media.mobile : media.source;

      return media;
    });
  }
}
