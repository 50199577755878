<profile-core-side-nav-layout>
    <profile-core-syndicated-header
        *ngIf="showHeaderFooter"
        [url]="syndicatedLayoutConfigs['baseUrl'][siteId] + syndicatedLayoutConfigs['header'].path"
        [version]=" syndicatedLayoutConfigs['header'].siteConfigs[siteId].version"
        [siteId]="siteId"
        [environment]="environment"
        [locale]="locale"
        class="header">
    </profile-core-syndicated-header>
    <profile-core-side-navigation class="side-nav">
    </profile-core-side-navigation>
    <main class="content" *ngIf="!globalLoading">
        <profile-core-error *ngIf="showError" [error]="dataError"></profile-core-error>
        <div [ngClass]="{'visible': dataLoaded, 'hidden': !dataLoaded}">
            <router-outlet></router-outlet>
        </div>
    </main>
    <profile-core-syndicated-footer
        *ngIf="showHeaderFooter"
        [url]="syndicatedLayoutConfigs['baseUrl'][siteId] +  syndicatedLayoutConfigs['footer'].path"
        [version]="syndicatedLayoutConfigs['footer'].siteConfigs[siteId].version"
        [siteId]="siteId"
        [environment]="environment"
        [locale]="locale"
        class="footer">
    </profile-core-syndicated-footer>
    <profile-core-syndicated-legal
        *ngIf="showLegalFooter"
        [url]="syndicatedLayoutConfigs['baseUrl'][siteId] +  syndicatedLayoutConfigs['legal'].path"
        [siteId]="siteId"
        [locale]="locale"
        [environment]="environment"
        [version]="syndicatedLayoutConfigs['legal'].siteConfigs[siteId].version"
        class="footer">
    </profile-core-syndicated-legal>
</profile-core-side-nav-layout>

<profile-core-overlay [overlay]="overlay"></profile-core-overlay>

<div class="global-loader" *ngIf="globalLoading">
    <wdpr-progress-loader size="large"></wdpr-progress-loader>
</div>