import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IOrder } from '@profile/shared/interfaces/magic-bands.interface';
import { categoryTypes } from '@profile/shared/constants/app.constants';

@Component({
  selector: 'profile-reservation-data',
  templateUrl: './reservation-data.component.html',
  styleUrls: ['./reservation-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReservationDataComponent {
  @Input() order: IOrder;
  categoryTypes = categoryTypes;

  constructor() { }
}
