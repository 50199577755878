/* eslint-disable max-len */
import { MEDIA_ENGINE_CONSTANTS } from '@profile/shared/constants/app.constants';

const fsBtnClass = MEDIA_ENGINE_CONSTANTS.selectors.fsBtnClass;
const headerMeFsClass = MEDIA_ENGINE_CONSTANTS.selectors.headerMeFsClass;
const headerClass = MEDIA_ENGINE_CONSTANTS.selectors.headerClass;

/* istanbul ignore next */
const ready = () => {
    const fsBtn = document.querySelector(`.${fsBtnClass}`);
    if (fsBtn) {
        fsBtn.addEventListener('click', (e) => {
            e.preventDefault();
            (window as any).ME.config.callbacks.customFullscreenEnter();
        });
    }

    (window as any).ME.nicelyFitMediaEngine = () => {
        const detailsEl = document.querySelector('.details');
        let detailsStyles;

        (document.querySelectorAll('.me-slides .me-slides-media ul li img, .me-slides .me-slides-media ul li video') as any).forEach((el) => {
            if ((window as any).ME.state.fullscreen) {
                el.style.height = '';
            } else {
                if ((window as any).innerWidth >= 1024) {
                    detailsStyles = detailsEl ? window.getComputedStyle(detailsEl) : {} || {};
                    el.style.height = parseInt(detailsStyles.height, 10) + parseInt(detailsStyles.paddingTop, 10) + parseInt(detailsStyles.paddingBottom, 10) + 'px';
                } else {
                    el.style.height = 'auto';
                }
            }
        });
    };

    (window as any).addEventListener('resize', (window as any).ME.nicelyFitMediaEngine);
    (window as any).ME.nicelyFitMediaEngine();
    (window as any).ME.calculateWidths();

    // Set thumbs visible
    (window as any).ME.els.container.classList.add('show-thumbs');

    setTimeout(() => {
        // Hide thumbs after a few seconds
        (window as any).ME.els.container.classList.remove('show-thumbs');
    }, 3000);
};

/* istanbul ignore next */
const customFullscreenEnter = () => {
    // This specifies the first image to display on FS
    const preFullscreenMediaSrc = (window as any).ME.els.slidesMediaUL.querySelector('.me-active').children[0].src;
    // This Specifies the data set to display (first one is the default images)
    (window as any).ME.changeMediaSet(0);
    // This specifies the first image to display on FS
    (window as any).ME.loadSlideWithSrc(preFullscreenMediaSrc);
    document.querySelector(`.${headerClass}`).classList.add(`${headerMeFsClass}`);
    (window as any).ME.fullscreenEnter();
    // Needed if we are using real full-screen, not browser only
    (window as any).ME.fullscreenAPIEnter();
};

/* istanbul ignore next */
const customFullscreenExit = () => {
    // This specifies the first image to display on normal state
    const preFullscreenMediaSrc = (window as any).ME.els.slidesMediaUL.querySelector('.me-active').children[0].src;

    // This Specifies the data set to display (first one is the default images)
    (window as any).ME.changeMediaSet(0);
    // This specifies the first image to display on normal state
    (window as any).ME.loadSlideWithSrc(preFullscreenMediaSrc);
    document.querySelector(`.${headerClass}`).classList.remove(`${headerMeFsClass}`);
    (window as any).ME.fullscreenExit();
    // Needed if we are using real full-screen, not browser only
    (window as any).ME.fullscreenAPIExit();
    (window as any).ME.nicelyFitMediaEngine();
};

const MEConfig = {
    callbacks: {
        ready,
        customFullscreenEnter,
        customFullscreenExit,
    },
    layout: ['slides', 'controls', 'tabs', 'thumbnails'],
    slides: {
        autoplay: false,
        displayTime: 5000,
        fullscreenOnTouchOrClick: true,
        loop: true,
        transitionTime: 300,
        // Needed if we are using real full-screen, not browser only
        fullscreenAPIEnabled: true,
    },
    controls: {
        video: {
            layout: [
                'play-pause',
                'timer',
                'volume',
                'progress-bar',
                'cc'
            ]
        }
    },
    thumbnails: {
        controls: false,
        maxVisible: 'manual',
        transitionTime: 300
    },
    video: {
        autoplay: true,
        muted: true,
        unmuteOnFullscreen: true
    },
    media: []
};

export const MEDIA_ENGINE_CONFIGS: any = {
    'snowball': MEConfig,
    'snowball-hover-thumbs': MEConfig,
    'snowball-no-thumbs': MEConfig
};
