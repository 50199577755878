import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { GuestDataComponent } from '@magicband/shared/components/guest-data/guest-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '@profile/shared/components/components.module';
import { MediaEngineModule } from '@wdpr/angular-media-engine';
import { HeroStoryComponent } from './components/hero-story/hero-story.component';
import { MagicbandOrderComponent } from './components/magicband-order/magicband-order.component';
import { ManagedMagicbandsComponent } from './components/managed-magicbands/managed-magicbands.component';
import { VignetteComponent } from './components/vignette/vignette.component';
import { MagicBandsComponent } from './magic-bands.component';
import { ModalComponent } from '@profile/shared/components/modal/modal.component';
import { ReservationDataComponent } from './components/reservation-data/reservation-data.component';
import { CountdownComponent } from '@magicband/shared/components/countdown/countdown.component';

@NgModule({
  declarations: [
    ModalComponent,
    MagicBandsComponent,
    MagicbandOrderComponent,
    ManagedMagicbandsComponent,
    VignetteComponent,
    GuestDataComponent,
    HeroStoryComponent,
    ReservationDataComponent,
    CountdownComponent
  ],
  imports: [
    CommonModule,
    MediaEngineModule,
    SharedComponentsModule,
    TranslateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    MagicbandOrderComponent,
    ManagedMagicbandsComponent,
    VignetteComponent,
    GuestDataComponent,
    CountdownComponent
  ]
})
export class MagicBandsModule { }
