import { Injectable } from '@angular/core';
import { ONEID_LANGUAGE_MAP } from '../constants';

/**
 * Instantiates OneID Lightbox client
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageCodeService {

  /**
   * Returns the language code mapped to the given locale.
   */
  getLanguageCode(locale: string): string {
    const mappedCode = ONEID_LANGUAGE_MAP.hasOwnProperty(locale) ? ONEID_LANGUAGE_MAP[locale] : undefined;
    return mappedCode ? mappedCode : this.parseLanguageCodeFromLocale(locale);
  }

  /**
   * Parses a locale string and returns it in a language code format.
   * Returns it in 'ab-CD' format. If the regex doesn't find a match,
   * it returns the original string.
   */
  parseLanguageCodeFromLocale(locale: string): string {
    const match = locale.match(/(\w+)[-_](\w+)/);
    return match ? `${match[1].toLowerCase()}-${match[2].toUpperCase()}` : locale;
  }

}
