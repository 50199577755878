import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MagicBandsComponent } from './features/magic-bands/magic-bands.component';
import { AuthGuardService } from '@profile/shared/services/authentication-guard-service/authentication-guard.service';
import {
    MasterAuthenticationGuard
} from '@profile/shared/services/master-authentication-guard-service/master-authentication-guard.service';
import { ContentResolver } from '@profile/shared/services/content-resolver/content-resolver.service';

const routes: Routes = [
    {
        path: 'plan/bands-cards',
        component: MagicBandsComponent,
        canActivate: [MasterAuthenticationGuard],
        resolve: {
            content: ContentResolver
        },
        data: {
            showHeaderFooter: true
        }
    },
    {
        path: ':locale/plan/bands-cards',
        component: MagicBandsComponent,
        canActivate: [MasterAuthenticationGuard],
        resolve: {
            content: ContentResolver
        },
        data: {
            showHeaderFooter: true
        }
    },
    {
        path: ':locale/:locale/plan/bands-cards',
        component: MagicBandsComponent,
        canActivate: [MasterAuthenticationGuard],
        resolve: {
            content: ContentResolver
        },
        data: {
            showHeaderFooter: true
        }
    },
    {
        path: 'my-disney-cruise/bands-more',
        component: MagicBandsComponent,
        canActivate: [AuthGuardService],
        resolve: {
            content: ContentResolver
        },
        data: {
            showHeaderFooter: true
        }
    },
    {
        path: ':locale/my-disney-cruise/bands-more',
        component: MagicBandsComponent,
        canActivate: [AuthGuardService],
        resolve: {
            content: ContentResolver
        },
        data: {
            showHeaderFooter: true
        }
    },
    {
        path: ':locale/:locale/my-disney-cruise/bands-more',
        component: MagicBandsComponent,
        canActivate: [AuthGuardService],
        resolve: {
            content: ContentResolver
        },
        data: {
            showHeaderFooter: true
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
