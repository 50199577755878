import { Inject, Injectable, InjectionToken, isDevMode } from '@angular/core';
import { isObject, isString } from 'lodash-es';
import { HttpService } from '../http-service/http-service';
import { ILogger } from './ilogger';

/* eslint-disable @typescript-eslint/naming-convention */
export const LoggerServiceToken = new InjectionToken('logger.service');
export const LoggerServiceMaxLengthToken = new InjectionToken('logger.service.maxLength');
export const LoggerServiceUrlToken = new InjectionToken('logger.service.url');

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements ILogger {
  private debugEnabled: boolean;
  private console;

  constructor(
    private http: HttpService,
    @Inject(LoggerServiceUrlToken) private url: string,
    @Inject(LoggerServiceMaxLengthToken) private maxStringLength: string
  ) {
    this.debugEnabled = isDevMode();
    this.console = console;
  }

  log(message: string, ...args: any[]) {
    const messages = this.parseMessage(args);
    this.console.log(message, ...messages);
  }

  remoteLog(message: string, ...args: any[]) {
    this.send('info', message, ...args);
  }

  warn(message: string, ...args: any[]) {
    const messages = this.parseMessage(args);
    this.console.warn(message, ...messages);
  }

  error(message: string, ...args: any[]) {
    // We only need the error parsed for the console.
    const messages = this.parseMessage(args);
    this.console.error(message, ...messages);
    this.send('error', message, ...args);
  }

  debug(message: string, ...args: any[]) {
    if (this.debugEnabled) {
      const messages = this.parseMessage(args);
      this.console.debug(message, ...messages);
    }
  }

  /**
   * Sends log message to configured backend.
   */
  private send(logLevel: string, message: string, ...args: any[]) {
    if (this.url) {
      const logMessage = this.truncateMessageObject([message, ...args]);
      return this.http.post(`${this.url}${logLevel}`, logMessage);
    }
  }

  /**
   * Parses the args array to display it properly on the console.
   */
  parseMessage(args: any[]) {
    let messages;
    // Iterate over array
    messages = args.map(elem => {
      if (typeof elem !== 'string') {
        return elem;
      }
      let str;
      try {
        // Try to parse JSON and access message prop
        // Fallback to whole JSON object
        const data = JSON.parse(elem);
        str = data.message || elem;
      } catch (e) {
        // Plain string - Eat the error
        str = elem;
      }
      return str;
    });

    return messages;
  }

  /**
   * @see LogasaurusService
   */
  truncateMessageObject(logMessage, depth = 4) {
    for (const prop in logMessage) {
      if (isString(logMessage[prop])) {
        // protect against assignment errors on
        // non-writable properties in strict mode
        try {
          logMessage[prop] = this.truncateMessageString(logMessage[prop]);
        } catch (error) {
          // fail silently
        }
      } else if (isObject(logMessage[prop])) {
        if (depth) {
          // recurse if within the allowed depth
          logMessage[prop] = this.truncateMessageObject(logMessage[prop], depth - 1);
        } else {
          // truncate the object by removing props that are too deep
          delete(logMessage[prop]);
        }
      }
    }
    return logMessage;
  }

  truncateMessageString(str) {
    if (str.length > this.maxStringLength) {
      return str.substr(0, this.maxStringLength) + '...[truncated]';
    }

    return str;
  }
}
