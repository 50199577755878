import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '@wdpr-profile/authenticator';

@Injectable()
export class DebugGuardService implements CanActivate {
    public debugCookies: string;
    public cookieObject: any;
    public debugIsEnabled: boolean;

    constructor(
        private router: Router,
        private sessionService: SessionService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {

        // get featureToggles cookie and parse it
        this.debugCookies = this.sessionService.getCookie('featureToggles');
        if (this.debugCookies) {
            try {
                this.cookieObject = decodeURIComponent(this.debugCookies);
                this.cookieObject = JSON.parse(this.cookieObject);
            } catch (error) {
                // fail silently
            }
        }
        if (this.cookieObject && this.cookieObject.debugModuleEnabled) {
            this.debugIsEnabled = this.cookieObject.debugModuleEnabled;
        }
        // Activate or deactivate debug pages according to ngDebugMode boolean.
        if (!this.debugIsEnabled) {
            this.router.navigate(['**'], { queryParams: { retUrl: route.url} });
            return false;
        }

        return true;
    }

}
