import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IMagicBandData } from '@profile/shared/interfaces/magic-bands.interface';
import { API_CONSTANTS } from '@profile/shared/constants/app.constants';
import { MAGIC_BAND_API_CONSTANTS } from '@profile/shared/constants/app.constants';
import { ConfigService } from '@wdpr-profile/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MagicBandService {
  private activeGuest: string;
  // Observable sources
  private collectionOpenedSource = new Subject<string>();
  private collectionClosedSource = new Subject<string>();

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  // Observable streams
  collectionOpened$ = this.collectionOpenedSource.asObservable();
  collectionClosed$ = this.collectionClosedSource.asObservable();

  public retrieveMagicBands(): Observable<IMagicBandData> {
    const siteId = this.configService.getValue('siteId');

    let httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'application/json');
    httpHeaders.set('Content-Type', 'application/json');

    if (!environment.production) {
      httpHeaders.set('X-Disney-Internal-Site', siteId);
    }

    const httpOptions = {headers: httpHeaders};

    return this.http.get<IMagicBandData>('/profile-api/v1/guest/swid/{{swid}}/magic-bands', httpOptions);
  }

  isActive(guestId: string): boolean {
    return this.activeGuest === guestId;
  }

  openCollection(guestId: string): void {
    this.activeGuest = guestId;
    this.collectionOpenedSource.next(guestId);
  }

  closeCollection(): void {
    const activeGuest = this.activeGuest;
    this.activeGuest = null;
    this.collectionClosedSource.next(activeGuest);
  }

  public verifyAddress(xBandRequestID, shippingAddress): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Strip-Loc':  'true',
        'X-Catch-Loc':  'true',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      parameters: new HttpParams({fromObject: shippingAddress})
    };

    return this.http.put(
      MAGIC_BAND_API_CONSTANTS.profileApiMBPath + MAGIC_BAND_API_CONSTANTS.updateMBOrderPath(xBandRequestID),
      httpOptions, {observe: 'response'}
    );
  }

  public updateManagedBandStatus(bandID, state) {
    let postData = {'secondaryState': state};

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(
      MAGIC_BAND_API_CONSTANTS.profileApiMBPath + MAGIC_BAND_API_CONSTANTS.updateMBStatus(bandID),
      postData,
      httpOptions
    );
  }

  public getSingleManagedBand(bandID) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(
      MAGIC_BAND_API_CONSTANTS.profileApiMBPath + MAGIC_BAND_API_CONSTANTS.updateMBStatus(bandID),
      httpOptions
    );
  }

  public getSingleManagedBandOptions(bandID) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(
      MAGIC_BAND_API_CONSTANTS.profileApiMBPath + MAGIC_BAND_API_CONSTANTS.updateMBOptions(bandID),
      httpOptions
    );
  }
}
