import { Injectable } from '@angular/core';
import { ConfigService } from '@wdpr-profile/core';

@Injectable({
  providedIn: 'root'
})
export class CdnService {
  cdnPath: string;
  cdnList: Array<string>;
  cdnListIndex = 0;

  constructor(
    private config: ConfigService
  ) {
    this.cdnPath = this.config.getValue('cdnPath');
    this.cdnList = this.config.getValue('cdnList');
  }

  public getCDNUrl(relativeUrl): string {
    if (!relativeUrl || typeof(relativeUrl) !== 'string') {
      return relativeUrl;
    }
    let thisIndex = this.cdnListIndex++ % this.cdnList.length;
    let absoluteUrl = this.cdnList[thisIndex] + this.cdnPath;
    // Verify that we have a relative URL.
    if (relativeUrl.match(/^\//)) {
      absoluteUrl += relativeUrl;
    } else {
      absoluteUrl = relativeUrl;
    }
    // Remove possible double slashes at the beginning of a relative URL.
    absoluteUrl = absoluteUrl.replace(/(^\/\/)/g, '/');
    // Remove possible double slashes in the URL path.
    absoluteUrl = absoluteUrl.replace(/([^:])(\/\/+)/g, '$1/');
    return absoluteUrl;
  }
}
