interface ListenerMap {
    [name: string]: Array<Function>;
}

const listeners: ListenerMap = {};

export function getListeners() {
  return listeners;
}

/**
 * Attach an event listener.
 * Once the event is emitted,
 * the designated handler will
 * execute.
 */
export function attachEventListener(name: string, handler: Function) {
  if (!Array.isArray(listeners[name])) {
    listeners[name] = [];
  }

  listeners[name].push(handler);
}

/**
 * Detach an event listener.
 */
export function detachEventListener(name: string, handler?: Function) {
  if (!Array.isArray(listeners[name])) {
    return;
  }

  if (typeof handler === 'undefined') {
    listeners[name] = [];
    return;
  }

  for (let i = 0; i < listeners[name].length; i++) {
    if (listeners[name][i] === handler) {
      listeners[name].splice(i, 1);
    }
  }
}
