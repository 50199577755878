import {
    IError
} from '@profile/shared/interfaces/magic-bands.interface';

export class MagicBandError {
    errorObject: Array<IError>;

    static RESERVATION_REQUEST = 'DCL_RESERVATION_SERVICE_REQUEST_ERROR';
    static RESERVATION_PROCESS = 'MAGIC_BAND_DCL_RESERVATION_PROCESS_ERROR';


    constructor() {
        this.errorObject = [];
    }

    get hasErrors() {
        return this.errorObject.length;
    }

    get message() {
        return 'descriptions.magicbandspage.sections.error_magic_bands_unavailable';
    }

    set error(error) {
        this.errorObject = error;
    }
}
