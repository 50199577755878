import { CdnService } from '@magicband/shared/services/cdn-service/cdn-service';
import { DeviceDataService } from '@wdpr/ra-angular-device-detection';
import { formatImageURL } from '@magicband/shared/utils/utils';
import { IProductDetailData, imageSizeTShirt } from '@profile/shared/interfaces/magic-bands.interface';
import {
    BAND_IMAGE_PATH,
    BAND_TYPE_REGEX,
    CARD_IMAGE_PATH,
    CARD_TYPES,
    MEDIA_OPTIONS
} from '@magicband/shared/constants/app.constants';

export class ProductData {
    userDevice = '';

    readonly defaultBandImage = 'default_band.png';
    readonly defaultCardImage = CARD_TYPES['CH0200'];

    constructor(
        private mediaProducts: IProductDetailData,
        private cdnService: CdnService,
        private deviceDataService: DeviceDataService
    ) {
        const cookieName = this.deviceDataService.getConfig();
        this.deviceDataService.getCookie(cookieName);
        this.userDevice = this.deviceDataService.getDeviceType().toLowerCase();
    }

    public getImage (sku: string, imageSize: imageSizeTShirt, renderSize: imageSizeTShirt) {
        let productImage;
        let mediaOptions = renderSize === imageSizeTShirt.thumb ?
            MEDIA_OPTIONS[renderSize]
            : MEDIA_OPTIONS[this.userDevice][renderSize];
        const isBand = BAND_TYPE_REGEX.test(sku);
        try {
            if (isBand) {
                productImage = this.mediaProducts[sku].images[imageSize].href;
            } else {
                let cardImage = CARD_TYPES[sku];
                if (!cardImage) {
                    cardImage = this.defaultCardImage;
                }
                productImage = this.cdnService.getCDNUrl(CARD_IMAGE_PATH) + cardImage;
            }
        } catch (e) {
            if (isBand) {
                productImage = this.cdnService.getCDNUrl(BAND_IMAGE_PATH) + this.defaultBandImage;
            } else {
                productImage = this.cdnService.getCDNUrl(CARD_IMAGE_PATH) + this.defaultCardImage;
            }
        };
        return this.cdnService.getCDNUrl(formatImageURL(productImage, mediaOptions));
    }
}
