<!-- <profile-page-loading *ngIf="loading"></profile-page-loading> -->
<div class="magic-band-loader" *ngIf="loading">
    <wdpr-progress-loader size="medium" ></wdpr-progress-loader>
</div>
<div class="magicbands {{siteId}}" id="magicbands" *ngIf="!loading && !isURLProtected">
    <div id="pageContainer">
        <div id="pageContainerInner">
            <div id="pageContent" class="pepde-container uiPlus">
                <div class="uHeader pepde-container headerContainer">
                    <h1 class="uTitle" [translate]="'descriptions.magicbandspage.sections.title'"></h1>
                </div>
                <section id="error" *ngIf="dataError">
                    <div class="error">
                        <span class="di di-alert"></span> <span [translate]="errorCollector.message"></span>
                    </div>
                </section>
                <section id="orderSets" *ngIf="!errorCollector.hasError">
                    <profile-magicband-order
                        *ngIf="data.orders.length"
                        [data]="data"
                        class="magicBandOrdersComponent"
                    >
                    </profile-magicband-order>
                </section>
                <section class="unlock_the_magic" *ngIf="!isTranslationEmpty('descriptions.magicbandspage.sections.unlock_the_magic_header')">
                    <div class="unlock_the_magic_title" [translate]="'descriptions.magicbandspage.sections.unlock_the_magic_header'"></div>
                    <div class="unlock_the_magic_desc" [innerHTML]="'descriptions.magicbandspage.sections.unlock_the_magic_copy' | translate"></div>
                    <div class="shop_bands" *ngIf="isShowShopMagicBandsButton()">
                        <div class="shop_bands_title" [translate]="'descriptions.magicbandspage.sections.shop_bands_header'"></div>
                        <div
                            class="shop_bands_desc"
                            [innerHTML]="'descriptions.magicbandspage.sections.shop_bands_description' | translate: {shopDisneyMBs: shopDisneyMagicBandsLink}"
                        ></div>
                    </div>
                    <div class="unlock_the_magic_cta" *ngIf="isShowShopMagicBandsButton()">
                        <a href="{{shopDisneyMagicBandsLink}}">
                        <wdpr-button 
                            theme="primary"
                            id="magicBandLinkBtn"
                            aria-disabled="false" 
                            size="standard" 
                            role="button"
                            aria-label="Shop MagicBands"
                            [translate]="'descriptions.magicbands.sections.cta_unlock_the_magic'"></wdpr-button>
                        </a>
                    </div>
                </section>
                <div class="horizontalSeparator"></div>
                <profile-magicbands-managed
                    *ngIf="data.managed.length"
                    [data]="data"
                    [managedBands]="managedBands"
                >
                </profile-magicbands-managed>
                <section class="orderHistory" *ngIf="siteId===siteIdConstant.WDW">
                    <a href="/magic-band-customization/order-history/">
                        <wdpr-button 
                            theme="primary"
                            id="magicBandOrderHistory"
                            aria-disabled="false" 
                            size="standard" 
                            role="button"
                            aria-label="Order History">Order History</wdpr-button>
                    </a>
                </section>
                <section id="findMagicBand" class="findMagicBand">
                    <h2 [translate]="'descriptions.magicbandspage.sections.find_magicband_title'"></h2>
                    <p>
                        <span
                            class="findBold"
                            [innerHTML]="'descriptions.magicbandspage.sections.find_magicband_dontSeePurchased' | translate"
                        ></span>
                        <span
                            class="separateExplanations"
                            [innerHTML]="'descriptions.magicbandspage.sections.find_magicband_purchasedExplanation' | translate"
                        ></span>
                        <span
                            class="findBold"
                            [innerHTML]="'descriptions.magicbandspage.sections.find_magicband_dontSeeResort' | translate"
                        ></span>
                        <span
                            [innerHTML]="'descriptions.magicbandspage.sections.find_magicband_resortExplanation' | translate"
                        ></span>
                    </p>
                    <span class="findLink">
                        <a href="{{claimMBPath(environmentName())}}">
                            <wdpr-button 
                                id="magicBandLinkBtn"
                                theme="primary"
                                aria-disabled="false" 
                                size="standard" 
                                role="button"
                                aria-label="Link a MagicBand"
                                [translate]="'descriptions.magicbandspage.sections.find_magicband_link'"></wdpr-button>
                        </a>
                    </span>
                    <br />
                    <a href="{{claimMBPath(environmentName())}}">
                        <wdpr-button 
                            id="magicBandOrderHistory"
                            theme="primary"
                            aria-disabled="false" 
                            size="standard" 
                            role="button"
                            aria-label="Order History"
                            *ngIf="siteId===siteIdConstant.WDW">Order History</wdpr-button>
                    </a>
                </section>
                <div class="horizontalSeparator"></div>
                <div class="base standardContentContainer d-clear pepde-span-50 uiPlus uLayout">
                    <div
                        class="magicband_purchase_disclaimer"
                        [innerHTML]="'descriptions.magicbandspage.sections.disclaimer' | translate"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</div>
