import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Authenticator } from '@wdpr-profile/authenticator';

// Extends Authenticator to add subscriptions
// without bundling the rxjs library in the Authenticator bundle

@Injectable({
  providedIn: 'root'
})
export class ProfileAuthenticatorService extends Authenticator {
  // Observable sources
  private loggedInSource = new Subject<object>();
  private loggedOutSource = new Subject<object>();

  // Observable streams
  loggedIn$ = this.loggedInSource.asObservable();
  loggedOut$ = this.loggedOutSource.asObservable();

  /**
   * Override the parent function to notify a subscriber
   * when login is complete and the session is started.
   * Errors are logged in the parent class.
   */
  /* istanbul ignore next */
  onLogin(guest): Promise<any> {
    return super.onLogin(guest)
      .then(() => this.loggedInSource.next(guest))
      .catch(err => this.loggedInSource.error(err));
  }

  /**
   * Override the parent function to notify a subscriber
   * when logout and session deletion are complete.
   * Errors are logged in the parent class.
   */
  /* istanbul ignore next */
  onLogout(): Promise<any> {
    return super.onLogout()
      .then((val) => this.loggedOutSource.next(val))
      .catch(err => this.loggedInSource.error(err));
  }

  /**
   * Override the parent function to notify a subscriber
   * when login is complete and the session is started.
   * Errors are logged in the parent class.
   */
  /* istanbul ignore next */
  onCreate(guest): Promise<any> {
    return super.onCreate(guest)
      .then(() => this.loggedInSource.next(guest))
      .catch(err => this.loggedInSource.error(err));
  }
}
