import { Injectable } from '@angular/core';
import { WindowRef } from '../window-ref/window-ref';

export interface IScript {
  name: string;
  src: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScriptLoader {

  private scripts: any = {};

  constructor(
    private windowRef: WindowRef
  ) {}

  /**
   * Initializes the loader with an array of scripts to load.
   */
  init(scripts: IScript[]) {
    scripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  /**
   * Loads a script by its name.
   */
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
    // resolve if already loaded
    if (this.scripts[name].loaded) {
      resolve({ script: name, loaded: true, status: 'Already Loaded' });
    } else {
      // load script
      const script = this.windowRef.nativeWindow.document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.scripts[name].src;
      script.onload = () => {
        this.scripts[name].loaded = true;
        resolve({ script: name, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });

      this.windowRef.nativeWindow.document.getElementsByTagName('head')[0].appendChild(script);
    }
    });
  }
}
