export const ONEID_LANGUAGE_MAP = {
  // International English
  'en'   : 'en-US',
  'en_CA': 'en-GB',
  'en_EU': 'en-GB',
  'en-eu': 'en-GB',
  'en_IE': 'en-GB',

  // Latin-American Spanish
  'es_AR': 'es-LA',
  'es_CL': 'es-LA',
  'es_CO': 'es-LA',
  'es_MX': 'es-LA',
  'es_PE': 'es-LA',
};

