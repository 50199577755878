import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { get } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class ContentService<T> {
    private content: any;
    private contentResult: T;

    constructor(
        private logger: LoggerService
    ) {}

    setContent(content: any) {
        this.content = content;
        if (this.content) {
            this.logger.log('Content loaded.');
        } else {
            this.logger.error('No content loaded at content.service.');
        }
    }

    /**
     * @param path the path of the content file object, should be separated by .
     * Example: profileDetails.iconText return the values located in that specific path.
     */
    getContentAsObservable(path: string): Observable<T> {
        this.contentResult = get(this.content, path);
        if (this.contentResult) {
            return of(this.contentResult);
        } else {
            this.logger.error('Cannot get content with the path: ', path);
            return of(undefined);
        }
    }

    /**
     * @param path the path of the content file object, should be separated by .
     * Example: profileDetails.iconText return the values located in that specific path.
     */
    getContent(path: string) {
        this.contentResult = get(this.content, path);
        if (this.contentResult) {
            return this.contentResult;
        } else {
            this.logger.error('Cannot get content with the path: ', path);
        }
    }
}
