import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '@profile/shared/services/retrieve-content-service/content.service';
import {
    API_CONSTANTS,
    MAGIC_BAND_API_CONSTANTS,
    headerBackgrounds,
    HOST_REGEX,
    KTTW_PLUS_BAND_REGEX,
    LINK_CONSTANTS,
    LOCALE_EN_US,
    MEDIA_TYPE,
    MEP_GUEST_PASSES,
    MEP_PASSES,
    secretkey,
    SITE,
    SLAPBAND_TYPE_REGEX,
    CARD_TYPE_REGEX,
    localLocaleUS,
    BAND_STATES,
    PAGE_TITLE_DEFAULT
} from '@profile/shared/constants/app.constants';
import { IMagicBandData, IManagedBand, IManagedMediaCollection } from '@profile/shared/interfaces/magic-bands.interface';
import {
    ConfigService,
    LocaleService,
    WindowRef,
    NavigationService,
    NavigationType,
    OneIdService,
    GuestProfileService,
    GuestProfile as GuestProfileCore,
    ProfileAnalyticsService
} from '@wdpr-profile/core';
import { has } from 'lodash-es';
import { of, EMPTY, forkJoin, from } from 'rxjs';
import { MagicBandService } from '@profile/shared/services/magic-band-service/magic-band.service';
import { TranslateService } from '@ngx-translate/core';
import { MagicBandError } from '@profile/shared/classes/magicband-error.class';
import { catchError, retry } from 'rxjs/operators';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';
import { ViewportDetection } from '@profile/shared/services/viewport-detection/viewport-detection.service';
import { ProfileService } from '@profile/shared/services/profile-service/profile.service';
import { GuestProfile } from '@profile/shared/services/profile-service/guest-profile';

import { ActivatedRoute } from '@angular/router';
import { RedirectHandlerService } from '@profile/shared/services/redirect-handler-service/redirect-handler.service';
import { toBoolean } from '@profile/shared/utils/utils';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { AnalyticsService, ConfigService as AnalyticsConfig } from '@wdpr/angular-analytics';

@Component({
    selector: 'profile-magicbands',
    templateUrl: './magic-bands.component.html',
    styleUrls: ['./magic-bands.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MagicBandsComponent implements OnInit, OnDestroy {
    private isSiteDcl = false;
    private isSiteWdw = false;
    private analyticsReportSuite;
    private analyticsLanguageCode;
    private analyticsCountryCode;
    dataError = false;
    hasError = false;
    siteId: string;
    environment: string;
    loading = true;
    guestLangPref: string;
    guestGeoLocale: string;
    guestSWID: string;
    data: IMagicBandData;
    dclUrlProtect = false;
    heroStoryData: any;
    errorCollector: any;
    backgroundImage: string;
    backgroundImages;
    isMobile: boolean;
    defaultData: IMagicBandData = {
        affiliations: {},
        errors: [],
        facilities: {},
        cruiseSailingFacilities: {},
        guests: {},
        managed: [],
        orders: [],
        products: {}
    };
    wdwBanner = '';
    dclDesktopBanner = '';
    isURLProtected = true; // Default is true to ensure that there is no lag where the guest would see the MB&C header image.
    guest: GuestProfile;
    loggedInGuestXID;
    loggedInGuestAvatarId: string;
    shopDisneyMagicBandsLink: string;
    claimMBPath;
    siteIdConstant = SITE;

    managedBands: IManagedMediaCollection = {};
    guestPasses = {};

    device_type = [
        'mobile',
        'tablet',
        'desktop'
    ];

    private destroy$ = new Subject<void>();
    private pageTitle: string = null;

    constructor(
        private configService: ConfigService,
        private localeService: LocaleService,
        private contentService: ContentService<any>,
        private magicBandService: MagicBandService,
        private titleService: Title,
        private translate: TranslateService,
        private cdnSerserve: CdnService,
        private viewport: ViewportDetection,
        private activatedRoute: ActivatedRoute,
        private redirect: RedirectHandlerService,
        private profileService: ProfileService,
        private logger: LoggerService,
        private windowRef: WindowRef,
        private navigationSvc: NavigationService,
        private oneIdSvc: OneIdService,
        private guestProfileSvc: GuestProfileService,
        private analyticsService: AnalyticsService,
        private analyticsConfig: AnalyticsConfig
    ) {
        this.siteId = this.configService.getValue('siteId');
        this.analyticsConfig.loader = () => {
            return {};
        };
        this.claimMBPath = MAGIC_BAND_API_CONSTANTS.claimMBPath;
        this.getProfileData();

        this.shopDisneyMagicBandsLink = LINK_CONSTANTS.shopMagicBands(this.siteId.toUpperCase());

        const dclUrlProtect = toBoolean(configService.getValue('dclUrlProtect'));
        const secretParam = this.activatedRoute.snapshot.queryParamMap.get('secret');

        if (this.siteId === SITE.DCL && dclUrlProtect && secretkey !== secretParam) {
            this.redirect.navigateToNotFoundError();
        } else {
            this.isURLProtected = false;
        }

        this.environment = this.configService.getValue('environment');
        this.guestLangPref = this.getLocale();
        this.guestGeoLocale = this.getLocale();
        this.backgroundImages = headerBackgrounds[this.siteId];
    }

    ngOnInit() {
        if (this.siteId === SITE.DCL) {
            this.isSiteDcl = true;
        } else if (this.siteId === SITE.WDW) {
            this.isSiteWdw = true;
        }
        const LOG_MESSAGE_PREFIX = 'MB_MAGIC_BANDS_COMPONENT_INIT -> ';
        const SIDE_NAVIGATION_CONTENT = this.contentService.getContent('sideNavigation');
        const META_CONTENT = this.contentService.getContent('meta');
        if (META_CONTENT) {
            this.logger.log(LOG_MESSAGE_PREFIX + 'META_CONTENT detected.');
            if (META_CONTENT['pageTitle']) {
                this.pageTitle = META_CONTENT.pageTitle;
                this.logger.log(LOG_MESSAGE_PREFIX + 'META_CONTENT.pageTitle detected with '
                    + 'the following value -> \"' + this.pageTitle + '\".');
            }
        } else {
            this.logger.warn(LOG_MESSAGE_PREFIX + 'WARNING -> META_CONTENT NOT detected so ' +
                'setting pageTitle to brand specific default value.');
            if (this.isSiteWdw) {
                this.pageTitle = PAGE_TITLE_DEFAULT.WDW;
            } else if (this.isSiteDcl) {
                this.pageTitle = PAGE_TITLE_DEFAULT.DCL;
            }
        }
        if (this.pageTitle) {
            this.titleService.setTitle(this.pageTitle);
            this.logger.log(LOG_MESSAGE_PREFIX + 'PAGE_TITLE_SUCCESS -> this.pageTitle detected with ' +
                'the following value -> \"' + this.pageTitle + '\" and page title successfully ' +
                'set for the SPA!');
        }
        this.navigationSvc.setRemoveFromLayout(false);
        this.viewport.onViewportResize().subscribe(() => {
            this.setHeaderBackground();
        });

        new Promise((resolve, reject) => {
            forkJoin({
                config: this.configService.load(),
                oneIdGuest: from(this.oneIdSvc.did.getGuest(true)),
                profileGuest: this.profileService.getProfile(),
                compactGuest: this.profileService.getCompactProfile(),
            })
                .pipe(
                    catchError(error => {
                        this.logger.error(`Error retrieving profile data: ${error.message}`);
                        resolve(null);
                        return EMPTY;
                    })
                )
                .subscribe(svcResponse => {
                    const consolidatedGuest = getConsolidatedGuest(svcResponse);
                    let loggedInGuestSwid = 'LOGGED_IN_GUEST_SWID_NOT_DETECTED';
                    if (consolidatedGuest) {
                        if (consolidatedGuest.guestIdentifiers) {
                            if (consolidatedGuest.guestIdentifiers.swid) {
                                loggedInGuestSwid = consolidatedGuest.guestIdentifiers.swid;
                                this.logger.log(LOG_MESSAGE_PREFIX + 'Logged in guest\'s swid detected -> \"' + loggedInGuestSwid);
                            } else {
                                this.logger.warn(LOG_MESSAGE_PREFIX + 'Logged in guest\'s swid NOT detected as the ' +
                                    'consolidatedGuest.guestIdentifiers.swid property is null.');
                            }
                        } else {
                            this.logger.warn(LOG_MESSAGE_PREFIX + 'Logged in guest\'s swid NOT detected as the ' +
                                'consolidatedGuest.guestIdentifiers object is null.');
                        }
                        if (consolidatedGuest.avatarId) {
                            this.loggedInGuestAvatarId = consolidatedGuest.avatarId;
                            this.logger.log(LOG_MESSAGE_PREFIX + 'Logged in guest\'s character ' +
                                'avatar ID detected with the following value -> \"' + this.loggedInGuestAvatarId + '\". ' +
                                'Logged in guest\'s swid -> \"' + loggedInGuestSwid + '\"');
                        } else {
                            this.logger.error(LOG_MESSAGE_PREFIX + 'Logged in guest\'s character ' +
                                'avatar ID NOT detected as the consolidatedGuest.avatarId property is null. ' +
                                'Logged in guest\'s swid -> \"' + loggedInGuestSwid + '\"');
                        }
                    }
                    this.guestProfileSvc.setLoggedInGuest(consolidatedGuest);
                    this.navigationSvc.setRemoveFromLayout(false);
                    this.navigationSvc.setType(NavigationType.loggedInGuest);

                    resolve(svcResponse);
                });
        });

        if (!this.isURLProtected) {
            this.setHeaderBackground();

            this.errorCollector = new MagicBandError();
            this.environmentName();

            this.magicBandService.retrieveMagicBands()
                .pipe(
                    retry(0), // retry a failed request up to 0 times
                    catchError(this.handleError) // then handle the error
                )
                .subscribe({
                    next: response => {
                        this.errorCollector.error = this.data ? this.data.errors : {};
                        this.data = response;
                        if (!this.errorCollector.hasErrors) {
                            if (this.data.cruiseSailingFacilities) {
                                this.data.facilities = this.data.cruiseSailingFacilities;
                                this.data.cruiseSailingFacilities = null; // Null this to prevent memory issues with large arrays.
                            }

                            if (has(this.data, 'managed')) {
                                // filter out bands with void state
                                this.data.managed = this.data.managed.filter(bands => bands.state !== BAND_STATES.VOID);
                                this.adornBandsWithMediaType(this.data.managed);
                                this.groupByGuestType(this.data.managed);
                            }
                        } else if (this.errorCollector.hasErrors) {
                            this.handleError();
                        }
                        this.loading = false;
                    },
                    error: error => {
                        this.dataError = true;
                        this.loading = false;
                        this.handleError();
                        return of(null);
                    }
                });

            const key = 'descriptions.media';
            this.translate.get(key)
                .subscribe((translation: object) => {
                    this.heroStoryData = translation.toString() !== key ? translation : null;
                });
        }
        this.processPageViewAnalyticsEvent();
    }

    ngOnDestroy() {
        this.navigationSvc.setRemoveFromLayout(true);
    }

    isShowShopMagicBandsButton() {
        const LOG_MESSAGE_PREFIX = 'MB_SHOW_SHOP_MAGIC_BANDS_BUTTON -> ';
        let isShowShopMagicBandsButton = false;
        if (this.siteId) {
            if (this.siteId === this.siteIdConstant.WDW) {
                if (this.guestLangPref) {
                    const IS_GUEST_LANG_PREFERENCE_A_VALID_US_GEO_LOCALE =
                        localLocaleUS.includes(this.guestLangPref);
                    if (IS_GUEST_LANG_PREFERENCE_A_VALID_US_GEO_LOCALE) {
                        isShowShopMagicBandsButton = true;
                        this.logger.log(LOG_MESSAGE_PREFIX +
                            'SUCCESS -> IS_GUEST_LANG_PREFERENCE_A_VALID_US_GEO_LOCALE ' +
                            'has a value of \"' +
                            IS_GUEST_LANG_PREFERENCE_A_VALID_US_GEO_LOCALE + '\".' +
                            ' isShowShopMagicBandsButton has a value of ' +
                            '\"' + isShowShopMagicBandsButton + '\".');
                    } else {
                        this.logger.warn(LOG_MESSAGE_PREFIX +
                            'Unable to process isShowShopMagicBandsButton boolean ' +
                            'because IS_GUEST_LANG_PREFERENCE_A_VALID_US_GEO_LOCALE ' +
                            'has a value of \"' +
                            IS_GUEST_LANG_PREFERENCE_A_VALID_US_GEO_LOCALE + '\".' +
                            'isShowShopMagicBandsButton has a value of ' +
                            '\"' + isShowShopMagicBandsButton + '\".');
                    }
                } else {
                    this.logger.warn(LOG_MESSAGE_PREFIX +
                        'Unable to process isShowShopMagicBandsButton boolean ' +
                        'because this.guestLangPref is null. ' +
                        'isShowShopMagicBandsButton has a value of ' +
                        '\"' + isShowShopMagicBandsButton + '\".');
                }
            } else {
                this.logger.log(LOG_MESSAGE_PREFIX +
                    'Unable to process isShowShopMagicBandsButton boolean ' +
                    'because this.siteId does NOT have a value of ' +
                    '\"' + this.siteIdConstant.WDW + '\" ' +
                    'isShowShopMagicBandsButton has a value of ' +
                    '\"' + isShowShopMagicBandsButton + '\".');
            }
        } else {
            this.logger.warn(LOG_MESSAGE_PREFIX +
                'Unable to process isShowShopMagicBandsButton boolean ' +
                'because this.siteId is null.' +
                'isShowShopMagicBandsButton has a value of \"' +
                isShowShopMagicBandsButton + '\".');
        }
        return isShowShopMagicBandsButton;
    }

    private processPageViewAnalyticsEvent() {
        const LOG_MESSAGE_PREFIX = 'MB_ANALYTICS_PROCESS_PAGE_VIEW_ANALYTICS_EVENT -> ';
        const LOG_MESSAGE_ERROR_PREFIX = 'ERROR -> ';
        const LOG_MESSAGE_INFO_PREFIX = 'INFO -> ';
        const LOG_MESSAGE_WARNING_PREFIX = 'WARNING -> ';
        const LOG_MESSAGE_SUCCESS_PREFIX = 'SUCCESS -> ';
        const ANALYTICS_LANGUAGE_CODE_DEFAULT = 'en';
        const ANALYTICS_COUNTRY_CODE_DEFAULT = 'US';
        const ANALYTICS_ACTIVE: boolean = this.configService.getValue('ANALYTICS_ACTIVE');
        const ANALYTICS_REPORT_SUITE_WDW: string = this.configService.getValue('ANALYTICS_REPORT_SUITE_WDW');
        const ANALYTICS_REPORT_SUITE_DCL: string = this.configService.getValue('ANALYTICS_REPORT_SUITE_DCL');

        if (ANALYTICS_ACTIVE) {
            this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' + ANALYTICS_ACTIVE +
                '\" for this request, thus processing related to the page view analytics event will proceed...');
            if (this.isSiteWdw) {
                this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' + ANALYTICS_ACTIVE +
                    '\" for this request, and the WDW site is detected, thus processing related to the ' +
                    'page view analytics event will proceed...');
                if (ANALYTICS_REPORT_SUITE_WDW) {
                    this.analyticsReportSuite = ANALYTICS_REPORT_SUITE_WDW;
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' + ANALYTICS_ACTIVE +
                        '\" for this request, and the WDW site is detected, and the analyticsReportSuite has been ' +
                        'sucessfully detected with a value of ' + this.analyticsReportSuite + ', thus processing ' +
                        'related to the page view analytics event will proceed...');
                } else {
                    this.logger.error(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for WDW, however no value is detected for the ANALYTICS_REPORT_SUITE_WDW ' +
                        'thus processing ' + 'related to the page view analytics event will not proceed.');
                }
            } else if (this.isSiteDcl) {
                this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                    ANALYTICS_ACTIVE + '\" for this request, and the DCL site is detected, thus processing related to the ' +
                    'page view analytics event will proceed...');
                if (ANALYTICS_REPORT_SUITE_DCL) {
                    this.analyticsReportSuite = ANALYTICS_REPORT_SUITE_DCL;
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for this request, and the DCL site is detected, and the ' +
                        'analyticsReportSuite has been ' + 'sucessfully detected with a value of ' +
                        this.analyticsReportSuite + ', thus processing related to the page view analytics ' +
                        'event will proceed...');
                } else {
                    this.logger.error(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for DCL, however no value is detected for the ANALYTICS_REPORT_SUITE_WDW ' +
                        'thus processing related to the page view analytics event will not proceed.');
                }
            }
            if (this.analyticsReportSuite) {
                if (this.guestGeoLocale) {
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for this request, and the analyticsReportSuite has been sucessfully detected ' +
                        'with a value of \"' + this.analyticsReportSuite + '\", and locale has been detected with a value of \"' +
                        this.guestGeoLocale + '\" thus processing related to the page view analytics event will proceed...');
                    if (this.guestGeoLocale.includes('-')) {
                        let localeArray: string[] = this.guestGeoLocale.split('-');
                        if (localeArray.length === 2) {
                            this.analyticsLanguageCode = localeArray[0];
                            this.analyticsCountryCode = localeArray[1];
                        } else {
                            this.logger.error(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE has a ' +
                                'value of ' + ANALYTICS_ACTIVE + ' for this request, however localeArray.length is not ' +
                                'the expected 2 value thus processing related to the page view analytics event will ' +
                                'not proceed.  The value for this.locale is \"' + this.guestGeoLocale + '\".');
                        }
                    }
                } else {
                    this.logger.error(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for this request, however locale is not detected ' +
                        'thus processing related to the page view analytics event will not proceed.' +
                        'The value for this.locale is \"' + this.guestGeoLocale + '\".');
                }
                if (!this.analyticsLanguageCode) {
                    this.analyticsLanguageCode = ANALYTICS_LANGUAGE_CODE_DEFAULT;
                    this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for this request, however analyticsLanguageCode is not detected ' +
                        'thus defaulting to the ANALYTICS_LANGUAGE_CODE_DEFAULT value \"' + ANALYTICS_LANGUAGE_CODE_DEFAULT +
                        '\" so that processing related to the page view analytics event can proceed...' +
                        'The value for analyticsLanguageCode is \"' + this.analyticsLanguageCode + '\".');
                }
                if (!this.analyticsCountryCode) {
                    this.analyticsCountryCode = ANALYTICS_COUNTRY_CODE_DEFAULT;
                    this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for this request, however analyticsCountryCode is not detected ' +
                        'thus defaulting to the ANALYTICS_COUNTRY_CODE_DEFAULT value \"' + ANALYTICS_COUNTRY_CODE_DEFAULT +
                        '\" so that processing related to the page view analytics event can proceed...' +
                        'The value for analyticsCountryCode is \"' + this.analyticsCountryCode + '\".');
                }
                if (this.analyticsLanguageCode && this.analyticsCountryCode) {
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'Attempting to send analyticsService.update() ' +
                        'request with analyticsLanguageCode \"' + this.analyticsLanguageCode + '\", analyticsCountryCode \"' +
                        this.analyticsCountryCode + '\" and the following analyticsReportSuite -> \"' +
                        this.analyticsReportSuite + '\"');
                    this.analyticsService.getModel().then((model: any) => {
                        model.configuration = {
                                SiteCatalyst: {
                                    reportSuiteId: this.analyticsReportSuite
                                }
                            };
                        model.site = this.siteId;
                        model.pageId = 'landing';
                        model.siteSection = 'profile/bands';
                        model.errorCodes = '';
                        model.contentLocale = this.analyticsCountryCode;
                        model.contentLanguage = this.analyticsLanguageCode;
                        model.marketingRegion = this.analyticsCountryCode;
                        this.analyticsService.update();
                        this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_SUCCESS_PREFIX + 'Successfully sent analyticsService.update() ' +
                            'request with analyticsLanguageCode \"' + this.analyticsLanguageCode + '\", analyticsCountryCode \"' +
                            this.analyticsCountryCode + '\" and the following analyticsReportSuite -> \"' +
                            this.analyticsReportSuite + '\"');
                    });
                } else {
                    this.logger.error(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' +
                        ANALYTICS_ACTIVE + '\" for this request, however the analyticsLanguageCode and or the ' +
                        'analyticsCountryCode are not defined thus processing related to the page view analytics event ' +
                        'will not proceed.');
                }
            } else {
                this.logger.error(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE has a value of \"' + ANALYTICS_ACTIVE +
                    '\" for this request, however the analyticsReportSuite is not defined thus processing related ' +
                    'to the page view analytics event will not proceed.');
            }
        } else {
            this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_ERROR_PREFIX + 'ANALYTICS_ACTIVE does not have a value of true for this ' +
                'request thus processing related to the page view analytics event will not proceed.');
        }
    }

    /**
     * Call ProfileService for the Guest's profile
     */
    getProfileData() {
        return this.profileService.getCompactProfile().subscribe({
            next: (profile: GuestProfile) => {
                this.loggedInGuestXID = profile.xid;
                this.loggedInGuestAvatarId = profile.avatarId;

            },
            error: err => {
                this.logger.error(`Get Profile Data Error: ${err}`);
            }
        });
    }

    getLocale() {
        let locale = this.localeService.getLocale();
        locale = locale || LOCALE_EN_US;
        return locale;
    }

    private handleError() {
        this.hasError = true;
        this.data = this.defaultData;
        return null;
    }

    public setHeaderBackground() {
        const device = this.device_type[this.viewport.getDevice()];
        let backGroundImageURL;
        if (typeof this.backgroundImages === 'object') {
            backGroundImageURL = this.cdnSerserve.getCDNUrl(this.backgroundImages[device]);
            this.backgroundImage = 'url(' + backGroundImageURL + '),linear-gradient(to right,#705FAA 30%,#1C87A3 70%)';
        } else {
            backGroundImageURL = this.cdnSerserve.getCDNUrl(this.backgroundImages);
            this.backgroundImage = 'url(' + backGroundImageURL + ')';
        }
    }

    /**
     *
     * @param {object} bands
     */
    private adornBandsWithMediaType(bands) {
        let band: IManagedBand;
        for (const bandIndex in bands) {
            if (bands[bandIndex]) {
                band = bands[bandIndex];
                if (band.productCode.match(CARD_TYPE_REGEX)) {
                    bands[bandIndex].mediaType = MEDIA_TYPE.CARD;
                } else if (band.productCode.match(SLAPBAND_TYPE_REGEX)) {
                    bands[bandIndex].mediaType = MEDIA_TYPE.SLAPBAND;
                } else {
                    bands[bandIndex].mediaType = MEDIA_TYPE.BAND;
                }
            }
        }
    }

    private environmentName() {
        const location = this.windowRef.nativeWindow.location;
        const lowerEnvironmentRegEx = HOST_REGEX;
        const locationString = location.toString();
        const lowerEnvMatch = locationString.match(lowerEnvironmentRegEx);
        return lowerEnvMatch ? lowerEnvMatch[1] : '';
    }

    /**
     * This function returns a boolean depending on if the copy for the key has content so that we can hide the entire block contained
     * in the HTML element. This will eliminate blank spaces in the UI between sites that do not have that specific copy.
     *
     *
     * @param {string} key - The DScribe traslation key
     * Return value Boolean
     */
    public isTranslationEmpty(key) {
        this.translate.get(key)
            .subscribe((translation: object) => {
                return translation.toString() === '';
            });
    }

    private sortManagedBandsByProductCode() {
        for (let segment in this.managedBands) {
            if (this.managedBands.hasOwnProperty(segment)) {
                for (let id in this.managedBands[segment]) {
                    if (this.managedBands[segment].hasOwnProperty(id)) {
                        if (this.managedBands[segment][id].length > 1) {
                            // Sort band and media in reverse order so plus bands show fiest.
                            this.managedBands[segment][id].sort((a, b) => {
                                if (a.productCode < b.productCode) {
                                    return 1;
                                } else if (a.productCode > b.productCode) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });
                            // Sort my media type to move cards to the end of the array.
                            this.managedBands[segment][id].sort((a, b) => {
                                if (a.mediaType < b.mediaType) {
                                    return -1;
                                } else if (a.mediaType > b.mediaType) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            });
                        }
                    }
                }
            }
        };
    }

    private isMepCard(productCode): boolean {
        return MEP_PASSES.indexOf(productCode) > -1 || MEP_GUEST_PASSES.indexOf(productCode) > -1;
    }

    /**
    *
    * @param {object} bands
    */
    private groupByGuestType(bands) {
        let band: IManagedBand;
        let loggedInGuestBands = {};
        let guestPassBands = {};
        let friendGuestsBands = {};
        let mediaToKeep = [];
        if (this.siteId === SITE.DCL) {
            // Remove all non Plus bands and MEP cards for DCL only.
            for (let i in bands) {
                if (bands[i].productCode.match(KTTW_PLUS_BAND_REGEX)) {
                    mediaToKeep.push(bands[i]);
                }
            }
            bands = mediaToKeep;
        }
        for (let i in bands) {
            if (bands.hasOwnProperty(i)) {
                band = bands[i];
                const guest = this.data.guests[band.xid] || null;
                if (guest) {
                    if (this.loggedInGuestXID === band.xid) {
                        if (!loggedInGuestBands[this.loggedInGuestXID]) {
                            loggedInGuestBands[this.loggedInGuestXID] = [];
                        }
                        loggedInGuestBands[this.loggedInGuestXID].push(band);
                    } else if (guest.mepSerialNumber) {
                        if (!guestPassBands[band.xid]) {
                            guestPassBands[band.xid] = [];
                        }
                        guestPassBands[band.xid].push(band);
                    } else {
                        // We need to filter out MEP cards from the guest since they are displayed separately.
                        if (!this.isMepCard(band.productCode)) {
                            if (!friendGuestsBands[band.xid]) {
                                friendGuestsBands[band.xid] = [];
                            }
                            friendGuestsBands[band.xid].push(band);
                        }
                    }
                }
            }
        }
        if (Object.keys(loggedInGuestBands).length) {
            this.managedBands.mine = loggedInGuestBands;
        }
        if (Object.keys(guestPassBands).length) {
            this.managedBands.passes = guestPassBands;
        }
        if (Object.keys(friendGuestsBands).length) {
            this.managedBands.friends = friendGuestsBands;
        }

        this.sortManagedBandsByProductCode();
    }
}

function getConsolidatedGuest(responses): GuestProfileCore {
    // Unpack responses.
    const { profileGuest, compactGuest, oneIdGuest } = responses;
    // Pull avatarId, guestIdentifiers, name and ageBand from /compact-profile.
    let { avatarId, guestIdentifiers, name, ageBand } = compactGuest;
    // Pull phone and address info from OneId.
    const { phones, addresses } = oneIdGuest.profile;
    // Pull the rest from the main profile call.
    /*
    if (avatarId) {
        if (!avatarId.includes(';avatarId')) {
            avatarId = avatarId + ';avatarId';
        }
    }
    */
    const props = {
        ...profileGuest,
        name,
        phones,
        ageBand,
        avatarId,
        addresses,
        guestIdentifiers
    };

    return new GuestProfileCore(props);
}
