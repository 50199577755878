<div
    *ngIf="guestID"
    id="guest-{{guestID}}"
    [ngClass]="{
        'guestSelection': true,
        'managed-section': managedBands
    }"
>
    <div class="guestAvatar" *ngIf="!managedBands">
        <img wdpr-ra-angular-cdn src="{{getGuestAvatar(guestID)}}">
        <div class="mediaThumb" *ngIf="confirmedCustomization">
            <div class="mediaContainer">
                <img
                    src="{{getMediaImage()}}"
                    class="band"
                />
            </div>
        </div>
    </div>
    <div class="guestNameAndDetails">
        <h4 class="guestName" *ngIf="!isGuestPass">
            {{guest.prefix}}
            {{guest.firstName}}
            {{guest.middleName}}
            <span *ngIf="siteID !== siteIdConstant.DCL">
                {{guest.lastName}}
                {{guest.suffix}}
            </span>
        </h4>
        <h4 class="guestName" *ngIf="isGuestPass">
            <span translate>descriptions.magicbandspage.sections.guest_pass_name</span>  
            <span *ngIf="siteID !== siteIdConstant.DCL">
                {{removeLeadingZeros(guest.mepSerialNumber)}}
            </span>
        </h4>
        <h3 *ngIf="siteID === siteIdConstant.DCL" class="guestLastName">
            {{guest.lastName}}
            {{guest.suffix}}
        </h3>

        <div
            *ngIf="age !== ''"
            class="age"
            [translateParams]="{age: age}"
            [translate]="'descriptions.magicbandspage.sections.age'"
        ></div>
    </div>
</div>
