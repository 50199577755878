<wdpr-modal #modal disable-close-on-background="true" disable-close-on-escape="true" hide-close-icon="true">
    <div slot="wrapper">
        <h3 slot="title" class="heavy modal-title" [ngClass]=class [innerHTML]="config.title | translate"></h3>
        <div slot="body" class="modal-body" [ngClass]=class [innerHTML]="config.body | translate"></div>
        <wdpr-button
            slot="buttons"
            theme="{{ buttonType.Primary }}"
            [hidden]="loading"
            (click)="buttonClick(buttonType.Primary)">{{ config.primaryButtonLabel | translate }}</wdpr-button>
        <wdpr-loading
            slot="buttons"
            size="small"
            [hidden]="!loading"></wdpr-loading>
        <wdpr-button *ngIf="config.secondaryButtonLabel"
            slot="buttons"
            theme="{{ buttonType.Secondary }}"
            (click)="buttonClick(buttonType.Secondary)">{{ config.secondaryButtonLabel | translate }}</wdpr-button>
    </div>
</wdpr-modal>
