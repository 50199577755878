import { MEDIA_OPTIONS, curlyBracketRegex } from '@profile/shared/constants/app.constants';
import * as moment from 'moment';

export function formatImageURL(imageURL, imageSize) {
    if (!imageURL) {
        return imageURL;
    }
    const mediaFormat = {};
    Object.assign(mediaFormat, imageSize, MEDIA_OPTIONS.format);
    let formattedImageURLMatch = imageURL.match(curlyBracketRegex);
    let i = 0;
    while (formattedImageURLMatch && i < 10) {
        i++;
        imageURL = imageURL.replace(formattedImageURLMatch[0], mediaFormat[formattedImageURLMatch[1]]);
        formattedImageURLMatch = imageURL.match(curlyBracketRegex);
    }
    return imageURL;
}

export function replaceCurlyValue(string, key, value) {
    const keyedCurlyBracketRegex = new RegExp('\\{' + key + '\\}', 'i');
    return string.replace(keyedCurlyBracketRegex, value);
}

export function formatExternalNumber(value) {
    return value ? value.replace(/([\d\w]{4})([\d\w]{4})([\d\w]{4})/i, '$1-$2-$3') : '';
}

export function formatTitleCase(value) {
    return value.replace(
        /\w\S*/g,
        function (val) {
            return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
        }
    );
}

export function getGuestAvatar(data, xid) {
    const defaultAvatar = '/assets/images/avatars/avatarretina.png';
    return data.guests[xid] && data.guests[xid].avatarUrl ? data.guests[xid].avatarUrl : defaultAvatar;
}

export function getValue(data, key) {
    return data[key] ? data[key] : '';
}

/**
 * Converts text representation of a boolean (i.e. 'true', 'TRUE', 'True', '1') to a boolean object.
 * @param value - the string representation of the boolean.
 * @returns boolean
 */
export function toBoolean(value: string) {
    value = value.toLowerCase();
    const truths: string[] = [
        'true',
        '1'
    ];
    return truths.includes(value);
}

export function removeLeadingZeros(number) {
    return number.replace(/^0+/, '');
}

export function getDateRangeString(startDate, endDate) {
    const arrivalDate = moment.utc(startDate);
    const departureDate = moment.utc(endDate);

    if (arrivalDate.format('M YYYY') !== departureDate.format('M YYYY')) {
        return arrivalDate.format('MMMM D, YYYY') + ' - ' + departureDate.format('MMMM D, YYYY');
    } else if (arrivalDate.format('M') !== departureDate.format('M')) {
        return arrivalDate.format('MMMM D') + ' - ' + departureDate.format('MMMM D, YYYY');
    } else {
        return arrivalDate.format('MMMM D') + ' - ' + departureDate.format('D, YYYY');
    }
}

export function isEmpty(value) {
    const str = value;
    return (!str || str === undefined || str.trim().length === 0);
}
