import { Injectable } from '@angular/core';
import { WindowRef } from '../window-ref/window-ref';
import { HttpService } from '../http-service/http-service';
import { SessionEventType } from '../authenticator/authenticator';

export interface ICookie {
  name: string;
  value?: string;
  domain?: string;
  exp?: string;
}

const serviceUrl = '/profile-api/authentication/session';

/**
 * Calls Profile WebAPI to get a JWT for the logged-in guest.
 * Response includes several cookies needed to persist the PEPCOM session,
 * we then set the rest of them here.
 */

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  hostname: string;

  constructor(
    private http: HttpService,
    private windowRef: WindowRef
  ) {
    this.hostname = windowRef.nativeWindow.location.hostname;
  }

  /**
   * Initialize session by posting to WebAPI, which sends back the necessary cookies.
   * requestRetries can be edited so we can retry the http post request n times
   */
  init(guest, sessionEventType: SessionEventType, requestRetries = 1): Promise<any> {
    // reject if we don't have the fields needed to initialize the session
    if (!guest.token
      || !guest.token.swid
      || !guest.token.access_token
      || !guest.token.refresh_token
      || !guest.profile) {
      return Promise.reject('Malformed response');
    }

    const data = {
      swid: guest.token.swid,
      accessToken: guest.token.access_token,
      refreshToken: guest.token.refresh_token,
      sessionEventType: sessionEventType
    };

    return this.http.post(serviceUrl, data)
      .then(response => {
        this.setSessionCookies(this.getSessionCreateCookieConfig(guest));
        return response;
      })
      .catch(error => {
        // If we have an error response from the API request, we retry n times.
        if (requestRetries > 0) {
          return this.init(guest, sessionEventType, requestRetries - 1);
        }
        // No more retries left, reject with the last error
        return Promise.reject(error);
        });
  }

  /**
   * Returns configuration for the cookies set on login.
   * (Cookies configured without an expiration will expire with the browser session.)
   */
  getSessionCreateCookieConfig(guest?): Array<ICookie> {
    const thirtyMinutes = new Date();
    thirtyMinutes.setMinutes(thirtyMinutes.getMinutes() + 30);

    const thirtyDays = new Date();
    thirtyDays.setDate(thirtyDays.getDate() + 30);

    const cookies: Array<ICookie> = [
      {
        name: 'authenticationSecure',
        value: 'true',
        domain: this.hostname,
        exp: thirtyMinutes.toUTCString()
      },
      {
        name: 'rememberme',
        value: guest ? this.getRememberMeValue(guest) : '',
      },
      {
        name: 'SESSION_TIMEOUT',
        value: this.getSessionTimeoutValue(guest),
        domain: this.hostname,
      },
      {
        name: 'SWID',
        value: guest ? guest.token.swid : '',
        domain: this.hostname,
        exp: thirtyDays.toUTCString()
      }
    ];

    return cookies;
  }

  /**
   * Returns the remember me cookie value
   */
  getRememberMeValue(guest): string {
    guest = guest || {};
    guest.profile = guest.profile || {firstName: '', lastName: ''};
    guest.token = guest.token || {swid: ''};
    return encodeURIComponent(JSON.stringify({
      name: guest.profile.firstName,
      lastName: guest.profile.lastName,
      avatar: '', // We have to leave this empty b/c it would require making an additional call
      swid: guest.token.swid
    }));
  }

  /**
   * Returns SESSION_TIMEOUT cookie value from the guest token,
   * or the App Time cookie override if set.
   */
  getSessionTimeoutValue(guest: any = {}): string {
    let value = '';

    if (guest.token && guest.token.exp) {
      value = new Date(guest.token.exp).toISOString();
    }

    const appTimeCookie = 'setSystemTime';
    const cookieOverrideValue = this.getCookie(appTimeCookie);

    // Check for PHP App Time override cookie in ISO8601 format
    if (cookieOverrideValue) {
      const date = new Date(cookieOverrideValue);
      date.setMinutes(30 + date.getMinutes()); // Add 30 minutes
      value = date.toISOString();
    }

    return value;
  }

  /**
   * Returns configuration for cookies that get deleted on logout.
   * Combined with the cookies created on login in getSessionCreateCookieConfig()
   */
  private getSessionDestroyCookieConfig(): Array<ICookie> {
    const cookies: Array<ICookie> = [
      {
        name: 'isLoggedIn',
      },
      {
        name: 'localeCookie_jar_aka',
      },
      {
        name: 'localeCookie_jar',
        domain: this.hostname
      },
      {
        name: 'passholdergateCookie',
        domain: this.hostname
      },
      {
        name: 'pep_jwt_token',
        domain: this.hostname
      },
      {
        name: 'pep_oauth_refresh_token_pp',
        domain: '.disney.go.com'
      },
      {
        name: 'pep_oauth_refresh_token',
        domain: this.hostname
      },
      {
        name: 'pep_oauth_token',
        domain: this.hostname
      },
      // delete both SWID cookies if set for different domains
      {
        name: 'SWID',
        domain: '.go.com'
      },
      {
        name: 'WOMID',
        domain: '.disney.go.com'
      },
    ];

    return cookies.concat(this.getSessionCreateCookieConfig());
  }

  /**
   * Sets cookies after a successful login.
   */
  setSessionCookies(config: Array<ICookie>) {
    config.forEach((cookie: ICookie) => this.setCookie(cookie));
  }

  /**
   * Update session cookies
   */
  update(): Promise<any> {
    return this.deleteHttpOnlyCookies();
  }

  /**
   * Calls WebAPI, which sends back the cookie delete headers for
   * http-only cookies, which we can't delete from the client
   */
  private deleteHttpOnlyCookies(): Promise<any> {
    return this.http.delete(serviceUrl);
  }

  /**
   * Destroy session cookies
   */
  destroy(): Promise<any> {
    this.getSessionDestroyCookieConfig()
      .forEach(cookie => this.deleteCookie(cookie));
    return this.deleteHttpOnlyCookies();
  }

  /**
   * Deletes a cookie
   */
  deleteCookie(cookie: ICookie) {
    cookie.value = '';
    cookie.exp = 'Thu, 01 Jan 1970 00:00:01 GMT';
    this.setCookie(cookie);
  }

  /**
   * Builds the cookie string and sets in on the native document
   */
  setCookie(cookie: ICookie) {
    let cookieString = `${cookie.name}=${cookie.value}; path=/;`;
    if (cookie.domain) {
      cookieString += ` domain=${cookie.domain};`;
    }
    if (cookie.exp) {
      cookieString += ` expires=${cookie.exp};`;
    }
    cookieString += ' secure;';
    this.windowRef.nativeWindow.document.cookie = cookieString;
  }

  /**
   * Returns cookie for the given name
   */
  getCookie(name: string) {
    name = name + '=';
    const decodedCookie = decodeURIComponent(this.windowRef.nativeWindow.document.cookie);
    const cookies = decodedCookie.split('; ');
    let res;
    cookies.forEach(cookieValue => {
      if (cookieValue.indexOf(name) === 0) {
        res = cookieValue.substring(name.length);
      }
    });
    return res;
  }
}
