import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { ConfigService, LocaleService } from '@wdpr-profile/core';
import { MAGIC_BAND_API_CONSTANTS, LOCALE_EN_US } from '@profile/shared/constants/app.constants';

// These are used for fallback.
import * as enLang from '@assets/i18n/wdw/en-us.json';

@Injectable()
export class CustomLoader implements TranslateLoader {
    siteId;
    langFile = enLang;
    contentHeader = new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    });
    isCDNEnabled;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private localeService: LocaleService,
    ) {
        this.siteId = this.configService.getValue('siteId');
        const cdnList = this.configService.getValue('cdnList');
        this.isCDNEnabled = Boolean(cdnList.length && cdnList[0] !== '');
    }

    getLocale() {
        let locale = this.localeService.getLocale();
        locale = locale || LOCALE_EN_US;
        return locale;
    }

    getTranslation(lang: string): Observable < any > {
        const locale = this.getLocale();
        let dscribeFileURL;

        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Accept', 'application/json');
        httpHeaders.set('Content-Type', 'application/json');

        const httpOptions = {
            headers: httpHeaders
        };

        const siteLangFile = this.langFile;

        // change describeFileUrl to any value to force the call to trigger the error and use static content
        // dscribeFileURL = '';
        dscribeFileURL = MAGIC_BAND_API_CONSTANTS.dscribeFileURLValue(locale);
        return new Observable(observer => {
            this.http.get(dscribeFileURL, httpOptions)
                .subscribe((res: Response) => {
                        // If the call was successful, send the JSON from the res object.
                        observer.next(res);
                        observer.complete();
                    },
                    error => {
                        // If the call was NOT successful, send the JSON from the assets
                        // folder for the correct site ID.
                        observer.next(siteLangFile);
                        observer.complete();
                    }
                );
        });
    }
}
