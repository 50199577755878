import {
    IShippment
  } from '@profile/shared/interfaces/magic-bands.interface';
import { getDateRangeString, isEmpty } from '@profile/shared/utils/utils';
import * as moment from 'moment';

export class ShippingLabel {

    constructor( private shippingData: IShippment ) {}

    /**
     * Returns an array of earliest and latest expected arrival days after the shipDate
     */
    protected arrivalRange(deliveryMethod: string = 'RP'): Number {
        const methodRange = {
            'RP': [5, 7],
            'THREE_DAY': [3, 4],
            'TWO_DAY': [2, 3],
            'NEXT_DAY_AM': [1, 1],
            'NEXT_DAY_PM': [1, 1],
            'SATURDAY': [2, 7], // @todo figure out what the values should be from this line down
            'EP': [2, 7],
            'EX': [2, 7],
            'N8': [2, 7],
            'MESSENGER': [2, 7]
        };
        return methodRange[deliveryMethod];
    }

    /**
     * Return a number indicating how many days after shipDate we should stop showing an order based on shipMethod
     * Setting the function return type causes a Operator '>=' cannot be applied to types 'number' and 'Number' error.
     */
    protected arrivalCutoff(deliveryMethod: string = 'RP') {
        const methodCutoff = {
            'RP': -8,
            'THREE_DAY': -4,
            'TWO_DAY': -3,
            'NEXT_DAY_AM': -2,
            'NEXT_DAY_PM': -2,
            'SATURDAY': -8, // @todo figure out what the values should be from this line down
            'EP': -8,
            'EX': -8,
            'N8': -8,
            'MESSENGER': -8
        };

        return methodCutoff[deliveryMethod];
    }

    get arrivalRangeString(): string {
        const arrivalRange = this.arrivalRange(this.shippingData.method);
        const estArrivalDateStart = moment(this.shippingData.shippingDate).add(arrivalRange[0], 'days');
        const estArrivalDateEnd = moment(this.shippingData.shippingDate).add(arrivalRange[1], 'days');

        return getDateRangeString(estArrivalDateStart, estArrivalDateEnd);
    }

    /**
     * Using the shipping method we need to determine a reasonable amount of days that we can show an order.
     */
    get showOrder(): boolean {
        const today = moment().startOf('day');
        const arrivalCutoff = this.arrivalCutoff(this.shippingData.method);
        if (isEmpty(this.shippingData.shippingDate) || isEmpty(this.shippingData.method)) {
            return false;
        }
        const shipDate = moment.utc(this.shippingData.shippingDate);
        /**
         * The diff is required to be a negative number so we reverse the usual diff parameters
         * since the arrivalCutoff value is a negative number to get an accurate comparison.
        */
        return shipDate.diff(today, 'days') >= arrivalCutoff;
    }

    get carrier() {
        const carrier = this.shippingData.carrier ? this.shippingData.carrier : null;
        return carrier;
    }

    get carrierLink() {
        const carrierLink = this.shippingData.carrierLink ? this.shippingData.carrierLink : null;
        return carrierLink;
    }

    get trackingNumber() {
        const trackingNumber = this.shippingData.trackingNumber ? this.shippingData.trackingNumber : null;
        return trackingNumber;
    }

    get recipientName() {
        const recipientName = this.shippingData.recipientName ? this.shippingData.recipientName : null;
        return this.shippingData.recipientName || null;
    }

    get alternateShippingDestination() {
        const resortShipmentFacilityId = this.shippingData.resortShipmentFacilityId ? this.shippingData.resortShipmentFacilityId : null;
        return resortShipmentFacilityId;
    }
}
