import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input
} from '@angular/core';
import { SITE } from '@profile/shared/constants/app.constants';

import {
    getGuestAvatar,
    removeLeadingZeros
} from '@profile/shared/utils/utils';

import {
    IMagicBandData,
    IGuest,
    imageSizeTShirt,
    ICustomizationSelection
} from '@profile/shared/interfaces/magic-bands.interface';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';
import { DeviceDataService } from '@wdpr/ra-angular-device-detection';
import { ProductData } from '@profile/shared/classes/magicband-product.class';

@Component({
    selector: 'profile-guest-data',
    templateUrl: './guest-data.component.html',
    styleUrls: ['./guest-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuestDataComponent implements OnInit {
    @Input() guestID: string;
    @Input() data: IMagicBandData;
    @Input() managedBands = false;
    @Input() siteID: string;
    @Input() isGuestPass = false;
    @Input() productCode: string;
    @Input() confirmedCustomization: boolean;
    @Input() bandData: ICustomizationSelection;

    guest: IGuest;
    age: string;
    siteIdConstant = SITE;

    protected productData: ProductData;

    defaultGuestData = {
        'xid': '',
        'swid': '',
        'xbmsLinkIds': '',
        'email': '',
        'firstName': '',
        'lastName': '',
        'dateOfBirth': '',
        'gender': '',
        'region': '',
        'ageBand': '',
        'avatarId': '',
        'avatarUrl': 'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/90/90/75/dam' +
            '/wdpro-assets/avatars/180x180/RetAvatar_180x180_Default_SignedIn.png?1623767113969',
        'address': {
            'line1': '',
            'line2': null,
            'city': '',
            'stateProvince': '',
            'postalCode': '',
            'country': '',
            'type': ''
        }
    };
    removeLeadingZeros = removeLeadingZeros;

    constructor(
        private cdnService: CdnService,
        private deviceDataService: DeviceDataService
    ) { }

    ngOnInit() {
        this.guest = this.defaultGuestData;
        if (
            this.guestID
            && this.data.guests
            && this.data.guests[this.guestID]
        ) {
            this.guest = this.data.guests[this.guestID];

            const age = this.guest.age || this.guest.ageBand || '';
            if (age === 'ADULT' || parseInt(age, 10) > 17) {
                this.age = '18+';
            } else {
                this.age = age;
            }
        };
        this.productData = new ProductData(this.data.products, this.cdnService, this.deviceDataService);
    }

    public FormatThumbImageURL(band) {
        return this.productData.getImage(band.productCode, imageSizeTShirt.small, imageSizeTShirt.thumb);
    }

    getGuestAvatar(guestID): string {
        return this.cdnService.getCDNUrl(getGuestAvatar(this.data, guestID));
    }

    getMediaImage(): string {
        return this.productData.getImage(this.productCode, imageSizeTShirt.small, imageSizeTShirt.thumb);
    }
}
