import { ShippingLabel } from '@profile/shared/classes/magicband-shipping-label.class';

export enum imageSizeTShirt {
    thumb = 'thumb',
    small = 'small',
    medium = 'medium',
    large = 'large'
}

export interface IMagicBandData {
    affiliations?: { [key: string]: IAffiliation };
    errors?: Array<IError>;
    facilities?: { [key: string]: IFacility };
    cruiseSailingFacilities?: { [key: string]: IFacility };
    guests?: { [key: string]: IGuest };
    managed?: Array<IManagedBand>;
    orders?: Array<IOrder>;
    products?: { [key: string]: IMagicBandProductDetail };
}

export interface IAccommodation {
    accommodationId: string;
    arrivalDateTime: string;
    departureDateTime: string;
    enterpriseFacilityId: number;
    reservationType: string;
    resort: IFacility;
    travelPlanId: string;
    travelPlanSegmentId: string;
}

export interface IAddress {
    address1?: string;
    address2?: string;
    address3?: string;
    address4?: string;
    city: string;
    country: string;
    dateLastModified?: string;
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    phoneNumber?: string;
    postalCode: string;
    preferred?: boolean;
    state?: string;
    stateProvince?: string;
    type?: string;
}

export interface IAffiliation {
    affiliationType: string;
    expired: boolean;
    passCategory: string;
    passName: string;
    sites: Array<string>;
}

export interface IGuestNameData {
    firstName: string;
    lastName: string;
    middleName?: string;
    prefix?: string;
    suffix?: string;
    title?: string;
}

export interface ICustomizationSelection {
    bandAccessories?: Array<string>;
    bandProductCode: string;
    confirmedCustomization: boolean;
    createDate: string;
    customizationSelectionId: string;
    dvcMember?: boolean;
    entitlements?: Array<string>;
    expirationDate?: string;
    firstName?: string;
    guestId?: string;
    guestIdType?: string;
    lastName?: string;
    optOut: boolean;
    optOutAvailable: boolean;
    primaryGuest: boolean;
    printedName: string;
    qualifyingIds?: Array<{ [key: string]: string }>;
    self?: string;
    updateDate: string;
    xbandOwnerId: string;
    xbandRequestId: string;
    xbandOwnerXid: string;
}

export interface IError {
    type?: string;
    message?: string;
}

export interface IFacility {
    cruiseSailingId?: string;
    id?: string;
    name?: string;
    productCode?: string;
    products?: Object;
    theme?: string;
    vignette?: IHref;
    vignettes?: IVignettes;
}

export interface IProductDetailData {
    [key: string]: IMagicBandProductDetail;
}

export interface IVignettes {
    ap?: IHref;
    desktop?: IHref;
    href?: IHref;
    mobile?: IHref;
}

export interface IGuest {
    address?: IAddress;
    age?: string;
    ageBand?: string;
    avatarId: string;
    avatarUrl: string;
    dateOfBirth?: string;
    email?: string;
    firstName: string;
    gender?: string;
    group?: string;
    guestType?: string;
    languagePreference?: string;
    lastName: string;
    mepSerialNumber?: string;
    middleName?: string;
    prefix?: string;
    region?: string;
    suffix?: string;
    swid?: string;
    title?: string;
    xid?: string;
    xbmsLinkIds?: string;
}

export interface IGuestData {
    [key: string]: IGuest;
}

export interface IHref {
    alt?: string;
    href: string;
    usageType?: string;
    title?: string;
    type?: string;
}

export interface ILink {
    [key: string]: IHref;
}

/**
 * This interface is used to assign type to both Managed bands and bands in orders. All keys are optional since neither
 * type of band share key/value pairs.
**/
export interface IMagicBandAbstract {
    bandProductCode?: string;
    confirmedCustomization?: boolean;
    createDate?: string;
    customizationSelectionId?: string;
    dvcMember?: boolean;
    entitlements?: Array<string>;
    expirationDate?: string;
    externalId?: string;
    optOut?: boolean;
    optOutAvailable?: boolean;
    primaryGuest?: boolean;
    printedName?: string;
    productCode?: string;
    secondaryState?: string;
    state?: string;
    updateDate?: string;
    xbandId?: string;
    xbandOwnerId?: string;
    xbandRequestId?: string;
    xid?: string;
}

export interface IMagicBandProductDetail {
    bottomColorCode: string;
    description: string;
    id?: string;
    images: ILink;
    isSolidColor?: boolean;
    self?: string;
    solidColor?: boolean;
}

export interface IManagedBand {
    assignmentDateTime?: string;
    externalId: string;
    mediaType?: string;
    productCode: string;
    secondaryState: string;
    state: string;
    xbandId: string;
    xid: string;
}

export interface IOrder {
    acquisitionId: string;
    acquisitionIdType: string;
    acquisitionLabel?: string;
    acquisitionStartDate?: string;
    acquisitionUpdateDate: string;
    category?: string;
    createDate: string;
    customizationEndDate?: string;
    customizationSelections: Array<ICustomizationSelection>;
    dualOrderEligible?: boolean;
    holdFulfillment: boolean;
    holdFulfillmentAvailable: boolean;
    holdFulfillmentReasonDescription?: string;
    holdReason?: string;
    isBeforeCustomizationDeadline?: boolean;
    isInReadySatus?: boolean;
    linkedChildAcquisitionIds?: Array<string>;
    options?: string;
    order?: string;
    orderDataComplete?: boolean;
    primaryGuestOwnerId?: string;
    primaryGuestOwnerXid?: string;
    provideShipNowOption?: boolean;
    reorder?: string;
    requestAddress: {
        address: IAddress;
        confirmedAddress: boolean;
        recipientName: string;
        phoneNumber?: string;
    };
    requestId?: string;
    reservation?: IReservation;
    reservations?: Array<IReservation>;
    resortReservations?: Array<IReservation>;
    self?: string;
    shippingLabel?: ShippingLabel;
    shipment?: IShippment;
    shipNow: boolean;
    shipNowAvailable: boolean;
    specialOffer: boolean;
    state: string;
    status?: string;
    updateDate: string;
    xbandRequestId: string;
    xbandRequestNumber?: number;
    xbandRequestStatusCode: string;
}

export interface IRequestAddress {
    recipientName: string;
    confirmedAddress: boolean;
    phoneNumber: string;
    address: IAddress;
}

export interface IReservation {
    accommodations?: Array<IAccommodation>;
    arrivalDate?: string;
    arrivalDateTime?: string;
    confirmationNumber?: string;
    departureDate?: string;
    departureDateTime?: string;
    facilityId?: string | number;
    packageCode?: string;
    packageDescription?: string;
    packageProductId?: number;
    reservationId?: string;
    reservationNumber?: string;
    shipCode?: string;
    stateroom?: { stateroomId: string };
    travelComponentId?: string;
    travelComponentStatus?: string;
    travelPlanId?: string;
    travelSegmentId?: string;
    vignette?: IHref;
    vignettes?: IVignettes;
    voyageId?: string;
}

export interface IShippment {
    address: IAddress;
    carrier?: string;
    carrierLink?: string;
    method: string;
    recipientName: string;
    resortShipmentFacilityId?: string;
    shippingDate?: string;
    trackingNumber?: string;
}

export interface IShipGoLiveData {
    sailing: string;
    onsale: string;
}

export interface IShipGoLiveDates {
    [key: string]: IShipGoLiveData;
}

export interface IManagedMediaCollection {
    friends?: { [key: string]: IManagedBand };
    mine?: { [key: string]: IManagedBand };
    passes?: { [key: string]: IManagedBand };
}
