import { IMagicBandAbstract } from '@profile/shared/interfaces/magic-bands.interface';

import {
  CARD_TYPE_REGEX,
  MEDIA_TYPE
} from '@profile/shared/constants/app.constants';

export class MagicBand {
  isFauxBand: boolean;
  isOrder: boolean;

  private TYPE_NONE = 'NONE';

  constructor(
    private magicband: IMagicBandAbstract
  ) {
    this.isOrder = this.magicband.entitlements ? true : false;
    this.isFauxBand = this.isOrder ?
      this.magicband.entitlements.indexOf(this.TYPE_NONE) > -1
      : false;
  }

  get type() {
    return this.isOrder ? '' : this.magicband.productCode.match(CARD_TYPE_REGEX);
  }
}
