<div #hero
    class="hero-story"
    [ngClass]="{'media-engine': heroData.mediaEngine}">
    <div class="media"
        [ngClass]="{'media-engine': heroData.mediaEngine}">
        <wdpr-media-engine *ngIf="heroData.mediaEngine"
            [version]="mediaEngine.version"
            [theme]="mediaEngine.theme"
            [production]="mediaEngine.production"
            [config]="mediaEngine.config"
            class=theme-{{mediaEngine.theme}}>
        </wdpr-media-engine>
        <div class="cta">
            <div class="cta-container">
                <h1 class="uTitle"
                    [translate]="'descriptions.magicbandspage.sections.title'"></h1>
                <div class="cta-mobile">
                    <a class="uSubTitle"
                        (click)="playbackVideo($event, true)">
                        <span class="text"
                            [translate]="'descriptions.magicbandspage.sections.sub_title'"></span>
                        <span class="di di-media-play-button"></span>
                    </a>
                </div>
                <div class="cta-desktop">
                    <a class="uSubTitle"
                        (click)="playbackVideo($event)">
                        <span class="text"
                            [translate]="'descriptions.magicbandspage.sections.sub_title'"></span>
                        <span class="di di-media-play-button"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
