<h4
    class="m-hide t-hide"
    *ngIf="order.category === categoryTypes.RESORT_CATEGORY"
    [translate]="'descriptions.magicbandspage.sections.entitlement_label_resort-order'"
></h4>
<h4
    class="m-hide t-hide"
    *ngIf="order.category === categoryTypes.DCL_CATEGORY"
    [translateParams]="{stateroom: order.stateroom}"
    [translate]="'descriptions.magicbandspage.sections.stateroom_singular'"
></h4>
<h4
    class="m-hide t-hide"
    *ngIf="order.category === categoryTypes.AP_CATEGORY"
    [translate]="'descriptions.magicbandspage.sections.entitlement_label_passholder'"
></h4>
<div class="primaryColumnContent">
    <div *ngIf="order.category === categoryTypes.RESORT_CATEGORY || order.category === categoryTypes.DCL_CATEGORY">
        <div
            *ngIf="order.category === categoryTypes.DCL_CATEGORY"
            class="orderRowDataLabel"
            [translateParams]="{number: order.acquisitionId}"
            [translate]="'descriptions.magicbandspage.sections.confirmation_number'"
        ></div>
        <div
            *ngIf="order.category === categoryTypes.RESORT_CATEGORY"
            class="name"
        >
            {{order.resortName}}
        </div>
        <div
            *ngIf="order.category === categoryTypes.DCL_CATEGORY"
            class="name"
        >
            {{order.cruiseName}}
        </div>
        <div
            class="resortArrival"
        >
            {{order.reservationDateWindow}}
        </div>
        <div
            *ngIf="order.category === categoryTypes.RESORT_CATEGORY"
            class="orderRowDataLabel"
            [translateParams]="{number: order.reservationNumber}"
            [translate]="'descriptions.magicbandspage.sections.confirmation_number'"
        ></div>
    </div>
    <div *ngIf="order.category === categoryTypes.AP_CATEGORY">
        <div class="name" [translate]="'descriptions.magicbandspage.sections.entitlement_description_ap-order'"></div>
    </div>
</div>