import { Component, ViewChild, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';

import { LoggerService } from '@wdpr/ra-angular-logger';
import '@wdpr/button';
import '@wdpr/modal';
import '@wdpr/loading';

export enum ModalType {
  Default = 'modal-default',
  Error = 'modal-error'
}

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary'
}

export interface IModalConfig {
  body: string;
  primaryButtonLabel: string;
  primaryButtonAction: Function;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: Function;
  title: string;
  type?: ModalType;
}

/**
 * This component is a light-weight wrapper around the wdpr-modal,
 * which allows consistent markup for all modals as well as implementing a loading state
 * for modals that trigger a service call.
 */
@Component({
  selector: 'profile-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('modal', {static: false}) modal: ElementRef;

  config: IModalConfig;
  loading = false;
  buttonType = ButtonType;

  get class(): string {
    return this.config.type ? this.config.type : ModalType.Default;
  }

  constructor(private logger: LoggerService) {}

  /**
   * Defines default configuration for an error modal
   * A parent component can open it with:
   * `this.modal.open(this.modal.defaultErrorConfig)`
   */
  defaultErrorConfig: IModalConfig = {
    body: '',
    primaryButtonLabel: '',
    primaryButtonAction: () => this.close(),
    title: '',
    type: ModalType.Error
  };

  ngOnInit() {
    // Initialize with a minimal configuration
    this.config = {
      body: '',
      title: '',
      primaryButtonLabel: '',
      primaryButtonAction: () => this.close(),
      type: ModalType.Default
    };
  }

  /**
   * Opens the modal with either the existing configuration
   * or with the given configuration
   */
  open(config?: IModalConfig) {
    if (config) {
      this.config = config;
    }

    this.modal.nativeElement.open = true;
  }

  close() {
    this.modal.nativeElement.open = false;
  }

  /**
   * Execute the button callback configured by the parent component
   */
  buttonClick(buttonId: string) {
    if (buttonId === ButtonType.Secondary) {
      if (this.config.secondaryButtonAction) {
        this.config.secondaryButtonAction();
      } else {
        this.logger.error('Modal - secondaryButtonAction not configured');
      }
    } else {
      this.config.primaryButtonAction();
    }
  }

}
