import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  async post(url: string, body: object): Promise<any> {
    return this.doFetch('POST', url, body);
  }

  async delete(url: string): Promise<any> {
    return this.doFetch('DELETE', url);
  }

  protected async doFetch(method: string, url: string, body?: object): Promise<any> {
    const options = <RequestInit> {
      method: method,
      mode: 'same-origin',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    if (body) {
      options['body'] = JSON.stringify(body);
    }

    const response = await fetch(url, options)
      .then(this.checkStatus)
      .then(res => {
        if (res.status === 204) {
          return null;
        }

        return res.json();
      });

    return response;
  }

  protected checkStatus(response: Response): Promise<Response> | Promise<never> {
    if (response.ok) {
      return Promise.resolve(response);
    }

    return response.json().then(error => {
      error.response = response;
      throw error;
    });
  }
}
