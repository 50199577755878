import { Pipe, PipeTransform } from '@angular/core';
import { isString, startsWith, pull, includes } from 'lodash-es';
import { SUPPORTED_LANGUAGES } from 'projects/magicband-spa/src/app/shared/constants/app.constants';
import { LocaleService, WindowRef } from '@wdpr-profile/core';

@Pipe({ name: 'formatExternalUrl' })
export class FormatExternalUrlPipe implements PipeTransform {
    private currentLanguage: string;
    private nativeWindow = this.windowRef.nativeWindow;

    constructor(
        private locale: LocaleService,
        private windowRef: WindowRef
    ) { }

    public transform(url: any, returnKey?: string): any {
        if (!this.currentLanguage) {
            this.setCurrentLanguage();
        }

        if (!isString(url)) {
            return url;
        }

        // Append a trailing '/' to the url (before any query params).
        const queryParamsIndex = url.indexOf('?');
        if (queryParamsIndex > 0 && url[queryParamsIndex - 1] !== '/') {
            url = [url.slice(0, queryParamsIndex), '/', url.slice(queryParamsIndex)].join('');
        } else if (queryParamsIndex < 0) {
            url += url.endsWith('/') ? '' : '/';
        }

        // add return URL
        if (returnKey) {
            // Added a trailing slash since this regex was removing my-di from the return URL of /my-disney-cruise/bands-more
            // now it looks for /ww-ww/ or /ww_ww/
            const localeRegex = /^\/[\w]{2}[-_][\w]{2}\//;
            const pathname = this.nativeWindow.location.pathname.replace(localeRegex, '');
            url += (url.indexOf('?') === -1 ? '?' : '&') + returnKey + '=' +
                encodeURI(pathname + this.nativeWindow.location.search);
        }

        // prepend language if relative url
        if (startsWith(url, '/') && this.currentLanguage) {
            if (this.currentLanguage !== 'en') {
                url = '/' + this.currentLanguage + url;
            }
        }

        return url;
    }

    private setCurrentLanguage(): void {
        const language = this.locale.getLocale();
        const supportedLanguages = SUPPORTED_LANGUAGES;
        const hostname = this.nativeWindow.location.hostname;

        if (hostname.indexOf('disneyworld.co.uk') > 0 || hostname.indexOf('disneyworld.eu') > 0) {
            // languages that we do not want lang in url
            // https://jira-nge.disney.com/browse/GIT-4094
            pull(supportedLanguages, 'en_GB', 'en-eu');
        }

        // check if language is supported
        this.currentLanguage = includes(supportedLanguages, language) ? language : undefined;
    }
}
