import { Injectable } from '@angular/core';

import { BrandConfigItem, IAuthenticatorConfig } from './iauthenticator-config';
import { LanguageCodeService } from '../language-code-service/language-code-service';
import { ScriptLoader } from '../script-loader/script-loader';
import { SessionService } from '../session-service/session-service';
import { LoggerService } from '../logger-service/logger-service';
import { emitEvent } from '../events/emit-event';
import { attachEventListener, detachEventListener } from '../events/event-subscriptions';
import { WindowRef } from '../window-ref/window-ref';

// Session Events
const sessionInit = 'session-init';
const sessionDestroy = 'session-destroy';
const sessionUpdate = 'session-update';

// Custom events handled by Authenticator and not OneID
const customEvents = [
  sessionInit,
  sessionDestroy,
  sessionUpdate
];

export const JWT_TOKEN_COOKIE_NAME = 'pep_jwt_token';
export const AKA_DISNEY_PEPCOM_SESSION = 'pepcom_session_aka';

// Session Event Type
// indicates how a new Guest Token was provisioned : CREATE|LOGIN|REAUTH|REFRESH
export const enum SessionEventType {
  'create' = 'CREATE',
  'login' = 'LOGIN',
  'reauth' = 'REAUTH',
  'refresh' = 'REFRESH'
}

/**
 * Supported brands
 */
export const enum Brand {
  disneyworld,
  disneyland,
  disneycruise,
  hiltonhead,
  verobeach
}

/**
 * Converts text representation of a boolean (i.e. 'true', 'TRUE', 'True', '1') to a boolean object.
 * A null value will default to true.
 * @param value - the string representation of the boolean.
 * @returns boolean
 */
export function toBoolean(value: string) {
  if (!value) {
    return true;
  }

  value = value.toLowerCase();
  const truths: string[] = [
    'true',
    '1'
  ];
  return truths.includes(value);
}

/**
 * OneID API wrapper for handling OneID Lightbox authentication and persisting the PEPCOM session.
 */
@Injectable({
  providedIn: 'root'
})
export class Authenticator {

  /**
   * OneID client instance - should not be accessed publicly
   */
  private _did;

  /**
   * A check for when the OneID instance has been initialized.
   */
  public isInitialized = false;

  /**
   * A boolean to indicate the page needs to be reloaded after the pep_jwt_token has been refreshed.
   */
  protected needsPageRefreshAfterTokenRefresh = false;

  /**
   * A boolean to toggle the pep_jwt_token refreshed.
   */
  protected refreshJwtCookie = true;

  /**
   * Create a proxied version of the public `did` instance for backwards compatibility.
   */
  public get did(): any {
    if (!this._did) {
      return;
    }

    const self = this;
    const handler = {
      get(target, prop) {
        const interceptedMethods = ['on', 'off', 'getTrustStateStatus'];
        if (interceptedMethods.includes(prop)) {
          // Return a closure that wraps the intercepted method
          const interceptMethod = self[prop];
          return function(...args) {
            return interceptMethod.apply(self, args);
          };
        } else {
          return target[prop];
        }
      }
    };

    return new Proxy(this._did, handler);
  }

  /**
   * Maps brand to Client ID and domain name.
   */
  private brandConfig = new Map<Brand, BrandConfigItem>([
    [Brand.disneyworld, {
      clientId: 'TPR-WDW-LBJS.WEB',
      domain: 'disneyworld',
      hasLegacySession: true
    }],
    [Brand.disneyland, {
      clientId: 'TPR-DLR-LBJS.WEB',
      domain: 'disneyland',
      hasLegacySession: true
    }],
    [Brand.disneycruise, {
      clientId: 'TPR-DCL-LBJS.WEB',
      domain: 'disneycruise',
      hasLegacySession: true
    }],
    [Brand.hiltonhead, {
      clientId: 'TPR-HILTONHEAD.ORE.WEB',
      domain: 'hiltonhead',
      hasLegacySession: false
    }],
    [Brand.verobeach, {
      clientId: 'TPR-VEROBEACH-ORE.WEB',
      domain: 'verobeach',
      hasLegacySession: false
    }]
  ]);

  /**
   * Environment-specific OneID script URLs
   */
  private scriptUrlConfig = {
    prod: 'https://cdn.registerdisney.go.com/v4/OneID.js',
    stage: 'https://stg.cdn.registerdisney.go.com/v4/OneID.js',
    load: 'https://val.cdn.registerdisney.go.com/v4/OneID.js'
  };

  /**
   * Returns the brand-specific client ID based on the current URL.
   */
  private get clientId(): string {
    const brand = this.detectBrand();
    return this.brandConfig.get(brand).clientId;
  }

  /**
   * Returns the OneID environment-specific script URL
   */
  private get oneIdScriptSrc(): string {
    const locationHref = this.windowRef.nativeWindow.location.href.toLocaleLowerCase();
    let scriptSrc = this.scriptUrlConfig.prod;

    if (locationHref !== null) {
      if (locationHref.match(/(localhost|local\.|latest\.|stage\.)/)) {
        scriptSrc = this.scriptUrlConfig.stage;
      } else if (locationHref.indexOf('lt01.') > -1) {
        scriptSrc = this.scriptUrlConfig.load;
      }
    }

    return scriptSrc;
  }

  constructor(
    private languageCode: LanguageCodeService,
    private logger: LoggerService,
    private scriptLoader: ScriptLoader,
    private session: SessionService,
    private windowRef: WindowRef,
  ) {}

  protected getJWTTokenRefreshToogle() {
    this.refreshJwtCookie = toBoolean(this.session.getCookie(AKA_DISNEY_PEPCOM_SESSION));
  }

  /**
   * Initializes OneID
   * Only called when running in the authenticator-bundle.js script
   */
  init(config: IAuthenticatorConfig): Promise<boolean | void> {
    // Since this is called when running in the authenticator-bundle.js, we need this here.
    this.getJWTTokenRefreshToogle();
    // Perform a page refrsh unless the 'pepcom_session_aka' cookie is false.
    this.needsPageRefreshAfterTokenRefresh = this.refreshJwtCookie;
    // load OneID script unless there's already a global OneID
    if (config.loadScript && !this.windowRef.nativeWindow.OneID) {
      const oneId = 'oneId';
      this.scriptLoader.init([
        { name: oneId, src: this.oneIdScriptSrc }
      ]);
      return this.scriptLoader.loadScript(oneId)
        .then(() => {
          return this.getClient(config);
        })
        .catch(err => this.logger.error(err));
    } else if (this.windowRef.nativeWindow.OneID) {
      return this.getClient(config);
    } else {
      this.logger.error('Authenticator - OneID not initialized');
    }
  }

  /**
   * Passthrough to OneID.get with sanitized config.
   * Initializes event binding.
   */
  private getClient(config): Promise<boolean> {
    // Other apps may have already instantiated OneID,
    // Check for an existing instance by calling `get`.
    // If there's no instance, it will throw an error and then we'll instantiate it
    try {
      this._did = this.windowRef.nativeWindow.OneID.get();
      this.bindEvents(this._did);
      this.isInitialized = true;
      return Promise.resolve(true);
    } catch {
      const normalizedConfig = this.normalizeConfig(config);
      this._did = this.windowRef.nativeWindow.OneID.get(normalizedConfig);
      return this._did.init()
        .then(() => {
          this.bindEvents(this._did);
          this.isInitialized = true;
          return Promise.resolve(true);
        })
        .catch(err => this.logger.error(err));
    }
  }

  /**
   * Detects brand based on current url.
   */
  private detectBrand(): Brand {
    let brand = Brand.disneyworld;
    const locationHref = this.windowRef.nativeWindow.location.href.toLocaleLowerCase();
    if (locationHref !== null) {
      this.brandConfig.forEach((value: BrandConfigItem, key: Brand) => {
        if (locationHref.indexOf(value.domain) > -1) {
          brand = key;
        }
      });
    }

    return brand;
  }

  /**
   * Returns Lightbox config object
   */
  private normalizeConfig(config): object {
    return {
      clientId: this.clientId,
      responderPage: `${this.windowRef.nativeWindow.location.origin}/authentication/responder.html`,
      debug: false,
      langPref: this.languageCode.getLanguageCode(config.langPref || 'en-US')
    };
  }

  /**
   * Binds to the OneID client events
   * @param did allows injecting an existing OneID client instance
   */
  bindEvents(didInstance?) {
    // Since Profile calls this function first, we need this here.
    this.getJWTTokenRefreshToogle();
    if (didInstance) {
      this._did = didInstance;
    }

    // Initialize or update session
    const sessionEvents = [
      'reauth',
      'primary-account'
    ];
    sessionEvents.forEach(event => {
      this._did.on(event, guest => {
        this.logger.debug(`Authenticator - ${event}`, guest);
        this.logger.remoteLog(`Authenticator - ${event}`, {swid: guest.profile.swid});
        this.onUpdateSession(guest, SessionEventType.reauth);
      });
    });

    this._did.on('create', guest => this.onCreate(guest));

    this._did.on('login', guest => this.onLogin(guest));

    this._did.on('logout', () => this.onLogout());

    this._did.on('error', err => this.logger.error(err));

    this._did.on('refresh', token => this.onRefresh(token));

    this._did.on('update', () => this.onUpdate());

    // Refresh the JWT cookie unless the 'pepcom_session_aka' cookie is set to false.
    if (this.refreshJwtCookie) {
      this.logger.remoteLog('Authenticator - bindEvents: Call JWT token cookie refresh.');
      this.refreshSessionCookies();
    }
  }

  /**
   * Check if the guest is logged in via OneID then check the JWT token cookie.
   * If the cookie is not present or expired, refresh the token cookie.
   */
  protected refreshSessionCookies() {
    this.getLoggedInStatus()
      .then(status => {
        if (status) {
          // there’s an existing OneID session, check for the cookies
          this.logger.remoteLog('Authenticator - refreshSessionCookies: The guest is logged in.');
          const jwtCookie = this.session.getCookie(JWT_TOKEN_COOKIE_NAME);
          if (!jwtCookie) {
            this.logger.remoteLog('Authenticator - refreshSessionCookies: pep_jwt_token cookie has expired.');
            this.getGuest()
              .then(guest => this.onUpdateSession(
                guest,
                SessionEventType.refresh,
                this.needsPageRefreshAfterTokenRefresh
              ));
          }
        } else {
          this.logger.remoteLog('Authenticator - getLoggedInStatus', status);
        }
      }).catch(err => {
        this.logger.remoteLog('Authenticator - refreshSessionCookies', err);
      });
  }

  // Event Handlers

  protected onCreate(guest): Promise<any> {
    this.logger.debug('Authenticator - onCreate', guest);
    this.logger.remoteLog('Authenticator - onCreate', {swid: guest.profile.swid});
    return this.onUpdateSession(guest, SessionEventType.create);
  }

  protected onLogin(guest): Promise<any> {
    this.logger.debug('Authenticator - onLogin', guest);
    this.logger.remoteLog('Authenticator - onLogin', {swid: guest.profile.swid});
    return this.onUpdateSession(guest, SessionEventType.login);
  }

  protected onRefresh(token): Promise<any> {
    this.logger.debug('Authenticator - onRefresh', token);
    this.logger.remoteLog('Authenticator - onRefresh', {swid: token.swid});
    return this.getGuest()
      .then(guest => this.onUpdateSession(guest, SessionEventType.refresh));
  }

  /**
   * Handles events that update the session.
   * Gets the JWT and initializes/updates the session cookies
   * @param guest The guest object to update the session for.
   * @param sessionEventType What action is being taken.
   * @param refreshPage Boolean to let the function know to refresh the current page.
   */
  protected onUpdateSession(guest, sessionEventType: SessionEventType, refreshPage: boolean = false): Promise<any> {
    this.logger.debug('Authenticator - onUpdateSession');
    this.logger.remoteLog('Authenticator - onUpdateSession', {swid: guest.profile.swid});

    const brand = this.detectBrand();

    if (this.brandConfig.get(brand).hasLegacySession) {
      return this.session.init(guest, sessionEventType)
        .then(() => {
          emitEvent(sessionInit, guest);
          if (refreshPage) {
            this.windowRef.nativeWindow.location.reload();
          }
        })
        .catch(err => this.logger.error('Authenticator - Error updating session', err));
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Destroy session
   */
  protected onLogout(): Promise<any> {
    this.logger.debug('Authenticator - onLogout');
    this.logger.remoteLog('Authenticator - onLogout');

    const brand = this.detectBrand();

    if (this.brandConfig.get(brand).hasLegacySession) {
      return this.session.destroy()
        .then(() => emitEvent(sessionDestroy))
        .catch(err => this.logger.error('Authenticator - Error deleting session', err));
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Update PHPSESSID cookie per security requirement
   */
  protected onUpdate() {
    this.logger.debug('Authenticator - onUpdate');
    this.logger.remoteLog('Authenticator - onUpdate');

    const brand = this.detectBrand();

    if (this.brandConfig.get(brand).hasLegacySession) {
      this.session.update()
        .then(() => emitEvent(sessionUpdate))
        .catch(err => this.logger.error('Authenticator - Error updating session', err));
      } else {
      return Promise.resolve();
    }
  }

  // OneID Passthrough methods

  // OneID Passthrough methods - Initialization

  get() {
    return this._did.get();
  }

  setLogLevel(level: string, preserve: boolean = false) {
    return this._did.setLogLevel(level, preserve);
  }

  // OneID Passthrough methods - Login/Registration

  launchLogin() {
    return this._did.launchLogin();
  }

  launchRegistration() {
    return this._did.launchRegistration();
  }

  launchGuestFlow(email_address: string) {
    return this._did.launchGuestFlow(email_address);
  }

  launchIdentityFlow(email_address: string) {
    return this._did.launchIdentityFlow(email_address);
  }

  // OneID Passthrough methods - Session Management

  forceTokenRefresh() {
    return this._did.forceTokenRefresh();
  }

  getLoggedInStatus() {
    return this._did.getLoggedInStatus();
  }

  /**
   * Intercept `getTrustStateStatus` to return a boolean.
   * In v4, OneID started returning 1/0 which broke some of our integrations.
   */
  getTrustStateStatus(): Promise<boolean> {
    return this._did.getTrustStateStatus()
      .then(status => {
        return (status === true || status === 1);
      });
  }

  launchReauth() {
    return this._did.launchReauth();
  }

  logout() {
    return this._did.logout();
  }

  // OneID Passthrough methods - View/Edit User Info

  getGuest(fullPayload = false, options?: any) {
    return this._did.getGuest(fullPayload, options);
  }

  launchProfile() {
    return this._did.launchProfile();
  }

  // OneID Passthrough methods - Newsletters

  launchNewsletters(promotionId: string) {
    return this._did.launchNewsletters(promotionId);
  }

  // OneID Passthrough methods - Event Management

  on(event: string, callback: Function) {
    /**
     * Passthrough to OneID unless it's a custom event,
     * in which case we handle it.
     */
    if (customEvents.includes(event)) {
      attachEventListener(event, callback);
    } else {
      this._did.on(event, callback);
    }
  }

  off(event: string, callback?: Function) {
    /**
     * Passthrough to OneID unless it's a custom event,
     * in which case we handle it.
     */
    if (customEvents.includes(event)) {
      detachEventListener(event, callback);
    } else {
      this._did.off(event, callback);
    }
  }
}
