import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

// RA
import { ErrorHandlerModule } from '@wdpr/ra-angular-error-handler';
import { CorrelationModule } from '@wdpr/ra-angular-correlation';
import { LoggerModule } from '@wdpr/ra-angular-logger';
import { NativeBridgeModule, NativeBridgeProvider } from '@wdpr/ra-angular-native-bridge';
import { PageKeyModule } from '@wdpr/ra-angular-page-key';
import { RaInjectOneidAuthTokenModule } from '@wdpr/ra-angular-oneid-auth-token-injector';
import { RaInjectOneidAuthTokenInterceptor } from '@wdpr/ra-angular-oneid-auth-token-injector';
import { LoggerServiceUrlToken, LoggerServiceMaxLengthToken, LanguageCodeService } from '@wdpr-profile/authenticator';
import { AnalyticsModule } from '@wdpr/angular-analytics';
import { DeviceDetectionModule } from '@wdpr/ra-angular-device-detection';

import { DebugGuardService } from './shared/services/debug-guard-service/debug-guard.service';
import { CastProxyGuardService } from './shared/services/cast-proxy-guard-service/cast-proxy-guard.service';

import { CustomLoader } from './shared/classes/dscribe-custom-loader.class';

import {
    CastProxyService,
    ProfileCoreModule,
    AuthenticationGuard,
    AuthorizationInterceptor,
    ConfigService,
    ConfigServiceStorageKeyToken,
    HighTrustGuard,
    LocaleService,
    ONEID_CONFIG,
    OneIdService,
    ProfileSideNavLayoutComponent,
    SessionStorage,
    SwidInjectorInterceptor,
    SwidHeaderInjectorInterceptor,
    ServiceCallInterceptor,
    SyndicatedFooterComponent,
    SyndicatedHeaderComponent,
    SyndicatedLegalComponent,
    WindowRef,
    SideNavigationComponent,
    OverlayComponent,
    ErrorComponent
} from '@wdpr-profile/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormatExternalUrlPipe } from './shared/utils/pipes/format-external-url/format-external-url.pipe';

import { SharedComponentsModule } from './shared/components/components.module';
import { LOCALE_EN_US, CONFIG_CONSTANTS } from './shared/constants/app.constants';
import { OneIdService as ProfileOneIdService } from './shared/services/oneid-service/oneid.service';
import { SafePipe } from '@profile/shared/utils/pipes/safe/safe.pipe';
import { MagicBandsModule } from './features/magic-bands/magic-bands.module';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string;

export class missingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return '';
    }
}

const APP_PROVIDERS = [
    AuthenticationGuard,
    CastProxyGuardService,
    DebugGuardService,
    ConfigService,
    CookieService,
    CastProxyService,
    FormatExternalUrlPipe,
    HighTrustGuard,
    LanguageCodeService,
    LocaleService,
    SessionStorage,
    WindowRef,
    NativeBridgeProvider
];

@NgModule({
    declarations: [
        AppComponent,
        SafePipe,
        FormatExternalUrlPipe,
    ],
    imports: [
        SideNavigationComponent,
        AppRoutingModule,
        AnalyticsModule,
        BrowserAnimationsModule,
        BrowserModule,
        LoggerModule,
        CorrelationModule,
        ErrorHandlerModule,
        HttpClientModule,
        PageKeyModule,
        ProfileCoreModule,
        ProfileSideNavLayoutComponent,
        RaInjectOneidAuthTokenModule,
        SharedComponentsModule,
        SyndicatedFooterComponent,
        SyndicatedHeaderComponent,
        SyndicatedLegalComponent,
        OverlayComponent,
        ErrorComponent,
        NativeBridgeModule,
        MagicBandsModule,
        DeviceDetectionModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: missingTranslationHandler },
            useDefaultLang: false,
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClient, ConfigService, LocaleService]
            }
        })

    ],
    providers: [
        {
            provide: ConfigServiceStorageKeyToken,
            useValue: 'profileAngularConfig'
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService) => () => {
                return config.load()
                    .then((result: any) => {
                        const baseUrl = config.getValue(CONFIG_CONSTANTS.apiUrlKey);
                        const version = config.getValue(CONFIG_CONSTANTS.defaultVersionKey);
                        const cdnList = config.getValue(CONFIG_CONSTANTS.cdnListKey);
                        const cdnPath = config.getValue(CONFIG_CONSTANTS.cdnPathKey);
                        // Set __webpack_public_path__ so the chunks js bundles
                        // for lazy loading has the correct CDN domain.
                        __webpack_public_path__ =
                            (cdnList && cdnList.length > 0 ? cdnList[0] : '') + cdnPath;
                        // ask for token early when the app initializes.
                        return result;
                    })
                    .catch((err) => {
                        return err;
                    });
            },
            deps: [ConfigService],
            multi: true
        },
        {
            provide: 'PAGE_KEY_ADDITIONAL_CONFIG',
            useValue: {
                siteId: 'wdw',
                locale: 'en'
            }
        },
        {
            provide: 'PAGE_KEY_URL_ENDPOINT',
            useValue: '/plan/bands-cards/page-key/',
        },
        {
            provide: 'PAGE_KEY_TEMPLATE',
            useValue: [
                'siteId', 'url'
            ]
        },
        {
            provide: 'LOGASAURUS_URL',
            useValue: '/plan/bands-cards/log/error'
        },
        {
            provide: LoggerServiceUrlToken,
            useValue: '/plan/bands-cards/log/'
        },
        {
            provide: LoggerServiceMaxLengthToken,
            useValue: 10000
        },
        {
            provide: 'CORRELATION_CONFIG',
            useValue: {
                wdprConversationCookieName: 'Conversation_UUID',
                wdprConversationHeader: 'X-Conversation-Id',
                wdprCorrelationHeader: 'X-Correlation-Id',
                wdprEndpointFilter: '/error/?'
            }
        },
        {
            provide: ONEID_CONFIG,
            useFactory: (
                config: ConfigService,
                languageCode: LanguageCodeService,
                localeService: LocaleService,
                windowRef: WindowRef) => {


                const oneId = config.getValue('oneId');
                const siteId = config.getValue('siteId');
                let locale = localeService.getLocale();
                locale = locale || LOCALE_EN_US;

                /*
                const lbConfig = config.getValue('wdprLightbox');
                lbConfig.debug = config.getValue('ENVIRONMENT_CONSTANT').NG_DEBUG_MODE_ENABLED;
                lbConfig.langPref = languageCode.getLanguageCode(locale);
                lbConfig.responderPage = windowRef.nativeWindow.location.origin + lbConfig.responderPage;

                return lbConfig;
                */
                return {
                    initialize: 'true',
                    clientId: oneId.clientId,
                    responderPage: windowRef.nativeWindow.location.origin + oneId.responder,
                    langPref: languageCode.getLanguageCode(locale),
                    debug: config.getValue('ENVIRONMENT_CONSTANT').NG_DEBUG_MODE_ENABLED,
                };
            },
            deps: [ConfigService, LanguageCodeService, LocaleService, WindowRef]
        },
        {
            provide: OneIdService,
            useClass: ProfileOneIdService // Use our class override in order to bind to Authenticator events
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor, // Automatically adds auth header to http calls
            deps: [OneIdService, CastProxyService, WindowRef],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SwidInjectorInterceptor,  // Automatically adds SWID to urls in http calls
            deps: [OneIdService, CastProxyService, WindowRef],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SwidHeaderInjectorInterceptor,
            deps: [OneIdService, CastProxyService, WindowRef],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RaInjectOneidAuthTokenInterceptor,
            multi: true,
        },
        APP_PROVIDERS
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/profile-api/content/', '/profile-mb-spa/');
    // Use when running en-us.json locally
    // return new TranslateHttpLoader(http, '/static/');
}
