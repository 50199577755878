import { getListeners } from './event-subscriptions';

/**
 * Emit an event and optional
 * data payload to all attached
 * listeners.
 */
export function emitEvent(name: string, data?) {
  const listeners = getListeners();
  if (!Array.isArray(listeners[name])) {
    return;
  }

  listeners[name].forEach(handler => {
    try {
      handler(data);
    } catch (e) {}
  });
}
