import {
    IMagicBandData,
    IReservation
} from '@profile/shared/interfaces/magic-bands.interface';
import { IMagicBandOrderClass } from '@profile/shared/classes/magicband-order.class.interface';
import { MagicBandOrder } from '@profile/shared/classes/magicband-order.class';
import { ConfigService, LocaleService } from '@wdpr-profile/core';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';
import { ShippingLabel } from '@profile/shared/classes/magicband-shipping-label.class';
import { IShippingLabel } from '@profile/shared/classes/magicband-shipping-label.class.interface';

import * as moment from 'moment';
import { SITE, shipGoLiveDates } from '@profile/shared/constants/app.constants';
import { LoggerService } from '@wdpr/ra-angular-logger';


export class MagicBandOrders {
    private rawMagicBandData: IMagicBandData;
    private orderData: Array<IMagicBandOrderClass> = [];
    siteId: string;
    todaysdate;
    mbEnablePurchaseWindowRestriction = false;
    shipGoLiveDates;

    constructor(
        private magicbandData: IMagicBandData,
        private configService: ConfigService,
        private cdnService: CdnService,
        private localeService: LocaleService,
        private gattingOverride: boolean,
        private logger: LoggerService
    ) {
        this.siteId = this.configService.getValue('siteId');
        this.rawMagicBandData = this.magicbandData;
        this.sortOrdersByResortArrivalDate();
        this.todaysdate = moment.utc().startOf('day');
        let shippingLabel: IShippingLabel;
        this.mbEnablePurchaseWindowRestriction = this.configService.getValue('mbEnablePurchaseWindowRestriction');

        this.shipGoLiveDates = shipGoLiveDates;

        for (const order in this.rawMagicBandData.orders) {
            if (this.rawMagicBandData.orders.hasOwnProperty(order)) {
                shippingLabel = null;
                if (this.rawMagicBandData.orders[order]) {
                    let showOrder = true; // Defaults to true for WDW
                    const thisOrder = this.rawMagicBandData.orders[order];
                    const id = thisOrder.acquisitionId;
                    if (this.rawMagicBandData.affiliations) {
                        thisOrder.acquisitionLabel = this.rawMagicBandData.affiliations[id] ?
                            this.rawMagicBandData.affiliations[id].affiliationType.toLowerCase()
                            : 'resort-order';
                    }

                    if (this.siteId === SITE.DCL && !this.gattingOverride) {
                        showOrder = this.showMagicBandOrder(thisOrder);
                    }

                    if (showOrder && thisOrder.state === MagicBandOrder.STATUS_SHIPPED && thisOrder.shipment) {
                        shippingLabel = new ShippingLabel(thisOrder.shipment);
                        showOrder = shippingLabel.showOrder;
                    }

                    if (showOrder) {
                        this.orderData.push(
                            new MagicBandOrder(
                                thisOrder,
                                shippingLabel,
                                this.rawMagicBandData.facilities,
                                this.rawMagicBandData.guests, configService,
                                this.cdnService,
                                this.localeService,
                                this.logger
                            )
                        );
                    }
                }
            }
        }
    }

    private sortOrdersByResortArrivalDate() {
        const key = 'arrivalDate';
        for (const order in this.rawMagicBandData.orders) {
            if (this.rawMagicBandData.orders.hasOwnProperty(order)) {
                let thisOrder = this.rawMagicBandData.orders[order];
                let reservationList = thisOrder.reservations || thisOrder.resortReservations || [];
                reservationList = this.sortReservationsByArrivalDate(reservationList);
                if (thisOrder.reservations) {
                    thisOrder.reservations = reservationList;
                } else if (thisOrder.resortReservations) {
                    thisOrder.resortReservations = reservationList;
                }
            }
        };

        this.rawMagicBandData.orders.sort(
            (a, b) => {
                try {
                    const aRes = a.reservations || a.resortReservations || [];
                    const bRes = b.reservations || b.resortReservations || [];
                    return moment.utc(aRes[0][key]).isBefore(bRes[0][key]) ? -1 : moment.utc(aRes[0][key]).isAfter(bRes[0][key]) ? 1 : 0;
                } catch (e) {
                    return 0;
                }
            }
        );
    }

    showMagicBandOrder(thisOrder): boolean {
        const shipCode = thisOrder.reservation.shipCode;
        const shipDepartureDate = moment.utc(thisOrder.resortReservations[0].arrivalDate);
        const shipGoLiveData = shipGoLiveDates[shipCode] || null;

        if (shipGoLiveData) {
            const shipOnSaleDate = moment.utc(shipGoLiveData.onsale);
            const shipSailingDate = moment.utc(shipGoLiveData.sailing);
            return this.todaysdate.isSameOrAfter(shipOnSaleDate) && shipDepartureDate.isSameOrAfter(shipSailingDate);
        }
        return false;
    }

    private sortReservationsByArrivalDate(reservationList): Array<IReservation> {
        const key = 'arrivalDate';
        reservationList.sort(
            (a, b) => moment.utc(a[key]).isBefore(b[key]) ? -1 : moment.utc(a[key]).isAfter(b[key]) ? 1 : 0
        );
        return reservationList;
    }

    get count() {
        return this.orderData ?
            this.orderData.length :
            0;
    }

    get orders() {
        return this.orderData;
    }
}
