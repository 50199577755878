import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CastProxyService } from '@wdpr-profile/core';

@Injectable()
export class CastProxyGuardService implements CanActivate {
    constructor(
        private castProxyService: CastProxyService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        // checks if there is a cast proxy session.
        return this.castProxyService.isCastProxy().then(isCastProxy => {
            if (isCastProxy !== false) {
                return true;
            } else {
                return false;
            }
        }).catch(() => {
            return false;
        });
    }
}
