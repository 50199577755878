import { IconText } from '@wdpr-profile/core/lib/components/molecules/icon-text/icon-text.interface';
import { IShipGoLiveDates } from '@profile/shared/interfaces/magic-bands.interface';

export const LOGIN_URL = '/login';

export const CONFIG_CONSTANTS = {
    siteIdKey: 'siteId',
    apiUrlKey: 'webApiUrl',
    envKey: 'environment',
    defaultVersionKey: 'webApiDefaultVersion',
    prodEnvValue: 'production',
    cdnListKey: 'cdnList',
    cdnPathKey: 'cdnPath',
    mobileBreakpoint: 960
};

export const LOCALE_EN_US = 'en-US';

export const DATE_FORMAT = 'MMMM D, YYYY';

export const API_CONSTANTS = {
    authEndpoint: 'authz/public',
    authStatusEndpoint: 'authentication/status/',
    friendsByPlans: '/profile-api/gam/fnf/friends-by-plans',
    listSvcEndpoint: 'list-service/details-slugs',
    mirsLookup: '/profile-api/mirs/keyring/v2/lookup-claimable-cast-tickets',
    mirsAssign: '/profile-api/mirs/keyring/v2/assign-claimable-cast-tickets',
    personalizedContentEndpoint: 'recommendation-service/personalized-content',
    webapiGuestPath: '/profile-api/guest/',
    guestTandCAcceptanceStatus: (tAndCName: string) => `{{swid}}/termsAndConditions/${tAndCName}/`,
    guestProfile: (xid: string) => `/profile-api/gam/assembly/guest/id;xid=${xid}/profile/`,
    getGuidProfile: (guid: string) => `/profile-api/gam/assembly/guest/id;guid=${guid}/profile/`,
    invitations: (type: 'swid' | 'xid', id: string) =>
        `/profile-api/gam/assembly/guest/id;${type}=${id}/sent-invitations`,
    removeConnection: (xid: string, connectionXid: string) => `/profile-api/gam/assembly/guest/${xid}/friend/${connectionXid}`,
    optIn: '/profile-api/gam/assembly/guest/id;swid={{swid}}/indicator-set',
    guestIndicators: '/profile-api/gam/assembly/guest/id;swid={{swid}}/indicator-set',
    sentInvitations: () =>
        '/profile-api/gam/assembly/sent-invitations?guest-id-type=swid&guest-id-value={{swid}}&status=Pending&item-limit=30',
    managedGuest: (guid: string) => `/profile-api/gam/assembly/managed-guest/id;guid=${guid}`,
    dscribeFileURLValue: (locale: string) => `/profile-api/content/${locale}/profile-spa?condensed=true`,
    buAuthStatus: (childSwid: string) => `/profile-api/child-auth-svc/authorization-status?child-swid=${childSwid}&includeProfile=true`,
    flResVerificationStart: (swid: string) => `/profile-api/residency/verification/?swid=${swid}`,
};

export const GUEST_INDICATORS = {
    SHARE_FNF_LIST: 'SHARE_FNF_LIST',
    BLOCK_ALL: 'BLOCK_ALL',
    BLOCK_NONE: 'BLOCK_NONE'
};

export const OPT_IN = {
    indicator: 'LOOKUP_PREFERENCE',
    optOut: 'OPT_OUT',
    optIn: 'OPT_IN'
};

export enum SITE {
    WDW = 'wdw',
    DLR = 'dlr',
    DCL = 'dcl'
}

export enum PAGE_TITLE_DEFAULT {
    WDW = 'MagicBands & Cards',
    DCL = 'DisneyBand+'
}

export const GUEST_AGE_LIMITS = {
    responsible: 21,
    adult: 18,
    inviteToConnect: 13,
    infant: 2
};

export const VALIDATION_REGEX = {
    phoneEmail: /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}|\+\d{1,3}\d{6,14}|(\d{2})(\d{4,14}))$/,
    email: /^[\w\+\-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    name: '^[a-zA-Z\\s\']+$',
    middleName: '^[a-zA-Z\\s\']+$'
};

export const CHILD_ACCOUNT_ENABLED = 'childAccountEnabled';

export const getVerifiedPhoneText = (verifiedIconData: IconText) =>
    `<span class='text-verified'> ${verifiedIconData.title}<span class='di di-${verifiedIconData.icon}'></span></span>`;

export const COOKIE_FL_RESIDENCY_MOBILE = 'access_token_flres';


/* Magic Band Constants */

export const localLocaleUS = [
    'en-us',
    'en_us',
    'en-US',
    'en_US',
    'es-us',
    'es_us',
    'es-US',
    'es_US'
];

export const MAGICBAND_THRESHOLDS = {
    'countdown': 5
};

export const curlyBracketRegex = /\{(\w*)\}/i;

export const SUPPORTED_LANGUAGES = [
    'en-CA',
    'en-GB',
    'en-eu',
    'es-ar',
    'es-cl',
    'es-co',
    'es-mx',
    'es-pe',
    'es-us',
    'fr-ca',
    'pt-br'
];

export const ANALYTICS_PREFIX = 'mdxprofile';
const explorerServicePath = 'explorer-service/';

export const MAGIC_BAND_API_CONSTANTS = {
    authEndpoint: 'authz/public',
    authStatusEndpoint: 'authentication/status/',
    claimMBPath: (env: string) => `https://${env}disneyworld.disney.go.com/link/magic-bands/`,
    detailsEndpoint: 'explorer-service/details-entity-simple',
    detailsScheduleEndpoint: explorerServicePath + 'details-entity-schedule',
    guestProfile: (swid: string) => `/profile-api/gam/assembly/guest/id;xid=${swid}/profile/`,
    listSvcEndpoint: 'list-service/details-slugs',
    mediaServiceEndpoint: 'media-service/media-engine-bundles',
    profileApiMBPath: '/profile-api/v1/mbrest/app',
    resourceBundleEndpoint: 'language-bundles',
    jwtCookieName: 'pep_jwt_token',
    updateMBOrderPath: (requestID: string) => `/xband-requests/${requestID}`,
    updateMBStatus: (bandID: string) => `/xband/${bandID}`,
    updateMBOptions: (bandID: string) => `/xband-options/${bandID}`,
    dscribeFileURLValue: (locale: string) => `/profile-api/content/${locale}/profile-mb-spa?condensed=true`
};

export const LINK_CONSTANTS = {
    shopMagicBands: (siteId: string) => 'https://www.shopdisney.com/parks/shop-by-category/accessories/magicband/?cmp=MARKETING-' +
        siteId + '_OTHER_Site_Link_072722_MagicBandPlus'
};

export const CARD_TYPES = {
    'CH0000': 'Passholder-Orange.png',          // White Card
    'CH0200': 'Generic-Green.png',              // Green Ticket Media
    'CH0300': 'Kttw-Blue.png',                  // Blue Key to the World
    'CH0700': 'Passholder-Orange.png',          // Annual Pass w/Prkng - Orange Card
    'CH0800': 'Passholder-Premier.png',         // Premier Passport
    'CH1100': 'Passholder-Black.png',           // Annual Pass w/o Parking - Black Card
    'CH1300': 'Mep-Cast-Primary.png',           // Main Entrance Pass
    'CH1400': 'Family-Holiday-Celebration.png', // Family Holiday Celebration
    'CH5700': 'Passholder-Premier-Club-33.png', // Club33 - DLR AP Only
    'CH5800': 'Golden-Oak.png',                 // Golden Oak Resident
    'CH5900': 'Mep-Vip-Primary.png',            // Main Entrance Pass
    'CH6000': 'Mep-Vip-Guest-Pass.png',         // VIP MEP Guest Pass
    'CH6100': 'Mep-Cast-Guest-Pass.png',        // Guest Pass
    'CH6200': 'Kttw-Gold.png',                  // Gold Key to the World - Club Level
    'CH6300': 'Passholder-Premier-Club-33.png', // Club 33 Premier Passport
    'CR61559': 'Mep-Cast-Primary.png'           // Main Entrance Pass
};

export const CARD_IMAGE_PATH = '/assets/images/magicbands/cards/';
export const BAND_IMAGE_PATH = '/assets/images/magicbands/bands/';
export const AVATAR_DEFAULT_IMAGE = '/assets/images/avatars/avatarretina.png';

export const MEP_PASSES = [
    'CH1300',
    'CH5900',
    'CR61559'
];

export const MEP_GUEST_PASSES = [
    'CH6000',
    'CH6100'
];

export const BAND_TYPE_REGEX = /^(S|B|H)(\d|)(I|\||)([A-F\d]+)$/i;
export const CARD_TYPE_REGEX = /^C[HR][\d\w]{4,6}$/g;
export const HOST_REGEX = /https?:\/\/(local\.|latest\.|stage\.)disney(?:world|cruise|land)\.disney\.go\.com(?:.*)/;
export const KTTW_PLUS_BAND_REGEX = /^(H\d|CH0300)/g;
export const PLUS_BAND_REGEX = /^H\d(?:\||I)[\d\w]+$/g;
export const SLAPBAND_TYPE_REGEX = /^(S|B)+([A-Fa-f3]+)$/g;

export const secretkey = 'NekXA6aLV5b1';

export const MEDIA_TYPE = {
    CARD: 'card',
    BAND: 'band',
    SLAPBAND: 'slapband'
};

export const MEDIA_OPTIONS = {
    format: {
        format: '2', // PNG
        quality: '90'
    },
    thumb: { width: 33, height: 60 },
    vignettes: { width: 1024, height: 97 },
    desktop: {
        small: { width: 60, height: 100 },
        medium: { width: 180, height: 328 },
        large: { width: 350, height: 640 }
    },
    mobile: {
        small: { width: 60, height: 100 },
        medium: { width: 125, height: 228 },
        large: { width: 175, height: 320 }
    }
};

export const VIGNETTE_DEFAULTS = {
    ap: { 'single': { 'href': '/assets/images/magicbands/vignettes/ap.png' } },
    dcl: {
        'desktop': { 'href': '/assets/images/magicbands/vignettes/MB_DefaultBanner_Desktop_2x.png' },
        'mobile': { 'href': '/assets/images/magicbands/vignettes/MB_DefaultBanner_Mobile_2x.png' }
    },
    wdw: { 'single': { 'href': '/assets/images/magicbands/vignettes/WDW_MB_DefaultBanner_Desktop_2x.png' } }
};

export const categoryTypes = {
    RESORT_CATEGORY: 'resort-order',
    AP_CATEGORY: 'ap-order',
    MEP_CATEGORY: 'mep-order',
    DCL_CATEGORY: 'dcl-order'
};

export const orderACQTypes = [
    'travel-plan-id',     // Reservation
    'dcl-travel-plan-id', // DCL Reservation
    'ats-tcod',           // AP
    'rex-acquisition-id', // MEP
];

export const orderTypes = [
    categoryTypes.RESORT_CATEGORY,
    categoryTypes.AP_CATEGORY,
    categoryTypes.MEP_CATEGORY,
    categoryTypes.DCL_CATEGORY
];

export const validShippingDestinations = [
    'US',
    'USA',
    'VI',
    'PR'
];

export const headerBackgrounds = {
    'dcl': '/assets/images/magicbands/headers/MB_HeroImage_3x.png',
    'wdw': {
        'desktop': '/assets/images/magicbands/headers/wdw-mbc-header-mbplus-desktop.jpg',
        'mobile': '/assets/images/magicbands/headers/wdw-mbc-header-mbplus-mobile.png'
    }
};

export const shipNameImageURLTemplate = {
    'desktop': '/assets/images/magicbands/vignettes/dcl_ship_name_{shipCode}_desktop_2x.png',
    'mobile': '/assets/images/magicbands/vignettes/dcl_ship_name_{shipCode}_mobile_2x.png'
};

export const relevantOrderTypes = {
    shipNowOrders: true,
    shipmentDeadlineOrders: false
};

export const babCustomizationPath = {
    'dcl': '/disney-band-customization/',
    'wdw': '/magic-band-customization/'
};

export const sitePaths = {
    'dcl': 'my-disney-cruise/bands-more',
    'wdw': 'plan/bands-cards'
};

export const MEDIA_ENGINE_CONSTANTS = {
    selectors: {
        titleBarClass: 'me-title-bar',
        titleClass: 'me-title',
        ctaBtnClass: 'me-cta-btn',
        fsBtnClass: 'me-fs-btn',
        headerMeFsClass: 'me-fullscreen',
        headerClass: 'syndicated-header',
    },
    configOverrides: {
        video: 'configOverrides.config.video',
        slides: 'configOverrides.config.slides'
    },
    defaultTheme: 'snowball-hover-thumbs',
    desktop: 'desktop',
    mobileWeb: 'mobileWeb',
};

export const shipGoLiveDates: IShipGoLiveDates = {
    WW: { sailing: '2023-06-12T00:00:00Z', onsale: '2023-05-11T00:00:00Z' },
    DF: { sailing: '2023-07-29T00:00:00Z', onsale: '2023-07-06T00:00:00Z' },
    DM: { sailing: '2023-10-22T00:00:00Z', onsale: '2023-09-21T00:00:00Z' },
    DD: { sailing: '2023-11-20T00:00:00Z', onsale: '2023-10-17T00:00:00Z' },
    DW: { sailing: '2024-03-19T00:00:00Z', onsale: '2024-02-03T00:00:00Z' },
    WT: { sailing: '2024-10-29T00:00:00Z', onsale: '2024-09-17T00:00:00Z' }
};

export const BAND_STATES = {
    'VOID': 'VOID',
};
