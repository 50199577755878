export interface IName {
  title?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
}

export interface IGuestIdentifiers {
  xid: string;
  swid?: string;
  guid?: string;
}

export class GuestProfile {
  ageBand?: string;
  avatarId: string;
  dateOfBirth?: string;
  email?: string;
  guestIdentifiers?: IGuestIdentifiers;
  name: IName;
  xid: string;

  get fullName(): string {
    return [
      this.name.title,
      this.name.firstName,
      this.name.middleName,
      this.name.lastName,
      this.name.suffix
    ].join(' ').trim();
  }

  get affiliationName(): string {
    return [
      this.name.firstName,
      this.name.middleName.substring(0, 1),
      this.name.lastName,
      this.name.suffix
    ].join(' ').trim();
  }
}
