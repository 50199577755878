import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    globalLoading = new BehaviorSubject<boolean>(true);
    routerOutletLoading = new BehaviorSubject<boolean>(false);
    isInitialNavigation = true;
    navigationCounter = 0;

    private destroy$ = new Subject<void>();

    constructor(
        private router: Router
    ) {
        this.setupNavigationListener();
    }

    setupNavigationListener() {
        this.router.events.pipe(
            takeUntil(this.destroy$),
            filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
        ).subscribe(event => {
            if (event instanceof NavigationStart) {
                this.setLoadingState(true);
                this.navigationCounter++;
            } else if (event instanceof NavigationEnd) {
                if (this.navigationCounter >= 1) {
                    this.isInitialNavigation = false;
                }
                this.hideLoaders();
            }
        });
    }

    setLoadingState(isLoading: boolean) {
        if (this.isInitialNavigation) {
            this.globalLoading.next(isLoading);
        } else {
            this.routerOutletLoading.next(isLoading);
        }
    }

    hideLoaders() {
        this.globalLoading.next(false);
        this.routerOutletLoading.next(false);
    }

    getGlobalLoading() {
        return this.globalLoading.asObservable();
    }

    getRouterOutletLoading() {
        return this.routerOutletLoading.asObservable();
    }
}
