import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { ProfileAuthenticatorService } from '@profile/shared/services/profile-authenticator-service/profile-authenticator.service';
import { OneIdService as CoreOneIdService, ONEID_CONFIG } from '@wdpr-profile/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { of } from 'rxjs';
import get from 'lodash-es/get';

/**
 * Instantiates OneID Lightbox client
 */
@Injectable({
  providedIn: 'root'
})
export class OneIdService extends CoreOneIdService {

  constructor(
    private authenticator: ProfileAuthenticatorService,
    private logger: LoggerService,
    @Inject(ONEID_CONFIG) private oneIdConfig: any,
  ) {
    super(oneIdConfig);

    // bind to session events - this is why we need to extend the core class
    this.authenticator.bindEvents(this.did);
  }

  guestIsAdult(): Promise<any> {
    return super.getGuest()
      .then((guest) => {
        let guestIsAdult: boolean;
        const ageBand = get(guest, 'profile.ageBand');
        if (ageBand) {
          return guestIsAdult = ageBand === 'ADULT';
        } else {
          throw new Error('Unable to determine age!');
        }
      });
  }
}
