import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Meta, Title } from '@angular/platform-browser';
import {
    ConfigService,
    ErrorService,
    ErrorState,
    LocaleService,
    Overlay,
    OverlayService,
    ProfileAnalyticsService
} from '@wdpr-profile/core';
import { TranslateService } from '@ngx-translate/core';
import { RaInjectOneidAuthTokenService } from '@wdpr/ra-angular-oneid-auth-token-injector';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { LoadingService } from './shared/services/loading-service/loading-service';
import { ANALYTICS } from '@profile/shared/constants/analytics.constants';

@Component({
    selector: 'profile-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public dataError: ErrorState;
    public dataLoaded = true;

    // Syndicated header and footer settings
    public syndicatedLayoutConfigs: Object;
    public siteId: string;
    public environment: string;
    public locale: string;
    public showHeaderFooter = false;
    public showLegalFooter = false;
    public showError = false;

    // Overlay
    public overlay: Overlay;

    // Loader Spinner
    public globalLoading: boolean;

    private subscriptions: Subscription[] = [];
    private destroy$ = new Subject<void>();

    constructor(
        private configService: ConfigService,
        private localeService: LocaleService,
        private router: Router,
        private titleService: Title,
        private raInjectOneidAuthTokenService: RaInjectOneidAuthTokenService,
        private overlaySvc: OverlayService,
        private errorSvc: ErrorService,
        private loadingSvc: LoadingService,
        private translateService: TranslateService,
        private profileAnalyticsService: ProfileAnalyticsService, // Do not remove
        private meta: Meta,
        private logger: LoggerService
    ) { }

    ngOnInit() {
        this.watchLoadingStates();
        this.watchOverlay();

        this.raInjectOneidAuthTokenService.init({
            ENABLED: true,
            LOGGEDINFLOW: true
        });
        this.syndicatedLayoutConfigs = this.configService.getValue('syndicatedLayout');
        this.siteId = this.configService.getValue('siteId');
        this.environment = this.configService.getValue('environment');
        this.locale = this.localeService.getLocale();
        // Show header and footer if configured in the current route
        this.subscriptions.push(
            this.router.events.subscribe(event => {
                if (event instanceof RoutesRecognized) {
                    const route = event.state.root.firstChild;
                    this.showHeaderFooter = !!route.data.showHeaderFooter;
                    this.showLegalFooter = !!route.data.showLegalFooter;
                }
            })
        );

        // Listen for errors
        // this.watchError();

        // Set translation locale
        this.translateService.use(this.locale);

        this.translateService.get('meta.description')
            .subscribe((translation: object) => {
                this.meta.addTag({ name: 'description', content: translation.toString() });
            });

        this.translateService.get('meta.description')
            .subscribe((translation: object) => {
                this.meta.addTag({ name: 'keywords', content: translation.toString() });
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            // Ignore the if just for coverage, there's no need to test unsubscribe
            /* istanbul ignore next */
            if (sub && sub.unsubscribe) {
                sub.unsubscribe();
            }
        });
    }

    watchOverlay() {
        this.overlaySvc.getOverlay().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: overlay => this.overlay = overlay
        });
    }

    watchError() {
        this.errorSvc.getErrorState()
            .pipe(filter(error => error !== null))
            .subscribe(error => {
                // If we get an undefined value, that means  Watcher failed
                // so we set the error to null here to display the default
                // error message.
                this.dataError = error ?? null;
                this.showError = true;
            });
    }

    watchLoadingStates() {
        this.loadingSvc.getGlobalLoading().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (globalLoadingStatus) => {
                this.globalLoading = globalLoadingStatus;
            }
        });
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

}
