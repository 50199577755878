import { Injectable } from '@angular/core';
import { WindowRef } from '@wdpr-profile/core';
import get from 'lodash-es/get';

@Injectable({
    providedIn: 'root',
})
export class A11yService {
    constructor(
        private windowRef: WindowRef
    ) {}

    reducedMotionEnabled() {
        // Check if the guest has `prefers-reduced-motion` enabled
        const mediaQuery = this.windowRef.nativeWindow.matchMedia('(prefers-reduced-motion: reduce)');

        return get(mediaQuery, 'matches', false);
    }
}
