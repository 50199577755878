import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { catchError } from 'rxjs/operators';
import { includes } from 'lodash-es';
import { EMPTY } from 'rxjs';
import { LocaleService, ConfigService, NavigationService, ErrorService } from '@wdpr-profile/core';

import { LOCALE_EN_US, SITE} from '@profile/shared/constants/app.constants';
import { ContentService } from '@profile/shared/services/retrieve-content-service/content.service';

export const ContentResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
    // Services
    const configService = inject(ConfigService);
    const localeService = inject(LocaleService);
    const logger = inject(LoggerService);
    const translate = inject(TranslateService);
    const navigationSvc = inject(NavigationService);
    const contentService = inject(ContentService<any>);
    const errorSvc = inject(ErrorService);
    const WDW_EN_US_JSON = require('@assets/i18n/wdw/en-us.json');
    const DCL_EN_US_JSON = require('@assets/i18n/dcl/en-us.json');
    let siteId: string = configService.getValue('siteId');
    let isSiteWdw = false;
    let isSiteDcl = false;
    const LOG_MESSAGE_PREFIX = 'MB_CONTENT_RESOLVER -> ';
    if (siteId) {
        if (siteId === SITE.WDW) {
            isSiteWdw = true;
            logger.log(LOG_MESSAGE_PREFIX + 'Detected valid WDW siteId with the value \"' + siteId + '\"');
        } else if (siteId === SITE.DCL) {
            isSiteDcl = true;
            logger.log(LOG_MESSAGE_PREFIX + 'Detected valid DCL siteId with the value \"' + siteId + '\"');
        } else {
            logger.error(LOG_MESSAGE_PREFIX + 'Invalid and unsupported siteId detected with the ' +
                'value \"' + siteId + '\"');
        }
    } else {
        logger.error(LOG_MESSAGE_PREFIX + 'Invalid and unsupported siteId detected as the siteId ' +
            'value is null and thus all content processing cannot proceed.');
    }

    // Reset error State
    errorSvc.setErrorState(null);

    // Get Locale to pull the translated content
    const supportedLocales = configService.getValue('supportedLocales');
    let locale = localeService.getLocale();
    locale = includes(supportedLocales, locale) ? locale : LOCALE_EN_US;
    translate.setDefaultLang(LOCALE_EN_US);

    // Use a promise to resolve the route and never reject
    // the error so we can stay on the same URI
    return new Promise((resolve, reject) => {
        translate.use(locale)
            .pipe(
                catchError((error: Error) => {
                    logger.error(`ContentResolver - FAILED. Reason: ${error.message}`);
                    errorSvc.handleError('unavailable');
                    resolve(null);

                    return EMPTY;
                })
            )
            .subscribe(content => {
                // Initialize the error messages for future use.
                /*
                let sideNavigationContent = content?.sideNavigation;

                if (!sideNavigationContent) {
                  sideNavigationContent = WDW_EN_US_JSON.sideNavigation;
                  content.sideNavigation = sideNavigationContent;
                }
                */
               let sideNavigationContent = null;
                if (isSiteWdw) {
                    sideNavigationContent = WDW_EN_US_JSON.sideNavigation;
                } else if (isSiteDcl) {
                    sideNavigationContent = DCL_EN_US_JSON.sideNavigation;
                }
                errorSvc.initializeErrorMessages(content?.error);
                contentService.setContent(content);
                if (sideNavigationContent) {
                    const SIDE_NAVIGATION_CONTENT_JSON_STRING = JSON.stringify(sideNavigationContent);
                    logger.log(LOG_MESSAGE_PREFIX + ' sideNavigationContent detected for siteId with the value \"' +
                        siteId + '\" Injecting the following sideNavigationContent -> \"' +
                        SIDE_NAVIGATION_CONTENT_JSON_STRING + '\"');
                    navigationSvc.initialize({
                        content: sideNavigationContent
                    });
                    logger.log(LOG_MESSAGE_PREFIX + 'navigationSvc successfully initialized with the ' +
                        'sideNavigationContent detected for siteId with the value \"' +
                        siteId + '\" Injecting the following sideNavigationContent -> \"' +
                        SIDE_NAVIGATION_CONTENT_JSON_STRING + '\"');
                    resolve(content);
                }
            });
    });
};
