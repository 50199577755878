import { Injector, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CastProxyGuardService } from '../cast-proxy-guard-service/cast-proxy-guard.service';
import { AuthenticationGuard } from '@wdpr-profile/core';

@Injectable({
    providedIn: 'root'
})
export class MasterAuthenticationGuard implements CanActivate {
    constructor(
        private router: Router,
        private injector: Injector
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // run CastProxyGuard and only if it fails run the AuthenthicationGuard
        // we don't let both run to stop the AuthenticationGuard from opening the login lightbox.
        const castProxyGuard: CanActivate = this.injector.get(CastProxyGuardService);
        const castProxyGuardResult = await castProxyGuard.canActivate(route, state);
        if (castProxyGuardResult !== false) {
            return true;
        } else {
            const authenticationGuard: CanActivate = this.injector.get(AuthenticationGuard);
            const authenticationGuardResult = await authenticationGuard.canActivate(route, state);
            if (authenticationGuardResult === true) {
                return true;
            } else {
                // authentication guard would handle false by opening login lightbox.
            }
        }
    }
}
