import { Injectable } from '@angular/core';
import { WindowRef } from '@wdpr-profile/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEVICE_TYPE } from './device-type.constant';
import { Viewport } from './viewport-detection.interface';

export const BREAKPOINTS = {
    desktopLarge: 1920,
    desktopMedium: 1440,
    desktop: 1024,
    tablet: 768,
};

/**
 * A service to determine the device type depending on the viewport.
 *
 * @class ViewportDetection
 */
@Injectable({
  providedIn: 'root',
})
export class ViewportDetection {

    private viewport: DEVICE_TYPE;
    private resizing: Subject<DEVICE_TYPE>;

    /**
     * @constructor
     */
    constructor(private winRef: WindowRef) {
        this.viewport = this.getDevice();
        this.resizing = new Subject();
        // Debounce the resizing event since its fired at a high rate.
        const eventSource = fromEvent(winRef.nativeWindow, 'resize').pipe(debounceTime(150));
        // Subscribe to update the viewport.
        eventSource.subscribe((event) => {
            const updatedViewport = this.getDevice();
            if (this.viewport !== updatedViewport) {
                this.viewport = updatedViewport;
                this.resizing.next(this.viewport);
            }
        });
    }

    /**
     * Determines the device type depending on the current viewport.
     *
     * @returns
     */
    public getDevice(): DEVICE_TYPE {
        const width = this.winRef.nativeWindow.innerWidth;
        let viewport = DEVICE_TYPE.mobile;

        if (width >= BREAKPOINTS.desktop) {
            viewport = DEVICE_TYPE.desktop;
        } else if (width >= BREAKPOINTS.tablet) {
            viewport = DEVICE_TYPE.tablet;
        }

        return viewport;
    }

    /**
     * Calculates whether current viewport belongs to a mobile device or not.
     *
     * @returns
     */
    isMobile(): boolean {
        return this.getDevice() === DEVICE_TYPE.mobile;
    }

    /**
     * Calculates whether current viewport belongs to a tablet device or not.
     *
     * @returns
     */
    isTablet(): boolean {
        return this.getDevice() === DEVICE_TYPE.tablet;
    }

    /**
     * Calculates whether current viewport belongs to a desktop device or not.
     *
     * @returns
     */
    isDesktop(): boolean {
        return this.getDevice() === DEVICE_TYPE.desktop;
    }

    /**
     * Returns an observable which can be subscribed to listen for device detection changes.
     *
     * @returns
     */
    onViewportResize(): Subject<DEVICE_TYPE> {
        return this.resizing;
    }

    /**
     * Determines the breakpoint name depending on the current viewport.
     *
     * @returns
     */
    getViewportBreakpoint(): string {
        const width = this.winRef.nativeWindow.innerWidth;
        let viewportSize: string;

        if (width >= BREAKPOINTS.desktopLarge) {
            viewportSize = 'desktop-large';
        } else if (width >= BREAKPOINTS.desktopMedium) {
            viewportSize = 'desktop-medium';
        } else if (width >= BREAKPOINTS.desktop) {
            viewportSize = 'desktop';
        } else if (width >= BREAKPOINTS.tablet) {
            viewportSize = 'tablet';
        } else {
            viewportSize = 'mobile';
        }

        return viewportSize;
    }

    getViewportSize(): Viewport {
        return {
            width: this.winRef.nativeWindow.innerWidth,
            height: this.winRef.nativeWindow.innerHeight
        };
    }
}
