import {
    IOrder,
    IReservation,
    IFacility,
    IGuestData,
    ICustomizationSelection
} from '@profile/shared/interfaces/magic-bands.interface';
import {
    MEDIA_OPTIONS,
    validShippingDestinations,
    relevantOrderTypes,
    LOCALE_EN_US,
    SITE,
    localLocaleUS
} from '@profile/shared/constants/app.constants';
import { formatImageURL, getDateRangeString } from '@profile/shared/utils/utils';
import { MagicBand } from '@profile/shared/classes/magicband-band.class';
import * as moment from 'moment';
import { get } from 'lodash-es';
import { ConfigService, LocaleService } from '@wdpr-profile/core';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';
import { IShippingLabel } from '@profile/shared/classes/magicband-shipping-label.class.interface';
import { LoggerService } from '@wdpr/ra-angular-logger';

export class MagicBandOrder {

    static STATUS_SHIPPED = 'COMPLETED'; // final and permanent state for an order
    static STATUS_PROCESSING = 'FULFILLMENT';
    static STATUS_PICKUP = 'FULFILL_ON_SITE';
    static STATUS_CUSTOMIZE = 'CUSTOMIZATION';
    static STATUS_NO_FULFILLMENT = 'NO_FULFILLMENT'; // do not show on UI (for some reason, this order will not ship)
    static STATUS_UNCLAIMED = 'UNCLAIMED';

    // more accurate and specific statuses defined and used by the UI only
    static STATUS_PRE_CUSTOMIZATION = 'PRE_CUSTOMIZATION';
    static STATUS_CUSTOMIZE_READY = 'CUSTOMIZATION_READY';
    static STATUS_NEVER_CUSTOMIZED = 'NEVER_CUSTOMIZED';
    static STATUS_FULFILLMENT_IMMINENT = 'FULFILLMENT_IMMINENT';
    static STATUS_FULFILLMENT_IMMINENT_READY = 'FULFILLMENT_IMMINENT_READY';
    static STATUS_SHIP_TO_RESORT = 'SHIP_TO_RESORT';
    static STATUS_SHIP_TO_HOME = 'SHIP_TO_HOME';

    static STATUS_FROZEN_IN_CUSTOMIZATION = 'FROZEN_IN_CUSTOMIZATION';
    static STATUS_FROZEN_IN_CUSTOMIZATION_READY = 'FROZEN_IN_CUSTOMIZATION_READY';
    static STATUS_FROZEN_IN_CUSTOMIZATION_BAD_ADDRESS = 'STATUS_FROZEN_IN_CUSTOMIZATION_BAD_ADDRESS';

    static STATUS_PROBLEM = 'PROBLEM';

    // Resort reservation status according to DREAMS
    static TRAVEL_STATUS_ACTIVE = 'ACTIVE';
    static TRAVEL_STATUS_CANCELLED = 'CANCELLED';

    static TYPE_STANDARD = 'STANDARD';
    static TYPE_NONE = 'NONE';

    // Hold fulfillment reasons
    static HOLD_SHIP_TO_RESORT = 'SHIP_TO_RESORT';
    static HOLD_GUEST_ENTERED_RESORT_ADDRESS = 'GUEST_PROVIDED_RESORT_ADDRESS';
    static HOLD_OTHER = 'Other';

    // Countdown Windows
    public COMPLETE_ORDER_LIMIT = 5;
    public PURCHASE_MB_LIMIT_FROM_DEPARTURE_BASE = 10;
    public PURCHASE_MB_LIMIT_FROM_DEPARTURE = 45;

    // Acquisition ID Types supported
    // (if not listed here, a generic label will display until
    //  a CMS override is put in place for new order types)
    public RESORT_ACQ_TYPE = 'travel-plan-id';
    public AP_ACQ_TYPE = 'ats-tcod';
    public MEP_ACQ_TYPE = 'rex-acquisition-id';
    public SN_ACQ_TYPE = 'sn-tcod';
    public DCL_ACQ_TYPE = 'dcl-travel-plan-id';

    // Categories supported
    public RESORT_CATEGORY = 'resort-order';
    public AP_CATEGORY = 'ap-order';
    public MEP_CATEGORY = 'mep-order';
    public SN_CATEGORY = 'ap-order';
    public DCL_CATEGORY = 'dcl-order';

    public earliestArrivalDate;

    default_vignettes = {
        ap: '/assets/images/magicbands/vignettes/ap.png',
        dcl: {
            'desktop': '/assets/images/magicbands/vignettes/MB_DefaultBanner_Desktop_2x.png',
            'mobile': '/assets/images/magicbands/vignettes/MB_DefaultBanner_Mobile_2x.png'
        },
        wdw: '/assets/images/magicbands/vignettes/WDW_MB_DefaultBanner_Desktop_2x.png'
    };

    order;
    reservationList: Array<IReservation>;
    bands = [];
    siteId: string;

    daysUntilDeadline;
    daysUntilPurchaseWindow;
    validShippingDestinations: string[];
    localLocaleUS: string[];

    constructor(
        private magicbandOrder: IOrder,
        private shippingLabelContainer: IShippingLabel,
        private facilityData: IFacility,
        private guestData: IGuestData,
        private configService: ConfigService,
        private cdnService: CdnService,
        private localeService: LocaleService,
        private logger: LoggerService
    ) {
        this.reservationList = this.magicbandOrder.reservations || this.magicbandOrder.resortReservations || [];
        this.getEarliestArrivalDate();
        this.convertOrderAcqToCategory();
        this.processBands();
        this.siteId = this.configService.getValue('siteId');
        this.validShippingDestinations = validShippingDestinations;
        this.localLocaleUS = localLocaleUS;
    }

    get getPrimaryGuestAddress() {
        let primaryGuestXid = '';
        let primaryGuest;
        let primaryGuestAddress = { address: {} };
        let renameMap = {
            'line1': 'address1',
            'line2': 'address2',
            'line3': 'address3',
            'line4': 'address4',
            'stateProvince': 'state'
        };
        this.magicbandOrder.customizationSelections.forEach(band => {
            if (band.primaryGuest) {
                primaryGuestXid = band.xbandOwnerXid;
            }
        });
        primaryGuest = this.guestData[primaryGuestXid];
        if (primaryGuest.address) {
            for (let key in primaryGuest.address) {
                if (key !== 'type' && primaryGuest.address[key]) {
                    if (renameMap[key]) {
                        primaryGuestAddress.address[renameMap[key]] = primaryGuest.address[key];
                    } else {
                        primaryGuestAddress.address[key] = primaryGuest.address[key];
                    }
                }
            }
        }
        return primaryGuestAddress;
    }

    private convertOrderAcqToCategory() {
        let acquisitionIdType;
        let foundIndex;
        const acqTypes = [
            this.RESORT_ACQ_TYPE,
            this.AP_ACQ_TYPE,
            this.MEP_ACQ_TYPE,
            this.SN_ACQ_TYPE,
            this.DCL_ACQ_TYPE
        ];
        const categories = [
            this.RESORT_CATEGORY,
            this.AP_CATEGORY,
            this.MEP_CATEGORY,
            this.SN_CATEGORY,
            this.DCL_CATEGORY
        ];

        acquisitionIdType = this.magicbandOrder.acquisitionIdType;
        foundIndex = acqTypes.indexOf(acquisitionIdType);
        if (foundIndex > -1) {
            this.magicbandOrder.category = categories[foundIndex];
        } else {
            this.magicbandOrder.category = acquisitionIdType;
        }
    }

    private getEarliestArrivalDate() {
        let arrivalDate;
        const todaysdate = moment.utc();
        for (const res in this.reservationList) {
            if (this.reservationList[res].accommodations) {
                for (const accom in this.reservationList[res].accommodations) {
                    if (this.reservationList[res].accommodations[accom].arrivalDateTime) {
                        arrivalDate = moment.utc(this.reservationList[res].accommodations[accom].arrivalDateTime);
                        if (
                            !this.earliestArrivalDate
                            || (
                                arrivalDate.diff(todaysdate, 'days') < 0
                                && arrivalDate.isBefore(this.earliestArrivalDate)
                            )
                        ) {
                            this.earliestArrivalDate = arrivalDate;
                        }
                    }
                }
            } else {
                const arrivalDateTime = moment.utc(get(this.reservationList, '[0].arrivalDate'));
                if (
                    !this.earliestArrivalDate
                    || (
                        arrivalDateTime.diff(todaysdate, 'days') < 0
                        && arrivalDateTime.isBefore(this.earliestArrivalDate)
                    )
                ) {
                    this.earliestArrivalDate = arrivalDateTime;
                }
            }
        }
    }

    private allowUnrestrictedShippingForOrder() {
        if (
            relevantOrderTypes.shipNowOrders === null
            && this.magicbandOrder.provideShipNowOption
        ) {
            // this is a Ship Now order, and it has no shipping restrictions activated
            return true;
        } else if (
            relevantOrderTypes.shipmentDeadlineOrders === null
            && this.magicbandOrder.provideShipNowOption
        ) {
            // this is a non Ship Now order (resort), and it has no shipping restrictions activated
            return true;
        }
        return false;
    }

    private isGuestInAValidUSGeoLocale(GUEST_GEO_LOCALE_FOR_REQUEST: string): boolean {
        const LOG_MESSAGE_PREFIX = 'MB_IS_GUEST_IN_A_VALID_US_GEO_LOCALE -> ';
        let isGuestInAValidUSGeoLocale = false;
        if (GUEST_GEO_LOCALE_FOR_REQUEST) {
            this.logger.log(LOG_MESSAGE_PREFIX + 'GUEST_GEO_LOCALE_FOR_REQUEST detected has a ' +
                'value of \"' + GUEST_GEO_LOCALE_FOR_REQUEST + '\".');
            if (this.localLocaleUS) {
                    if (this.localLocaleUS.indexOf(GUEST_GEO_LOCALE_FOR_REQUEST) > -1) {
                    isGuestInAValidUSGeoLocale = true;
                    this.logger.log(LOG_MESSAGE_PREFIX + 'Geo-locale detected and guest has valid ' +
                        'US geo-locale thus setting isGuestInAValidUSGeoLocale to \"' +
                        isGuestInAValidUSGeoLocale + '\"');
                }
            } else {
                this.logger.warn(LOG_MESSAGE_PREFIX + 'localLocaleUS NOT detected, thus processing ' +
                    'related to isGuestInAValidUSGeoLocale will not proceed.');
            }
        } else {
            this.logger.warn(LOG_MESSAGE_PREFIX + 'GUEST_GEO_LOCALE_FOR_REQUEST is null for this ' +
                'request thus no processing related to GUEST_GEO_LOCALE_FOR_REQUEST can be executed."');
        }

        return isGuestInAValidUSGeoLocale;
    }

    private getLoggedInGuestCountryCode(LOG_MESSAGE_PREFIX: string): string {
        const LOG_MESSAGE_COUNTRY_CODE_PREFIX = 'MB_LOGGED_IN_GUEST_COUNTRY_CODE -> ';
        let countryCode: string = null;
        const GUEST_ADDRESS_FOR_ODER_PROCESSING = this.getGuestAddressForOrderProcessing();
        if (GUEST_ADDRESS_FOR_ODER_PROCESSING) {
            if (GUEST_ADDRESS_FOR_ODER_PROCESSING.address) {
                countryCode = GUEST_ADDRESS_FOR_ODER_PROCESSING.address['country'];
                if (countryCode) {
                    countryCode = countryCode.trim().toUpperCase();
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_COUNTRY_CODE_PREFIX +
                        'Guest country detected with the value \"' +
                        countryCode + '\".');
                } else {
                    this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_COUNTRY_CODE_PREFIX +
                        'Guest guestAddress.address.country NOT detected ' +
                        'thus unable to determine country.');
                }
            } else {
                this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_COUNTRY_CODE_PREFIX +
                    'Guest guestAddress.address NOT detected thus unable to determine country.');
            }
        }
        return countryCode;
    }

    private isLoggedInGuestAbleToShipOrderToAddress(): boolean {
        const LOG_MESSAGE_PREFIX = 'MB_IS_LOGGED_IN_GUEST_ABLE_TO_SHIP_ORDER_TO_ADDRESS_PROCESSING -> Order acquisitionId \"' +
            this.magicbandOrder.acquisitionId + '\" -> ';
        const LOG_MESSAGE_ERROR_PREFIX = 'ERROR -> ';
        const LOG_MESSAGE_INFO_PREFIX = 'INFO -> ';
        const LOG_MESSAGE_WARNING_PREFIX = 'WARNING -> ';
        const LOG_MESSAGE_SUCCESS_PREFIX = 'SUCCESS -> ';
        const COUNTRY_CODE_US = 'US';
        let isLoggedInGuestAbleToShipOrderToAddress = false;
        const GUEST_GEO_LOCALE_FOR_REQUEST = this.getGuestGeoLocaleForRequest();
        const IS_GUEST_IN_A_VALID_US_GEO_LOCALE = this.isGuestInAValidUSGeoLocale(GUEST_GEO_LOCALE_FOR_REQUEST);
        const GUEST_COUNTRY_CODE = this.getLoggedInGuestCountryCode(LOG_MESSAGE_PREFIX);
        const VALID_SHIPPING_DESTINATIONS = this.validShippingDestinations;
        if (GUEST_COUNTRY_CODE) {
            const IS_GUEST_COUNTRY_CODE_US: boolean = GUEST_COUNTRY_CODE === COUNTRY_CODE_US;
            if (IS_GUEST_COUNTRY_CODE_US) {
                if (IS_GUEST_IN_A_VALID_US_GEO_LOCALE) {
                    isLoggedInGuestAbleToShipOrderToAddress = true;
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_SUCCESS_PREFIX +
                        'GUEST_COUNTRY_CODE detected with the value \"' + GUEST_COUNTRY_CODE + '\"' +
                        ' AND GUEST_GEO_LOCALE_FOR_REQUEST is a valid \"' + COUNTRY_CODE_US + '\" geo-locale with a value of \"' +
                        GUEST_GEO_LOCALE_FOR_REQUEST + '\" thus setting isLoggedInGuestAbleToShipOrderToAddress set to \"' +
                        isLoggedInGuestAbleToShipOrderToAddress + '\" for order acquisitionId \"' +
                        this.magicbandOrder.acquisitionId + '\".');
                }
            } else {
                this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_SUCCESS_PREFIX + 'IS_GUEST_COUNTRY_CODE_US has a ' +
                    'value of \"' + IS_GUEST_COUNTRY_CODE_US + '\" thus not processing ' +
                    'isLoggedInGuestAbleToShipOrderToAddress based on GUEST_COUNTRY_CODE at ' +
                    'this time in processing. ' +
                    'GUEST_COUNTRY_CODE detected with the value \"' + GUEST_COUNTRY_CODE + '\".  ' +
                    'isLoggedInGuestAbleToShipOrderToAddress has a value of \"' + isLoggedInGuestAbleToShipOrderToAddress +
                    '\" at this time of processing for order ' +
                    'acquisitionId \"' + this.magicbandOrder.acquisitionId + '\".');
            }
        } else {
            this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + 'GUEST_COUNTRY_CODE NOT detected thus unable to ' +
                'determine if the guest is in the \"' + COUNTRY_CODE_US + '\". ' +
                'isLoggedInGuestAbleToShipOrderToAddress currently has a value of \"' + isLoggedInGuestAbleToShipOrderToAddress +
                '\" at this time of processing for order acquisitionId \"' +
                this.magicbandOrder.acquisitionId + '\".');
        }
        if (!isLoggedInGuestAbleToShipOrderToAddress) {
            if (!GUEST_COUNTRY_CODE) {
                isLoggedInGuestAbleToShipOrderToAddress = true;
                this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + LOG_MESSAGE_SUCCESS_PREFIX + 'Guest country NOT ' +
                    'detected, thus defaulting isLoggedInGuestAbleToShipOrderToAddress to \"' +
                    isLoggedInGuestAbleToShipOrderToAddress + '\"');
            } else if (this.allowUnrestrictedShippingForOrder()) {
                isLoggedInGuestAbleToShipOrderToAddress = true;
                this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_SUCCESS_PREFIX + 'allowUnrestrictedShippingForOrder() returned true, ' +
                    'thus setting isLoggedInGuestAbleToShipOrderToAddress to \"' + isLoggedInGuestAbleToShipOrderToAddress + '\"');
            } else {
                if (GUEST_GEO_LOCALE_FOR_REQUEST) {
                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX +
                        'Geo-locale detected and guest with the following value \"' +
                        GUEST_GEO_LOCALE_FOR_REQUEST + '\"');
                    if (VALID_SHIPPING_DESTINATIONS) {
                        if (GUEST_COUNTRY_CODE) {
                            const IS_VALID_SHIPPING_DESTINATIONS_FOR_GUEST_COUNTRY_CODE: boolean =
                            VALID_SHIPPING_DESTINATIONS.indexOf(GUEST_COUNTRY_CODE) > -1;
                            if (IS_VALID_SHIPPING_DESTINATIONS_FOR_GUEST_COUNTRY_CODE) {
                                if (IS_GUEST_IN_A_VALID_US_GEO_LOCALE) {
                                    isLoggedInGuestAbleToShipOrderToAddress = true;
                                    this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_SUCCESS_PREFIX +
                                        'GUEST_COUNTRY_CODE determined with ' +
                                        'the following value -> ' + GUEST_COUNTRY_CODE + ' however the geo-locale detected has a valid ' +
                                        'US geo-locale with a value of \"' + GUEST_GEO_LOCALE_FOR_REQUEST +
                                        '\" and IS_GUEST_IN_A_VALID_US_GEO_LOCALE has a value of \"' + IS_GUEST_IN_A_VALID_US_GEO_LOCALE +
                                        '\" shipping destination and is in a US Locale thus setting ' +
                                        'isLoggedInGuestAbleToShipOrderToAddress to \"' +
                                        isLoggedInGuestAbleToShipOrderToAddress + '\"');
                                } else {
                                    this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX +
                                        'validShippingDestinations detected, ' +
                                        'however IS_GUEST_IN_A_VALID_US_GEO_LOCALE has a value of \"' +
                                        IS_GUEST_IN_A_VALID_US_GEO_LOCALE + '\", ' +
                                        'thus processing related to isLoggedInGuestAbleToShipOrderToAddress will not proceed. ' +
                                        'GUEST_COUNTRY_CODE determined with the following value -> ' + GUEST_COUNTRY_CODE +
                                        ' The current isLoggedInGuestAbleToShipOrderToAddress value is \"' +
                                        isLoggedInGuestAbleToShipOrderToAddress + '\"');
                                }
                            } else {
                                this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX +
                                    'GUEST_GEO_LOCALE_FOR_REQUEST detected, ' +
                                    'however IS_VALID_SHIPPING_DESTINATIONS_FOR_GUEST_COUNTRY_CODE has a value of \"' +
                                    IS_VALID_SHIPPING_DESTINATIONS_FOR_GUEST_COUNTRY_CODE + ', ' +
                                    'GUEST_COUNTRY_CODE determined with the following value -> ' + GUEST_COUNTRY_CODE +
                                    'thus processing related to validShippingDestinations will not proceed.');
                            }
                        } else {
                            this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + 'GUEST_GEO_LOCALE_FOR_REQUEST detected, ' +
                                'however GUEST_COUNTRY_CODE is not detected and has a value of null, thus processing ' +
                                'related to validShippingDestinations will not proceed.');
                        }
                    } else {
                        this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + 'GUEST_GEO_LOCALE_FOR_REQUEST detected, ' +
                            'however validShippingDestinations NOT detected, thus processing related to validShippingDestinations ' +
                            'will not proceed.');
                    }
                } else {
                    isLoggedInGuestAbleToShipOrderToAddress = true;
                    this.logger.warn(LOG_MESSAGE_PREFIX + LOG_MESSAGE_WARNING_PREFIX + 'GUEST_GEO_LOCALE_FOR_REQUEST NOT detected, ' +
                        'thus defaulting isLoggedInGuestAbleToShipOrderToAddress to \"' + isLoggedInGuestAbleToShipOrderToAddress +
                        '\" so that country code gating can be processed later in the guest E-Commerce web process flow.');
                }
            }
        }
        this.logger.log(LOG_MESSAGE_PREFIX + LOG_MESSAGE_INFO_PREFIX + 'isLoggedInGuestAbleToShipOrderToAddress set to \"'
            + isLoggedInGuestAbleToShipOrderToAddress + '\" for order acquisitionId \"' + this.magicbandOrder.acquisitionId + '\".');
        return isLoggedInGuestAbleToShipOrderToAddress;
    }

    private determineCustomizeConfirmedStatus() {
        const isReady = this.magicbandOrder.isInReadySatus;
        if (this.magicbandOrder.provideShipNowOption) {
            return this.getShipNowStatus(isReady);
        } else if (this.magicbandOrder.isBeforeCustomizationDeadline) {
            if (!isReady) {
                return MagicBandOrder.STATUS_CUSTOMIZE;
            } else {
                return MagicBandOrder.STATUS_CUSTOMIZE_READY;
            }
        } else {
            if (!isReady) {
                return MagicBandOrder.STATUS_FULFILLMENT_IMMINENT;
            } else {
                return MagicBandOrder.STATUS_FULFILLMENT_IMMINENT_READY;
            }
        }
    }

    private getShipNowStatus(isReady) {
        if (!isReady) {
            return MagicBandOrder.STATUS_FROZEN_IN_CUSTOMIZATION;
        } else {
            if (this.isLoggedInGuestAbleToShipOrderToAddress()) {
                return MagicBandOrder.STATUS_FROZEN_IN_CUSTOMIZATION_READY;
            }
            return MagicBandOrder.STATUS_FROZEN_IN_CUSTOMIZATION_BAD_ADDRESS;
        }
    }

    /**
    * Either gets the vignette from the facility data or from a local image. DCL uses two images where WDW and AP orders
    * use only one image.
    * @returns Object
    */
    get vignette(): Object {
        let vignette = {};
        const facilityID = this.reservationList[0] ? this.reservationList[0].facilityId : null;
        const facility = facilityID ? this.facilityData[facilityID] : null;

        if (facility) {
            if (facility.vignettes) {
                for (let image in facility.vignettes) {
                    if (this.siteId === SITE.WDW && image === 'desktop') {
                        vignette['single'] = this.getCdnUrl(formatImageURL(facility.vignettes[image].href, MEDIA_OPTIONS.vignettes));
                    } else if (this.siteId === SITE.DCL) {
                        vignette[image] = this.getCdnUrl(formatImageURL(facility.vignettes[image].href, MEDIA_OPTIONS.vignettes));
                    }
                }
            } else if (facility.vignette) {
                vignette['single'] = [this.getCdnUrl(formatImageURL(facility.vignette, MEDIA_OPTIONS.vignettes))];
            } else {
                vignette = [this.getCdnUrl(this.default_vignettes[this.siteId])];
            }
        } else if (this.siteId === SITE.DCL) {
            vignette['desktop'] = this.getCdnUrl(this.default_vignettes[this.siteId]['desktop']);
            vignette['mobile'] = this.getCdnUrl(this.default_vignettes[this.siteId]['mobile']);
        } else {
            vignette['single'] = [this.getCdnUrl(this.default_vignettes.ap)];
        }
        return vignette;
    }

    get shippingLabel(): IShippingLabel {
        return this.shippingLabelContainer;
    }

    public getGuestGeoLocaleForRequest(): string {
        let locale = this.localeService.getLocale();
        locale = locale || LOCALE_EN_US;
        return locale;
    }

    public filterFauxBands() {

    }

    public getCdnUrl(mediaUrl): string {
        return this.cdnService.getCDNUrl(mediaUrl);
    }

    private processBands() {
        this.magicbandOrder.customizationSelections.forEach(band => {
            this.bands.push(new MagicBand(band));
        });
    }

    get guestCount(): Number {
        return this.sortedGuests.length;
    }

    get acquisitionId() {
        return this.magicbandOrder.acquisitionId;
    }

    get acquisitionLabel() {
        return this.magicbandOrder.acquisitionLabel || '';
    }

    get category() {
        return this.magicbandOrder.category;
    }

    get count() {
        return this.magicbandOrder.customizationSelections ? this.magicbandOrder.customizationSelections.length : 0;
    }

    get stateroom() {
        return this.magicbandOrder.reservation ? this.magicbandOrder.reservation.stateroom.stateroomId : '';
    }

    get shipCode() {
        return this.magicbandOrder.reservation ? this.magicbandOrder.reservation.shipCode : '';
    }

    get daysUntilCountdownDeadline() {
        let daysUntilDeadline = 1; // Default to 1 incase this is an AP MagicBand order. This will ensure the CTA shows.
        if (this.magicbandOrder.customizationEndDate) {
            // We have a reservation so we need the number of days to complete the order.
            const deadline = moment.utc(this.magicbandOrder.customizationEndDate);
            const todaysdate = moment.utc().startOf('day');
            // Use the customization end date which is 11 days before the reservation date.
            daysUntilDeadline = deadline.diff(todaysdate, 'days');

            if (this.siteId === SITE.WDW && daysUntilDeadline <= 0 && this.earliestArrivalDate) {
                // If the customization end date has passed, we reset to the earliest arrival date minus 5 days.
                daysUntilDeadline = this.earliestArrivalDate.diff(todaysdate, 'days') - this.COMPLETE_ORDER_LIMIT;
            } else if (daysUntilDeadline < 1) {
                daysUntilDeadline = 0;
            }
            this.daysUntilDeadline = daysUntilDeadline;
        }

        return daysUntilDeadline;
    }

    get daysUntilCanPurchase() {
        const shipDepartureDate = moment.utc(get(this.reservationList, '[0].arrivalDate'))
            .subtract(this.PURCHASE_MB_LIMIT_FROM_DEPARTURE, 'days');
        const todaysdate = moment.utc().startOf('day');
        let daysUntilPurchaseWindow = shipDepartureDate.diff(todaysdate, 'days');
        if (daysUntilPurchaseWindow < 1) {
            daysUntilPurchaseWindow = 0;
        }
        this.daysUntilPurchaseWindow = daysUntilPurchaseWindow;
        return daysUntilPurchaseWindow;
    }

    get showCustomizeCountdown() {
        return this.daysUntilCountdownDeadline > 0 && this.isInCustomizationStatus;
    }

    get showPurchaseWindowNotice() {
        return this.daysUntilCanPurchase > 0;
    }

    get guestBands() {
        return this.magicbandOrder.customizationSelections;
    }

    get isBeforeCustomizationDeadline() {
        return this.daysUntilDeadline > 0;
    }

    get isActionableOrder() {
        const nonActionableStatus = [
            MagicBandOrder.STATUS_PICKUP,
            MagicBandOrder.STATUS_PROBLEM,
            MagicBandOrder.STATUS_PROCESSING,
            MagicBandOrder.STATUS_SHIP_TO_HOME,
            MagicBandOrder.STATUS_SHIP_TO_RESORT
        ];
        return !nonActionableStatus.includes(this.magicbandOrder.state);
    }

    get isInCustomizationStatus() {
        const nonActionableStatus = [
            MagicBandOrder.STATUS_PICKUP,
            MagicBandOrder.STATUS_PROCESSING,
            MagicBandOrder.STATUS_SHIP_TO_HOME,
            MagicBandOrder.STATUS_SHIP_TO_RESORT,
            MagicBandOrder.STATUS_SHIPPED
        ];
        return !nonActionableStatus.includes(this.magicbandOrder.state);
    }

    get isInReadyStatus() {
        const bandCount = this.bands.length;
        let isReadyCount = 0;
        let optedOutBandCount = 0;

        this.bands.forEach(band => {
            if (band.optOut || band.confirmedCustomization) {
                isReadyCount++;
                if (band.optOut) {
                    optedOutBandCount++;
                }
            }
        });

        return optedOutBandCount < bandCount && isReadyCount === bandCount;
    }

    get requestId() {
        return this.magicbandOrder.xbandRequestId;
    }

    get reservationDateWindow(): string {
        const arrivalDateTime = get(this.reservationList, '[0].arrivalDate');
        const departureDateTime = get(this.reservationList, '[0].departureDate');
        return (arrivalDateTime && departureDateTime) ?
            getDateRangeString(
                this.reservationList[0].arrivalDate,
                this.reservationList[0].departureDate
            ) : '';
    }

    get sailingDateWindow(): string {
        const arrivalDateTime = get(this.reservationList, '[0].arrivalDate');
        const departureDateTime = get(this.reservationList, '[0].departureDate');
        return (arrivalDateTime && departureDateTime) ?
            getDateRangeString(
                this.reservationList[0].arrivalDate,
                this.reservationList[0].departureDate
            ) : '';
    }

    get resortName(): string {
        const facilityID = this.reservationList[0] ? this.reservationList[0].facilityId : null;
        const facility = facilityID ? this.facilityData[facilityID] : null;

        return facility ? facility.name : '';
    }

    get cruiseName(): string {
        if (!this.magicbandOrder.reservation) {
            return '';
        }
        const voyageID = this.magicbandOrder.reservation.voyageId;
        return this.facilityData[voyageID].displayName;
    }

    get reservationNumber(): string {
        return get(this.reservationList, '[0].confirmationNumber') || get(this.reservationList, '[0].travelSegmentId');
    }

    get sortedGuests(): Array<string> {
        let primaryGuestXid;
        let nonPrimaryGuestIDList = [];
        let sortedGuestList: Array<string> = [];
        this.magicbandOrder.customizationSelections.forEach(band => {
            if (band.primaryGuest) {
                primaryGuestXid = band.xbandOwnerXid;
            } else {
                nonPrimaryGuestIDList.push(band.xbandOwnerXid);
            }
        });

        if (primaryGuestXid) {
            sortedGuestList.push(primaryGuestXid);
        }
        nonPrimaryGuestIDList.forEach(id => {
            sortedGuestList.push(id);
        });

        return sortedGuestList;
    }

    get status() {
        let orderStatus;
        const orderState = this.magicbandOrder.state;
        const GUEST_ADDRESS_FOR_ODER_PROCESSING = this.getGuestAddressForOrderProcessing();
        const address = GUEST_ADDRESS_FOR_ODER_PROCESSING['address'] || null;
        const confirmedAddress = GUEST_ADDRESS_FOR_ODER_PROCESSING['confirmedAddress'] || false;

        orderStatus = orderState;

        if (
            orderState === MagicBandOrder.STATUS_CUSTOMIZE
            && !address
        ) {
            orderStatus = MagicBandOrder.STATUS_PRE_CUSTOMIZATION;
        } else if (
            orderState === MagicBandOrder.STATUS_CUSTOMIZE
            && confirmedAddress
            && !this.magicbandOrder.shipNow
        ) {
            orderStatus = this.determineCustomizeConfirmedStatus();
        } else if (
            orderState === MagicBandOrder.STATUS_CUSTOMIZE
            && this.magicbandOrder.shipNow
        ) {
            orderStatus = MagicBandOrder.STATUS_PROCESSING;
        } else if (
            orderState === MagicBandOrder.STATUS_SHIPPED
            || orderState === MagicBandOrder.STATUS_PROCESSING
        ) {
            if (
                (
                    this.shippingLabelContainer
                    && this.shippingLabelContainer.alternateShippingDestination
                )
                || this.isShipmentHoldingForResortDestination
            ) {
                orderStatus = MagicBandOrder.STATUS_SHIP_TO_RESORT;
            } else if (orderState !== MagicBandOrder.STATUS_PROCESSING) {
                orderStatus = MagicBandOrder.STATUS_SHIP_TO_HOME;
            }
        }

        if (this.magicbandOrder.provideShipNowOption) {
            orderStatus = this.getShipNowStatus(this.magicbandOrder.isInReadySatus);
        }

        return orderStatus;
    }

    get holdReason() {
        const holdReason = this.magicbandOrder.holdReason || this.magicbandOrder.holdFulfillmentReasonDescription;
        return holdReason || null;
    }

    get isOrderProcessing(): boolean {
        const holdFulfillmentReasons = [
            MagicBandOrder.HOLD_OTHER,
            MagicBandOrder.HOLD_SHIP_TO_RESORT,
            MagicBandOrder.HOLD_GUEST_ENTERED_RESORT_ADDRESS
        ];

        return this.magicbandOrder.holdFulfillment && holdFulfillmentReasons.includes(this.holdReason);
    }

    private getGuestAddressForOrderProcessing() {
        const LOG_MESSAGE_PREFIX = 'MB_GUEST_ADDRESS_FOR_PROCESSING -> ';
        let guestAddressForOrderProcessing = null;
        if (this.magicbandOrder) {
            if (this.magicbandOrder.requestAddress) {
                guestAddressForOrderProcessing = this.magicbandOrder.requestAddress;
                this.logger.log(LOG_MESSAGE_PREFIX + 'Detected this.magicbandOrder.requestAddress ' +
                    'and this address will be used as the guestAddressForOrderProcessing.');
            }
        }
        if (!guestAddressForOrderProcessing) {
            if (this.getPrimaryGuestAddress) {
                guestAddressForOrderProcessing = this.getPrimaryGuestAddress;
                this.logger.log(LOG_MESSAGE_PREFIX + 'Detected this.getPrimaryGuestAddress ' +
                    'and this address will be used as the guestAddressForOrderProcessing.');
            }
        }
        if (!guestAddressForOrderProcessing) {
            this.logger.warn(LOG_MESSAGE_PREFIX + 'guestAddressForOrderProcessing is null due to ' +
                'the fact that no address was found in BOTH the this.magicbandOrder.requestAddress ' +
                'AND the this.getPrimaryGuestAddress references.');
        }
        return guestAddressForOrderProcessing;
    }

    get isShipmentHoldingForResortDestination() {
        const holdReasons = [
            MagicBandOrder.HOLD_SHIP_TO_RESORT,
            MagicBandOrder.HOLD_GUEST_ENTERED_RESORT_ADDRESS,
        ];
        return this.magicbandOrder.holdFulfillment && holdReasons.indexOf(this.magicbandOrder.holdReason) > -1;
    }

    public productCode(xid: string): string {
        for (const i in this.magicbandOrder.customizationSelections) {
            if (this.magicbandOrder.customizationSelections[i].xbandOwnerXid === xid) {
                return this.magicbandOrder.customizationSelections[i].bandProductCode;
            }
        }
        return '';
    }

    public confirmedCustomization(xid: string): boolean {
        for (const i in this.magicbandOrder.customizationSelections) {
            if (this.magicbandOrder.customizationSelections[i].xbandOwnerXid === xid) {
                return this.magicbandOrder.customizationSelections[i].confirmedCustomization;
            }
        }
        return false;
    }

    public bandData(xid: string): ICustomizationSelection {
        for (const i in this.magicbandOrder.customizationSelections) {
            if (this.magicbandOrder.customizationSelections[i].xbandOwnerXid === xid) {
                return this.magicbandOrder.customizationSelections[i];
            }
        }
        return null;
    }

    public isEnglishSelected(): boolean {
        const locale = String(this.getGuestGeoLocaleForRequest());

        return locale.indexOf('en') > -1;
    }
}
