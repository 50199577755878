import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { get } from 'lodash-es';
import { ConfigService } from '@wdpr-profile/core';

import { GuestProfile, IName } from './guest-profile';

const preferenceUrl = '/profile-api/profile-svc/profile-service/guests/{{swid}}/preference';
const defaultHeaders = {
  'Content-Type': 'application/json',
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) { }

  /**
   * Fetches compact profile for the logged-in Guest
   */
  getCompactProfile(): Observable<GuestProfile> {
    const url = '/profile-api/profile-svc/profile-service/guests/{{swid}}/compact-profile/?site=all';
    return this.http.get(url, {
      headers: defaultHeaders
    }).pipe(
      map((response: any) => {
        return(this.normalizeProfileResponse(response));
      })
    );
  }

  /**
   * Fetches profile for the logged-in Guest
   */
  getProfile() {
    const clientId = get(this.config.getValue('oneId'), 'clientId', '');
    const url =  `/profile-api/profile-svc/profile-service/clients/${clientId}/guests/{{swid}}/profile`;
    return this.http.get(url, {
      headers: defaultHeaders
    }).pipe(
      map((response: any) => {
        return(this.normalizeProfileResponse(response.data));
      })
    );
  }

  protected normalizeProfileResponse(response: any) {
    const profile = new GuestProfile();
    profile.name = <IName>{
      title: get(response, 'profile.prefix', ''),
      firstName: get(response, 'profile.firstName', ''),
      lastName: get(response, 'profile.lastName', ''),
      middleName: get(response, 'profile.middleName', ''),
      suffix: get(response, 'profile.suffix', '')
    };
    profile.email = get(response, 'profile.email', '');
    profile.ageBand = get(response, 'profile.ageBand', '');
    profile.avatarId = get(response, 'avatar.id', '');

    profile.xid = get(response, 'xid');

    return profile;
  }

  /**
   * Get preferences for the logged-in Guest
   */
  getPreferences() {
    return this.http.get(preferenceUrl, {
      headers: defaultHeaders
    });
  }

  /**
   * Updates avatar preference for the logged-in Guest
   */
  updateAvatar(avatarId: string) {
    const body = {
      avatarId: avatarId,
      favoriteCharacterId: avatarId
    };

    return this.http.post(preferenceUrl, body, {
      headers: defaultHeaders
    });
  }

  /**
   * Fetches preferred language for the logged-in Guest
   */
  getPreferredLanguage(): Observable<string> {
    const url = '/profile-api/profile-svc/profile-service/guests/{{swid}}/preference/communication';
    return this.http.get(url, {
      headers: defaultHeaders
    }).pipe(
      map((response: any) => {
        return response.preferredLanguage ? response.preferredLanguage : '';
      })
    );
  }

  /**
   * Updated preferred language for the logged-in Guest
   */
  updatePreferredLanguage(language: string) {
    const url = '/profile-api/profile-svc/profile-service/guests/{{swid}}/preference/communication';
    const body = {
      preferredLanguage: language
    };

    return this.http.post(url, body, {
      headers: defaultHeaders
    });
  }
}
