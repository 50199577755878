import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'profile-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent {
  @Input() numberOfDays: any;
  @Input() type: string;

  @ViewChild('customizationWhyModal') customizationWhyModal: ElementRef;
  @ViewChild('purchaseWindowWhyModal') purchaseWindowWhyModal: ElementRef;

  constructor() { }

  public openModal(event: MouseEvent) {
    if (this.type === 'purchase') {
      this.openPurchaseWhyModal(event);
    } else {
      this.openCustomizationWhyModal(event);
    }
  }

  public openCustomizationWhyModal(event: MouseEvent): void {
    event.preventDefault();
    this.customizationWhyModal.nativeElement.open = true;
  }

  public closeCustomizationWhyModal(): void {
    this.customizationWhyModal.nativeElement.open = false;
  }

  public openPurchaseWhyModal(event: MouseEvent): void {
    event.preventDefault();
    this.purchaseWindowWhyModal.nativeElement.open = true;
  }

  public closePurchaseWhyModal(): void {
    this.purchaseWindowWhyModal.nativeElement.open = false;
  }

}
