import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MagicBandOrders } from '@profile/shared/classes/magicband-orders.class';
import { MagicBandOrder } from '@profile/shared/classes/magicband-order.class';
import { categoryTypes, babCustomizationPath, SITE } from '@profile/shared/constants/app.constants';
import { IMagicBandData, IOrder } from '@profile/shared/interfaces/magic-bands.interface';
import { ConfigService, LocaleService } from '@wdpr-profile/core';
import { DeviceDataService } from '@wdpr/ra-angular-device-detection';
import { MagicBandService } from '@profile/shared/services/magic-band-service/magic-band.service';
import { of } from 'rxjs';
import { RedirectHandlerService } from '@profile/shared/services/redirect-handler-service/redirect-handler.service';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';
import { TranslateService } from '@ngx-translate/core';
import { toBoolean, formatTitleCase } from '@profile/shared/utils/utils';
import { LoggerService } from '@wdpr/ra-angular-logger';
import * as e from 'express';

@Component({
    selector: 'profile-magicband-order',
    templateUrl: './magicband-order.component.html',
    styleUrls: ['./magicband-order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MagicbandOrderComponent implements OnInit {
    @Input() data: IMagicBandData;
    orderSets: any = [];

    categoryTypes = categoryTypes;

    public environment: string;

    @ViewChild('whyModal') whyModal: ElementRef;

    private cookieData = '';
    private cookieName = '';

    objectKeys = Object.keys;
    activeGuest: string;
    device: string;
    userDevice = '';
    userOS = '';
    userBrowser = '';
    siteId: string;
    entitlementTypeLabel = {};
    affiliations = {};
    gattingOverride = false;
    fulfillmentStatus;
    siteIdConstant = SITE;
    mbEnablePurchaseWindowRestriction = false;

    constructor(
        private configService: ConfigService,
        private magicBandService: MagicBandService,
        private _deviceDataService: DeviceDataService,
        private redirectHandler: RedirectHandlerService,
        private cdnService: CdnService,
        private translate: TranslateService,
        private localeService: LocaleService,
        private logger: LoggerService
    ) {
        this.cookieName = this._deviceDataService.getConfig();
        this.cookieData = this._deviceDataService.getCookie(this.cookieName);
        this.userDevice = this._deviceDataService.getDeviceType().toUpperCase();
        this.siteId = this.configService.getValue('siteId');
        this.fulfillmentStatus = MagicBandOrder.STATUS_PROCESSING;
        this.mbEnablePurchaseWindowRestriction = this.configService.getValue('mbEnablePurchaseWindowRestriction');

        // Get the environment variable to toggle ship gating only if we are NOT in a production environment.
        // Log if we are using a ship gate override and how the override was set for debugging purposes.
        const env = this.configService.getValue('environment');
        if (env === 'dev' || env === 'development') {
            this.gattingOverride = this.toBoolean(this.configService.getValue('dclShipGating'));
            this.logger.log(`Ship gating environmental override enabled: ${this.gattingOverride}`);
        }

        // If the environment variable is false, check for the cookie override.
        if (!this.gattingOverride) {
            this.gattingOverride = this.toBoolean(this._deviceDataService.getCookie('gattingOverride'));
            this.logger.log(`Ship gating cookie override enabled: ${this.gattingOverride}`);
        }
    }

    ngOnInit() {
        this.orderSets = new MagicBandOrders(
            this.data,
            this.configService,
            this.cdnService,
            this.localeService,
            this.gattingOverride,
            this.logger
        );
    }

    public getCdnUrl(mediaUrl): string {
        return this.cdnService.getCDNUrl(mediaUrl);
    }

    public getAffiliationLabel(acquisitionLabel) {
        const affiliationType = acquisitionLabel;
        return this.entitlementTypeLabel[affiliationType];
    }

    public clickGetStartedButtonHandler(shippingAddress, xBandRequestID) {
        const LOG_MESSAGE_PREFIX = 'MB_CLICK_GET_STARTED_BUTTON_HANDLER_PROCESSING -> ';
        const BAB_CUSTOMIZATION_PATH_FOR_SITE_ID = babCustomizationPath[this.siteId];
        shippingAddress.confirmedAddress = true;
        if (BAB_CUSTOMIZATION_PATH_FOR_SITE_ID) {
            this.logger.log(LOG_MESSAGE_PREFIX + '\"Get Started\" button click detected...  Attempting to verify ' +
                'address and redirect if verification is successful for this.siteId -> \"' + this.siteId + '\".  ' +
                'The redirect path (aka BAB_CUSTOMIZATION_PATH_FOR_SITE_ID) is the following value -> \"' +
                BAB_CUSTOMIZATION_PATH_FOR_SITE_ID + '\"');
            if (shippingAddress) {
                this.logger.log(LOG_MESSAGE_PREFIX + 'shippingAddress detected and continuing to process ' +
                    '\"Get Started\" button click...');
                if (xBandRequestID) {
                    this.logger.log(LOG_MESSAGE_PREFIX + 'shippingAddress AND xBandRequestID \"' +
                        xBandRequestID + '\" detected and continuing to process \"Get Started\" button ' +
                        'click and attempting to verify address...');
                    this.magicBandService.verifyAddress(xBandRequestID, shippingAddress)
                    .subscribe({
                        next: response => {
                            this.logger.log(LOG_MESSAGE_PREFIX + 'SUCCESS -> shippingAddress verification complete ' +
                                'for xBandRequestID \"' + xBandRequestID + '\" for \"Get Started\" button ' +
                                'click processing!  Now navigating to BAB_CUSTOMIZATION_PATH_FOR_SITE_ID -> \"'
                                + BAB_CUSTOMIZATION_PATH_FOR_SITE_ID + '\"');
                            this.redirectHandler.navigateToUrl(BAB_CUSTOMIZATION_PATH_FOR_SITE_ID + xBandRequestID);
                        },
                        error: error => {
                            const ERROR_JSON_STRING = JSON.stringify(error);
                            this.logger.error(LOG_MESSAGE_PREFIX + 'ERROR -> shippingAddress verification failed with ' +
                                'an error detected for xBandRequestID \"' + xBandRequestID + '\" related to ' +
                                '\"Get Started\" button click processing.  The error message is -> \"' +
                                ERROR_JSON_STRING + '\"');
                            return of(null);
                        }
                    });
                } else {
                    this.logger.error(LOG_MESSAGE_PREFIX + 'shippingAddress is detected however the xBandRequestID ' +
                        'is NOT detected, thus processing the \"Get Started\" button click will not proceed.');
                    return of(null);
                }
            } else {
                this.logger.error(LOG_MESSAGE_PREFIX + 'shippingAddress NOT detected, thus processing the ' +
                    '\"Get Started\" button click will not proceed.');
            }
        } else {
            this.logger.log(LOG_MESSAGE_PREFIX + '\"Get Started\" button click detected however the ' +
                'BAB_CUSTOMIZATION_PATH_FOR_SITE_ID could not be detected for this.siteId with the value \"' +
                this.siteId + '\", thus processing the \"Get Started\" button click will not proceed.');
        }
    }

    scrollTo(element: any): void {
        (document.getElementById(element) as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    public formatTitleCase(str: string): string {
        return formatTitleCase(str);
    }

    public toBoolean(value: string): boolean {
        return toBoolean(value);
    }

    public navigateToOrderHistory() {
        this.redirectHandler.navigateToUrl(babCustomizationPath[this.siteId] + 'order-history/');
    }

    public getTraslation(key) {
        return this.translate.get(key)
            .subscribe((translation: object) => {
                return translation.toString() !== key ? translation : '';
            });
    }

    public hasManagedBands(): boolean {
        return this.data.managed.length ? true : false;
    }

    public showHideCustomizeCountDown(order): boolean {
        const isResortShipReservation =
            order.category === categoryTypes.RESORT_CATEGORY
                || order.category === categoryTypes.DCL_CATEGORY ?
                true
                : false;
        return isResortShipReservation && order.showCustomizeCountdown;
    }

    public showHidePurchaseWindowNotice(order): boolean {
        if (!this.mbEnablePurchaseWindowRestriction) {
            return false;
        }

        const isResortShipReservation =
            order.category === categoryTypes.DCL_CATEGORY ?
                true
                : false;
        return isResortShipReservation && order.showPurchaseWindowNotice;
    }
}
