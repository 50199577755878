import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IVignettes } from '@profile/shared/interfaces/magic-bands.interface';
import { replaceCurlyValue } from '@profile/shared/utils/utils';
import { shipNameImageURLTemplate, SITE } from '@profile/shared/constants/app.constants';
import { ConfigService } from '@wdpr-profile/core';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';

@Component({
    selector: 'profile-vignette',
    templateUrl: './vignette.component.html',
    styleUrls: ['./vignette.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VignetteComponent implements OnInit {
    @Input() vignette: Array<IVignettes>;
    @Input() shipCode: string;
    @Input() isConcierge: boolean;

    config;
    siteId;
    siteIdConstant = SITE;

    shipNameBadges = { 'desktop': '', 'mobile': '' };

    constructor(
        private configService: ConfigService,
        private cdnSerserve: CdnService
    ) {
        this.siteId = this.configService.getValue('siteId');
    }

    ngOnInit() {
        for (let item in shipNameImageURLTemplate) {
            if (shipNameImageURLTemplate.hasOwnProperty(item)) {
                this.shipNameBadges[item] = this.getCdnUrl(
                    replaceCurlyValue(shipNameImageURLTemplate[item], 'shipCode',
                        this.shipCode.toLocaleLowerCase()));
            }
        }
    }

    public getCdnUrl(mediaUrl): string {
        return this.cdnSerserve.getCDNUrl(mediaUrl);
    }
}
