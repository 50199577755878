import { Injectable } from '@angular/core';
import { ConfigService } from '@wdpr-profile/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowRef } from '@wdpr-profile/core';
import { FormatExternalUrlPipe } from 'projects/magicband-spa/src/app/shared/utils/pipes/format-external-url/format-external-url.pipe';

const RETURN_URL_QUERY_PARAM = 'returnUrl';
const APP_REDIRECT_QUERY_PARAM = 'appRedirect';
const URL_SCHEME = '^(http|https)';
const PROFILE_LANDING = '/profile';
const ERROR = '/error/';
const ERROR_404 = '/error/404/';

/**
 * Handles multiple types of url redirection.
 */
@Injectable({
  providedIn: 'root'
})
export class RedirectHandlerService {
    private configurationData: any;

    /**
     * Determines if the current url should be checked for a return url.
     */
    checkUrlSegments = false;

    protected get homepageUrl(): string {
        return this.formatExternalUrlPipe.transform('/');
    }

    constructor(
        config: ConfigService,
        private logger: LoggerService,
        private route: ActivatedRoute,
        private router: Router,
        private windowRef: WindowRef,
        private formatExternalUrlPipe: FormatExternalUrlPipe
    ) {
        this.configurationData = config.getValue('redirectHandler');
    }

    /**
     * Checks for a return url, using several different strategies,
     * and validates against configured denylist and allowlist.
     * - first checks for 'returnurl' query param
     * - then checks for 'appredirect' query param
     * - then checks url segments (if flag is set)
     * - defalts to homepage if no matches found
     */
    getReturnUrl(): string {
        let returnUrl = this.route.snapshot.queryParams[RETURN_URL_QUERY_PARAM];

        // Check if returnurl query param is set, else if appredirect param is set, else homepage
        if (!returnUrl) {
            returnUrl = this.route.snapshot.queryParams[APP_REDIRECT_QUERY_PARAM];
        }

        if (!returnUrl && this.checkUrlSegments) {
            returnUrl = this.getReturnUrlSegments();
            this.checkUrlSegments = false;
        }

        // Default to homepage
        if (this.empty(returnUrl)) {
            returnUrl = this.homepageUrl;
        }

        // If return url query param is not allowed set as homepage
        if (this.isDenied(returnUrl) || !this.isAllowed(returnUrl)) {
            this.logger.error('RedirectHandlerService - getReturnUrl FAILED. Reason: invalid return Url', returnUrl);
            returnUrl = this.homepageUrl;
        }

        return returnUrl;
    }

    /**
     * Extracts a return url from the trailing url segments.
     * e.g. given '/login/tickets/foo' => return '/tickets/foo'
     */
    getReturnUrlSegments(): string {
        const firstSegment = this.windowRef.nativeWindow.location.pathname.split('/')[1];
        return this.windowRef.nativeWindow.location.pathname.replace('/' + firstSegment, '')
            + this.windowRef.nativeWindow.location.search;
    }

    /**
     * Navigates to the return url depending on if it's:
     * - an absolute url
     * - a relative url
     * - an angular route in this app
     */
    redirectToReturnUrl() {
        const returnUrl = this.getReturnUrl();

        // Check for absolute url
        if (this.checkForRegexMatch(returnUrl, URL_SCHEME)) {
            // Navigate to external site
            this.windowRef.nativeWindow.location.href = returnUrl;
        } else {
            // Navigate to relative route
            const returnUrlWithoutSlash = returnUrl.substring(1);
            let isAngularRoute = false;
            for (const route of this.router.config) {
                if (route.path === returnUrlWithoutSlash) {
                    isAngularRoute = true;
                    break;
                }
            }

            if (isAngularRoute) {
                this.router.navigate([returnUrl]);
            } else {
                this.windowRef.nativeWindow.location.href =  this.windowRef.nativeWindow.location.origin + returnUrl;
            }
        }
    }

    /**
     * Navigates to a relative url, external to this app, but part of the same site,
     * adding the locale to the path if necessary.
     */
    navigateToUrl(relativeUrl: string) {
        this.windowRef.nativeWindow.location.href = this.formatExternalUrlPipe.transform(relativeUrl);
    }

    /**
     * Navigates to profile landing page w/out having to hardcode the route in every page.
     * TODO: Once /profile is part of this app, use this.router instead of location.href
     */
    navigateToProfileLanding() {
        this.navigateToUrl(PROFILE_LANDING);
    }

    /**
     * Navigates to external 404 error page.
     */
    navigateToNotFoundError() {
        this.navigateToUrl(ERROR_404);
    }

    /**
     * Checks if a url matches any urls on the configured allowedDomains list.
     */
    isAllowed(url: string): boolean {
        let isAllowed = false;

        // Check if absolute
        if (this.checkForRegexMatch(url, URL_SCHEME)) {
            const allowedUrls = this.configurationData.allowedDomains;
            const absUrl = new URL(url);
            // Check to find in domainallowlist
            for (const match of allowedUrls) {
                if (this.checkForRegexMatch(absUrl.hostname, match)) {
                    isAllowed = true;
                    break;
                }
            }
        } else {
            // Relative so part of this domain
            isAllowed = true;
        }

        return isAllowed;
    }

    /**
     * Checks if a url matches any urls on the configured deniedUrls list.
     */
    isDenied(url: string): boolean {
        const deniedUrls = this.configurationData.deniedUrls;
        let isDenied = false;

        for (const reg of deniedUrls) {
            if (this.checkForRegexMatch(url, reg)) {
                isDenied = true;
                break;
            }
        }

        return isDenied;
    }

    /**
     * Checks for a match between url(string) and match(string but converted to regex)
     */
    checkForRegexMatch(url: string, match: string) {
        return url.match(this.formatRegExp(match));
    }

    /**
     * Creates case-insensitive regex for match function of allowlist and denylist
     */
    formatRegExp(inputStr: string): RegExp|null {
        return new RegExp(inputStr , 'i');
    }

    empty(value) {
      const str = value;
      return (!str || str === undefined || str.trim().length === 0);
    }

    /**
     * Navigates to external error page.
     */
    navigateToError() {
      this.navigateToUrl(ERROR);
  }
}
