import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { LOGIN_URL, OneIdService, ConfigService, AuthenticationGuard } from '@wdpr-profile/core';
import { ExternalUrlService } from '../external-url-service/external-url-service.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    s3Environment;

    constructor(
        private externalUrlService: ExternalUrlService,
        private logger: LoggerService,
        private oneIdService: OneIdService,
        private authenticationGuard: AuthenticationGuard,
        private configService: ConfigService,
    ) {
        this.s3Environment = this.configService.getValue('s3Env');
    }

    /**
     * If logged in then allow to go to the route, else redirect to login page.
     */
    canActivate(): Observable<boolean> {
        return new Observable((observer) => {
            this.oneIdService.did.getLoggedInStatus()
                .then(status => {
                    if (!status) {
                        if (this.s3Environment === 'local') {
                            // For local we just show the OneID login modal until a better solution comes along.
                            this.authenticationGuard.canActivate();
                        } else {
                            // For all other environments we redirect to the login page.
                            this.redirectToLogin();
                        }
                        observer.next(false);
                    } else {
                        observer.next(true);
                    }
                })
                .fail(err => {
                    this.logger.error(`Authentication Guard - Lightbox Error: ${err.message}`);
                    this.redirectToLogin();
                    observer.next(false);
                });
        });
    }

    redirectToLogin() {
        this.externalUrlService.go(LOGIN_URL, true);
    }
}
