<div class="countDownDisplay {{type}}">
    <h4>
        {{numberOfDays}}
    </h4>
    <div class="magicBandOrderCountDown">
        <span
            *ngIf="type === 'purchase'"
            class="countDownExplanaition"
            [innerHTML]="(
                numberOfDays > 1
                    ? 'descriptions.magicbandspage.sections.purchase_window_countdown_plural'
                    : 'descriptions.magicbandspage.sections.purchase_window_countdown_singular'
                ) | translate"
        ></span>
        <span
            *ngIf="type === 'customize'"
            class="countDownExplanaition"
            [innerHTML]="(
                numberOfDays > 1
                    ? 'descriptions.magicbandspage.sections.days_to_complete_order_plural'
                    : 'descriptions.magicbandspage.sections.days_to_complete_order_singular'
                ) | translate"
        ></span>
        <span class="whyLink">
            <button
                class="whyModalLauncher link"
                aria-label="'descriptions.magicbandspage.sections.why_link_alt' | translate"
                (click)="openModal($event)"
                [innerHTML]="'descriptions.magicbands.sections.cta_why' | translate"
            ></button>
        </span>
    </div>
</div>

<wdpr-modal
    #purchaseWindowWhyModal
    block-focus
    aria-close="Close"
    id="purchaseWindowWhyModal"
>
    <h3 slot="title" [translate]="'descriptions.magicbandspage.sections.purchase_window_modal_title'"></h3>
    <div slot="body">
        <p [innerHTML]="'descriptions.magicbandspage.sections.purchase_window_modal_body' | translate"></p>
    </div>
</wdpr-modal>

<wdpr-modal
    #customizationWhyModal
    block-focus
    aria-close="Close"
    id="customizationWhyModal"
>
    <h3 slot="title" [translate]="'descriptions.magicbandspage.sections.why_modal_title'"></h3>
    <div slot="body">
        <p [innerHTML]="'descriptions.magicbandspage.sections.why_modal_intro' | translate"></p>
        <p [innerHTML]="'descriptions.magicbandspage.sections.why_modal_body' | translate"></p>
    </div>
</wdpr-modal>
