import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MEDIA_ENGINE_CONFIGS } from '@profile/features/magic-bands/common/media-engine-configs';
import { A11yService } from '@profile/shared/services/a11y/a11y.service';
import { MediaEngineService } from '@profile/shared/services/media-engine/media-engine.service';
import { get, has, isEmpty } from 'lodash-es';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { HERO_STORY_CONSTANTS } from './hero-story.constants';

declare const ME: any;

enum ME_TAGS {
  DESKTOP_LOOP = 1,
  DESKTOP_FULL,
  MOBILE_IMG,
  MOBILE_VIDEO
}

const ME_CSS = {
  ACTIVE: '.me-active',
  FULLSCREEN: 'fullscreen',
  FULLSCREEN_ENTER: 'fullscreen-enter',
  FULLSCREEN_CONSTRAINED: 'fullscreen-constrained'
};

@Component({
  selector: 'profile-hero-story',
  templateUrl: './hero-story.component.html',
  styleUrls: ['./hero-story.component.scss']
})
export class HeroStoryComponent implements OnInit, OnDestroy {
  @Input() heroStoryData: any;
  @Input() media: any;
  @Input() native = false;

  @ViewChild('hero', { read: ElementRef }) hero: ElementRef;

  heroData: any;
  mediaEngine: any = {};
  mobile: any;
  desktop: any;

  private isMobile: boolean;
  private destroy$ = new Subject<void>();
  private isFullscreen = false;

  constructor(
    private mediaEngineService: MediaEngineService,
    @Inject(DOCUMENT) private document: Document,
    private a11yService: A11yService
  ) { }

  ngOnInit() {
    if (this.heroStoryData) {
      this.heroData = HERO_STORY_CONSTANTS;
      this.heroData.mediaEngine.data = this.heroStoryData;
      this.initMediaEngineComponent();
    }
  }

  initMediaEngineComponent() {
    const meData = get(this.heroData, 'mediaEngine');
    if (!meData) {
      return;
    }

    // Check for a title
    const title = get(this.heroData, 'cta.desktop.heading.title');

    // Initialize the media engine configuration
    const finderConfig = this.mediaEngineService.initConfig(meData, title);

    // if we don't have a hero cta for desktop disable full screen video feature
    const slides = get(finderConfig, 'config.slides');
    const action = get(this.desktop, 'action', {});
    if (isEmpty(action) && slides) {
      slides.fullscreenOnTouchOrClick = slides.fullscreenOnTouchOrClick || false;
      slides.fullscreenAPIEnabled = slides.fullscreenAPIEnabled || true;
    }

    const callbackOverrides = {};
    // if (this.heroData.constrainFsVideo) {
    //     callbackOverrides['constrainFsVideo'] = true;
    // }
    const mediaEngine = this.mediaEngineService.initMediaEngine(finderConfig);
    mediaEngine.config.callbacks = this.getConfigCallbacks(callbackOverrides);

    // Respect reduced motion a11y setting by not autoplaying video
    const reducedMotionEnabled = this.a11yService.reducedMotionEnabled();
    if (reducedMotionEnabled && has(mediaEngine, 'config.video.autoplay')) {
      // Disable autoplay
      mediaEngine.config.video.autoplay = false;

      // Add console message just to be safe
      // this.debugLog.info('***** Video autoplay disabled by "reduced motion" OS setting.');
    }

    // Set properties
    this.mediaEngine.version = mediaEngine.version;
    this.mediaEngine.theme = mediaEngine.theme;
    this.mediaEngine.production = mediaEngine.production;
    this.mediaEngine.config = mediaEngine.config;

    // subcribe to close video on scroll
    fromEvent(this.document, 'wheel')
      .pipe(
        debounceTime(25),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.isFullscreen) {
          ME.fullscreenExitHandler();
        }
      });
  }

  // Ignore the if just for coverage, there's no need to test unsubscribe
  /* istanbul ignore next */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // This handles triggering the ME switch for playback of the full video
  // The mobile flag is necessary to know which version of the video should
  // be played.
  // There is really no need to unit test any of this since it is just for the sake of ME.
  /* istanbul ignore next */
  playbackVideo(event, isMobile = false) {
    this.isMobile = isMobile;
    ME.fullscreenEnterHandler();
  }

  // Get custom ME callbacks for fullscreen enter/exit.
  // There is really no need to unit test any of this since it is just for the sake of ME.
  /* istanbul ignore next */
  private getConfigCallbacks(configOverrides) {
    return {
      ready: MEDIA_ENGINE_CONFIGS.snowball.callbacks.ready,
      customFullscreenEnter: () => {
        this.isFullscreen = true;
        const preFullscreenMediaSrc = ME.els.slidesMediaUL.querySelector(ME_CSS.ACTIVE).children[0].src;

        this.document.body.classList.add(ME_CSS.FULLSCREEN);
        this.hero.nativeElement.classList.add(ME_CSS.FULLSCREEN_ENTER);
        if (configOverrides.hasOwnProperty('constrainFsVideo')) {
          this.hero.nativeElement.classList.add(ME_CSS.FULLSCREEN_CONSTRAINED);
        }

        window.setTimeout(() => {
          ME.changeMediaSet(this.isMobile ? ME_TAGS.MOBILE_VIDEO : ME_TAGS.DESKTOP_FULL);
          ME.loadSlideWithSrc(preFullscreenMediaSrc);

          window.setTimeout(() => {
            ME.fullscreenEnter();
            ME.els.container.focus();
          }, 500);
        }, 50);
      },
      customFullscreenExit: () => {
        this.isFullscreen = false;
        const preFullscreenMediaSrc = ME.els.slidesMediaUL.querySelector(ME_CSS.ACTIVE).children[0].src;

        window.setTimeout(() => {
          ME.changeMediaSet(this.isMobile ? ME_TAGS.MOBILE_IMG : ME_TAGS.DESKTOP_LOOP);
          ME.loadSlideWithSrc(preFullscreenMediaSrc);
          ME.ccHide();

          window.setTimeout(() => {
            ME.fullscreenExit();
            this.document.body.classList.remove(ME_CSS.FULLSCREEN);
            this.hero.nativeElement.classList.remove(ME_CSS.FULLSCREEN_ENTER);
            if (configOverrides.hasOwnProperty('constrainFsVideo')) {
              this.hero.nativeElement.classList.remove(ME_CSS.FULLSCREEN_CONSTRAINED);
            }
            ME.els.container.focus();
          }, 50);
        }, 50);
      }
    };
  }
}
