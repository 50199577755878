import { WindowRef } from '@wdpr-profile/core';
import { Injectable } from '@angular/core';
import { FormatExternalUrlPipe } from 'projects/magicband-spa/src/app/shared/utils/pipes/format-external-url/format-external-url.pipe';

/**
 * Service for helping with routing to urls external to our app.
 */
@Injectable({
    providedIn: 'root'
})
export class ExternalUrlService {

    constructor(
        private windowRef: WindowRef,
        private formatExternalUrlPipe: FormatExternalUrlPipe) { }

    /**
     * Builds the relative url for the path and options provided.
     * @param url the "external" url to build the path to
     * @param appendReturnUrl flag to indicate whether the returnUrl query parameter should be appended to the url
     * @param returnParamKey some external urls use a non-standard return url query param key; specify this param
     *        with a string as the returl url key if the external url doesn't use the standard "returnUrl"
     */
    public buildUrl(url: string, appendReturnUrl = false, returnParamKey = 'returnUrl'): string {
        return this.formatExternalUrlPipe.transform(url, appendReturnUrl ? returnParamKey : undefined);
    }

    /**
     * Routes to the url provided. Assumes the url is relative to the current domain.
     * @param url the "external" url to route to
     * @param appendReturnUrl flag to indicate whether the returnUrl query parameter should be appended to the url
     */
    public go(url: string, appendReturnUrl = false, returnParamKey = 'returnUrl'): void {
        const nativeWindow = this.windowRef.nativeWindow;
        nativeWindow.open(this.buildUrl(url, appendReturnUrl, returnParamKey), '_self');
    }
}
