import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  CARD_TYPES,
  LOCALE_EN_US
} from '@profile/shared/constants/app.constants';
import {
  IMagicBandData,
  IManagedBand,
  imageSizeTShirt
} from '@profile/shared/interfaces/magic-bands.interface';
import { ConfigService, LocaleService } from '@wdpr-profile/core';
import { DeviceDataService } from '@wdpr/ra-angular-device-detection';
import { MagicBandService } from '@profile/shared/services/magic-band-service/magic-band.service';
import { of } from 'rxjs';
import { CdnService } from '@profile/shared/services/cdn-service/cdn-service';
import {
  formatExternalNumber, formatTitleCase
} from '@profile/shared/utils/utils';
import { ProductData } from '@profile/shared/classes/magicband-product.class';

@Component({
  selector: 'profile-magicbands-managed',
  templateUrl: './managed-magicbands.component.html',
  styleUrls: ['./managed-magicbands.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      state(
        'opened',
        style({
          height: '200px',
          opacity: 1
        })
      ),
      state(
        'closed',
        style({
          height: '100px',
          opacity: 0.8
        })
      ),
      transition('* <=> *', [animate('1s')])
    ])
  ],
})
export class ManagedMagicbandsComponent implements OnInit {
  @Input() data: IMagicBandData;


  // MDX will show all bands in ACTIVE and INACTIVE status.  VOID bands and cards will be hidden.
  private STATUS_ACTIVE = 'ACTIVE';
  private STATUS_INACTIVE = 'INACTIVE';
  private STATUS_VOID = 'VOID';

  private STATE_ACTIVATE = 'activate';
  private STATE_DEACTIVATE = 'deactivate';
  private STATE_LOST = 'reportLost';

  // active substatuses
  private SUBSTATUS_ORIGINAL = 'ORIGINAL';
  private SUBSTATUS_REPLACEMENT = 'REPLACEMENT';
  private SUBSTATUS_TRANSFER = 'TRANSFER';
  private SUBSTATUS_EXTERNAL_TRANSFER = 'EXTERNAL_TRANSFER';
  private SUBSTATUS_TRADE = 'TRADE';
  private SUBSTATUS_EXTERNAL_TRADE = 'EXTERNAL_TRADE';
  private SUBSTATUS_OBSOLETE = 'OBSOLETE';

  // inactive substatuses
  private SUBSTATUS_GUEST_LOST_OR_STOLEN = 'GUEST_LOST_OR_STOLEN';
  private SUBSTATUS_INVENTORY_ADJUSTMENT = 'INVENTORY_ADJUSTMENT';
  private SUBSTATUS_LOST_IN_DELIVERY = 'LOST_IN_DELIVERY';
  private SUBSTATUS_KEEPSAKE = 'KEEPSAKE';
  private SUBSTATUS_INVENTORY = 'INVENTORY';
  private SUBSTATUS_BUSINESS_NEED = 'BUSINESS_NEED';
  private SUBSTATUS_TRANSFER_READY = 'TRANSFER_READY';
  private SUBSTATUS_READY_FOR_TRADE = 'READY_FOR_TRADE';
  private SUBSTATUS_INVALID_PIN = 'INVALID_PIN';
  private SUBSTATUS_INVALID_ASSIGNMENT = 'INVALID_ASSIGNMENT';

  // void substatuses
  private SUBSTATUS_DAMAGED = 'DAMAGED';
  private SUBSTATUS_RECALL = 'RECALL';

  // field names for different APIs
  private SF_EXTERNAL_NUMBER_FIELD = 'externalId';
  private XBMS_EXTERNAL_NUMBER_FIELD = 'externalNumber';

  protected _managedBands: IManagedBand;
  protected cookieData = '';
  protected cookieName = '';

  protected productData: ProductData;

  cardTypes = CARD_TYPES;
  activeGuest: string;
  device: string;
  userDevice = '';
  userOS = '';
  userBrowser = '';
  isActive = false;
  managedBandsKeys: string[];
  siteId: string;
  isHeaderDisplayed: boolean;

  @Input()
  set managedBands(managedBands: IManagedBand) {
    this._managedBands = managedBands;
      if (managedBands) {
          this.managedBandsKeys = Object.keys(this._managedBands);
      }
  }

  get managedBands(): IManagedBand {
    return this._managedBands;
  }

  constructor(
    private cdnService: CdnService,
    private configService: ConfigService,
    private deviceDataService: DeviceDataService,
    private localeService: LocaleService,
    private magicBandService: MagicBandService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.cookieName = this.deviceDataService.getConfig();
    this.cookieData = this.deviceDataService.getCookie(this.cookieName);
    this.userDevice = this.deviceDataService.getDeviceType().toLowerCase();
    this.siteId = this.configService.getValue('siteId');
    const langPref = this.localeService.getLocale() || LOCALE_EN_US;
    this.translate.use(langPref.toLocaleLowerCase());

    this.productData = new ProductData(this.data.products, this.cdnService, this.deviceDataService);
  }

  public FormatImageURL(band) {
    return this.productData.getImage(band.productCode, imageSizeTShirt.large, imageSizeTShirt.medium);
  }

  public getDisabledState(secondaryState) {
    return secondaryState === this.SUBSTATUS_GUEST_LOST_OR_STOLEN ? 'disabled' : '';
  }

  public FormatThumbImageURL(band) {
        return this.productData.getImage(band.productCode, imageSizeTShirt.small, imageSizeTShirt.thumb);
   }

  public formatExternalNumber(value) {
    return formatExternalNumber(value);
  }

  public formatTitleCase(value) {
    return formatTitleCase(value);
  }

  public getCdnUrl(mediaUrl): string {
    return this.cdnService.getCDNUrl(mediaUrl);
  }

  public statusButton(xBand, stateZone) {
    if (stateZone === this.STATE_ACTIVATE && xBand.secondaryState === this.SUBSTATUS_GUEST_LOST_OR_STOLEN) {
      return false;
    }
    let newSecondaryState = this.SUBSTATUS_ORIGINAL;
    const xbandId = xBand.xbandId;

    if (stateZone === this.STATE_ACTIVATE && xBand.secondaryState === this.SUBSTATUS_ORIGINAL) {
        newSecondaryState = this.SUBSTATUS_KEEPSAKE;
    } else if (stateZone === this.STATE_LOST && xBand.secondaryState === this.SUBSTATUS_ORIGINAL) {
        newSecondaryState = this.SUBSTATUS_GUEST_LOST_OR_STOLEN;
    } else if (
      (stateZone === this.STATE_LOST && xBand.secondaryState === this.SUBSTATUS_GUEST_LOST_OR_STOLEN)
      || (stateZone === this.STATE_ACTIVATE && xBand.secondaryState === this.SUBSTATUS_KEEPSAKE)
    ) {
      newSecondaryState = this.SUBSTATUS_ORIGINAL;
    }

    this.magicBandService.getSingleManagedBandOptions(xbandId)
      .pipe(
        switchMap(response => {
          const availableNextStates = response['nextSecondaryState'];
          if (availableNextStates.includes(newSecondaryState)) {
            return this.magicBandService.updateManagedBandStatus(xbandId, newSecondaryState);
          } else {
            return of({});
          }
        })
      )
      .subscribe(returnVal => {
        xBand.state = returnVal['state'];
        xBand.secondaryState = returnVal['secondaryState'];
      });
  }

  public managedBandSections() {
    return Object.keys(this.managedBands);
  }

  public managedBandSectionIds(section) {
    return Object.keys(this.managedBands[section]);
  }

  public toggleBandCollection(xid, event) {
    this.activeGuest = this.activeGuest === xid ? null : xid;
  }
}
