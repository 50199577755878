export const HERO_STORY_CONSTANTS = {
    'mediaEngine': {
        'version': '3.0.0',
        'theme': 'snowball-no-thumbs',
        'production': false,
        'config': {
            'layout': [
                'slides',
                'controls',
                'tabs',
                'thumbnails'
            ],
            'tabs': {
                'initial': 1
            },
            'slides': {
                'autoplay': false,
                'displayTime': 5000,
                'fullscreenAPIEnabled': false,
                'fullscreenOnTouchOrClick': true,
                'loop': true,
                'transitionTime': 300
            },
            'controls': {
                'video': {
                    'layout': [
                        'play-pause',
                        'timer',
                        'volume',
                        'progress-bar',
                        'cc',
                        'dt'
                    ]
                }
            },
            'thumbnails': {
                'controls': true,
                'maxVisible': 'manual',
                'previewOnHover': true,
                'transitionTime': 300,
                'minWidth': 9000
            },
            'video': {
                'autoplay': true,
                'muted': true,
                'unmuteOnFullscreen': true
            }
        }
    }
};
